import React, { Component } from 'react'
import Navbar from '../components/Navbar'
import SidebarMenu from '../components/SidebarMenu'
import { TbCertificate, TbCurrentLocation, TbLocation, TbPin, TbSearch, TbSquareDot, TbStar, TbUser } from 'react-icons/tb';
import { MdInventory, MdVerified } from 'react-icons/md';
import { Button, Modal, Offcanvas, Tab, Tabs } from 'react-bootstrap';
import ProductComponent from '../components/ProductComponent';
import Select from 'react-select'
import { GiCandles, GiPathDistance, GiShoppingBag } from 'react-icons/gi';
import { BsArrowLeftRight, BsClockHistory, BsPuzzle } from 'react-icons/bs';
import { FcCancel } from 'react-icons/fc';
import { Link } from 'react-router-dom';
import ProductDetailsModalComponent from '../components/ProductDetailsModalComponent';
import ChatView from './ChatView';
import { getApi, postApi } from '../api/call.api';
import urlApi from '../api/url.api';
import { toast, ToastContainer } from 'react-toastify';
import { dateTimeConverter } from '../utils/dateTimeConverter';
import Pagination from 'react-js-pagination';
import ConfirmationModal from '../components/ConfirmationModal';
import { GrPowerReset } from 'react-icons/gr';
import { FaShoppingBag, FaShoppingBasket } from 'react-icons/fa';
import { HiShoppingBag } from 'react-icons/hi';
import AddonDecorComponent from '../components/AddonDecorComponent';
import socketService from '../api/socketConnect';
import PubSub from 'pubsub-js';

export default class SingleShopView extends Component {
    constructor(p) {
        super(p);
        this.state = {
            isOpen: false,
            toggleCheck: false,
            productDetailsModalShow: false,
            chatSideBarShow: false,
            shopData: [],
            productList: [],
            pagination: ({
                active_page: 0,
                per_page: 10,
                total_items: 0
            }),
            page: 1,
            count: 10,
            orderList: [],
            addonList: [],
            decorList: [],
            singleProductSDetail: [],
            picUpload: "",
            statusUpdateModalShow: false,
            addonListModalShow: false,
            decorListModalShow: false,
            notificationSideBarShow: false,
            pendingOrderData: [],
            ongoingOrderData: [],
            deliveredOrderData: [],
            valueSp: [],
            chatUserData: [],
            chatMsgList: [],
        }
        this.statusChange = this.statusChange.bind(this);
        this.allAddonDecorList = [];
        this.totalProductCount = "0";
        this.chatDetails = [];

    }
    options = [];

    menuBarClick() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    async addonViewModalShow() {
        let resp = await getApi(urlApi.addonListByBaker + this.props.match.params.bakerID);
        if (resp.responsecode === "200") {
            this.setState({ addonList: resp.data });
            this.setState({ addonListModalShow: true });
        } else {
            this.alert("No Addon Found", "500");
        }
    }

    addonViewModalHide() {
        this.setState({ addonListModalShow: false });
    }

    async decorViewModalShow() {
        let resp = await getApi(urlApi.decorListByBaker + this.props.match.params.bakerID);
        if (resp.responsecode === "200") {
            this.setState({ decorList: resp.data });
            this.setState({ decorListModalShow: true });
        } else {
            this.alert("No Decor Found", "500");
        }
    }

    decorViewModalHide() {
        this.setState({ decorListModalShow: false });
    }

    statusChange(toggleCheck) {
        this.setState({ toggleCheck: toggleCheck });
    }

    async productDetailsViewModalShow(data, tabIndex) {
        let addonTempList = [];
        let decorTempList = [];
        this.allAddonDecorList = [];
        if (data.add_on_ids || data.decor_ids) {
            let addons = JSON.parse(data.add_on_ids);
            let decors = JSON.parse(data.decor_ids);

            if (tabIndex === 1) {
                for (let i = 0; i < addons.length; i++) {
                    let respAddon = await getApi(urlApi.addonDetailsByAddonId + addons[i]);
                    if (respAddon.responsecode === "200") {
                        addonTempList.push(respAddon.data);
                    }
                }
                for (let j = 0; j < decors.length; j++) {
                    let respDecor = await getApi(urlApi.decorDetailsByDecorId + decors[j]);
                    if (respDecor.responsecode === "200") {
                        decorTempList.push(respDecor.data);
                    }
                }
                this.allAddonDecorList = [...this.allAddonDecorList, ...[...addonTempList], ...[...decorTempList]];
            }
        }
        if (tabIndex === 2) {
            this.allAddonDecorList = [...this.allAddonDecorList, ...[...data.add_on_details], ...[...data.decor_details]];
        }
        this.setState({ singleProductSDetail: data });
        this.setState({ productDetailsModalShow: true });
    }

    productDetailsViewModalHide() {
        this.setState({ productDetailsModalShow: false });
    }

    fileOnChange = async (e) => {
        await this.setState({ picUpload: e.target.files[0] });
        document.getElementById("pic-preview").src = URL.createObjectURL(e.target.files[0]);
    }

    showChatSideBar(data) {
        this.currentChatId = data.chat_id;
        this.setState({ chatSideBarShow: true }, () => {
            this.getChatDetails((data.chat_id).toString());
            this.getOldMessage((data.chat_id).toString());
        });
    }

    hideChatSideBar() {
        document.getElementById("chat-name").innerHTML = null;
        this.setState({ chatSideBarShow: false })
    }

    componentWillUnmount() {
        PubSub.unsubscribe("CHAT DETAILS");
        PubSub.unsubscribe("MSG LIST RCV");
        PubSub.unsubscribe("MSG RCV");
    }

    async getChatDetails(chat_id) {
        this.chatDetails = [];
        this.setState({ chatUserData: [] });
        let postData = {
            "chat_id": chat_id
        }
        socketService.emitFunc("chat-fetch", JSON.stringify(postData));
    }

    async getOldMessage(chat_id) {
        this.setState({ chatMsgList: [] });
        let postData = {
            "chat_id": chat_id
        }
        socketService.emitFunc("msg-list-fetch", JSON.stringify(postData));
        socketService.emitFunc("join-admin", JSON.stringify(postData));
    }

    async getAllProductCategoryList() {
        let res = await getApi(urlApi.productCategoryList);
        if (res.responsecode === "200") {
            for (const categoryItem in res.data) {
                this.options.push({
                    value: res.data[categoryItem].product_category_name,
                    label: res.data[categoryItem].product_category_name
                })
            }
        }
    }
    multiDropDownOnchange = (updatedValue, t) => {
        let valueSpNew = [];
        for (const speciality of updatedValue) {
            valueSpNew.push(speciality.value);
        }
        this.setState({ valueSp: valueSpNew });
    }

    componentDidMount() {
        PubSub.subscribe("CHAT DETAILS", (msg, data) => {
            this.chatDetails = data.data.chat_details;
            this.setState({ chatDetails: data.data });
            this.setState({ chatUserData: data.data.chat_user });
        });

        PubSub.subscribe("MSG LIST RCV", (msg, data) => {
            this.setState({ chatMsgList: data.data.message_list.reverse() });
        });

        PubSub.subscribe("MSG RCV", (msg, data) => {
            if (this.currentChatId === data.chat_id) {
                let newList = [...this.state.chatMsgList, data];
                this.setState({ chatMsgList: newList });
            }
        });
        this.getAllProductCategoryList();
        this.getShopDetails();
        if (this.props.match.params.defaultActiveKey === "shopDetails") {
            this.getShopDetails();
            this.getAllOrderCountData();
            this.getProductCount();
            this.getAddonCount();
            this.getDecorCount();
        }
        if (this.props.match.params.defaultActiveKey === "menu") {
            this.getMenuList();
        }
        if (this.props.match.params.defaultActiveKey === "orderDetails") {
            this.getOrderList();
        }
    }

    async getProductCount() {
        let postData = {
            "baker_id": this.props.match.params.bakerID,
        }
        let resp = await postApi(postData, urlApi.productCountByBaker);
        if (resp.responsecode === "200") {
            this.productCount = resp.data.total_products;
        } else {
            this.productCount = "0";
        }
    }
    async getAddonCount() {
        let postData = {
            "baker_id": this.props.match.params.bakerID,
        }
        let resp = await postApi(postData, urlApi.addonCountByBaker);
        if (resp.responsecode === "200") {
            this.addonCount = resp.data.total_addon;
        } else {
            this.addonCount = "0";
        }
    }
    async getDecorCount() {
        let postData = {
            "baker_id": this.props.match.params.bakerID,
        }
        let resp = await postApi(postData, urlApi.decorCountByBaker);
        if (resp.responsecode === "200") {
            this.decorCount = resp.data.total_decor;
        } else {
            this.decorCount = "0";
        }
    }

    async getPendingOrderCountData({ date = "" } = {}) {
        let postData = {
            "date": date,
            "baker_id": this.props.match.params.bakerID,
        }
        let resp = await postApi(postData, urlApi.pendingOrderCountByBaker);
        if (resp.responsecode === "200") {
            this.setState({ pendingOrderData: resp.data });
        } else {
            this.setState({ pendingOrderData: resp.data });
        }
    }
    async getOngoingOrderCountData({ date = "" } = {}) {
        let postData = {
            "date": date,
            "baker_id": this.props.match.params.bakerID,
        }
        let resp = await postApi(postData, urlApi.onGoingOrderCountByBaker);
        if (resp.responsecode === "200") {
            this.setState({ ongoingOrderData: resp.data });
        } else {
            this.setState({ ongoingOrderData: resp.data });
        }
    }
    async getDeliveredOrderCountData({ date = "" } = {}) {
        let postData = {
            "date": date,
            "baker_id": this.props.match.params.bakerID,
        }
        let resp = await postApi(postData, urlApi.deliveredOrderCountByBaker);
        if (resp.responsecode === "200") {
            this.setState({ deliveredOrderData: resp.data });
        } else {
            this.setState({ deliveredOrderData: resp.data });
        }
    }

    getAllOrderCountData({ date = "" } = {}) {
        this.getPendingOrderCountData({ date: date });
        this.getOngoingOrderCountData({ date: date });
        this.getDeliveredOrderCountData({ date: date });
    }

    async resetFields() {
        document.getElementById("from-date-filter").value = "";
        document.getElementById("to-date-filter").value = "";
        document.getElementById("search-field-order").value = "";
        document.getElementById("status-dropdown-order").value = "6";
        this.getOrderList({ dateFilter: "" });
    }

    async resetFieldsHome() {
        document.getElementById("from-date-filter").value = "";
        document.getElementById("to-date-filter").value = "";
        this.getAllOrderCountData({ date: "" });
    }

    alert = (msg, responseCode) => {
        if (responseCode === "200") {
            toast.success(msg);
        }
        else if (responseCode === "500") {
            toast.error(msg);
        }
        else {
            toast.info(msg);
        }
    }

    async getShopDetails() {
        let resp = await getApi(urlApi.shopDetailsByBakerID + this.props.match.params.bakerID);
        if (resp.responsecode === "200") {
            this.setState({ shopData: resp.data });
        }
        else {
            this.alert(resp.message, "500");
        }
    }

    editDetailsFetch() {
        let specialties = this.state.shopData.speciality.split(',');
        let valueSpNew = [];
        for (const speciality of specialties) {
            valueSpNew.push(speciality);
        }
        this.setState({ valueSp: valueSpNew });
        document.getElementById("edit-shop-name").value = this.state.shopData.shop_name;
        document.getElementById("edit-shop-fssai").value = this.state.shopData.fssai_certificate;
        document.getElementById("edit-delivery-distance").value = this.state.shopData.delivery_distance;
        document.getElementById("open-time").value = this.state.shopData.delivery_open_time;
        document.getElementById("close-time").value = this.state.shopData.delivery_close_time;
        document.getElementById("pic-preview").src = this.state.shopData.shop_image;
        document.getElementById("edit-rating").value = this.state.shopData.rating;
        document.getElementById("edit-address-details").value = this.state.shopData.address;
        document.getElementById("edit-pincode").value = this.state.shopData.pincode;
        document.getElementById("edit-landmark").value = this.state.shopData.landmark;
        document.getElementById("edit-social-link-1").value = this.state.shopData.social_link_1;
        document.getElementById("edit-social-link-2").value = this.state.shopData.social_link_2;
    }

    async getMenuList() {
        this.setState({ page: 1 });
        let postData = {
            "baker_id": this.props.match.params.bakerID,
        }
        let resp = await postApi(postData, urlApi.productListByBaker + this.state.page);
        if (resp.responsecode === "200") {
            this.setState({ productList: resp.data.data });
            this.setState({ pagination: resp.data.pagination });
            this.totalProductCount = resp.data.pagination.total_items;
        }
        else {
            this.totalProductCount = "0";
        }
    }

    async handlePageChangeForMenu(pageNumber) {
        await this.setState({ page: pageNumber });
        this.getMenuList();
    }

    async resetTable() {
        await this.setState({ orderList: [] });
        await this.setState({
            pagination: ({
                active_page: 0,
                per_page: 10,
                total_items: 0
            })
        })
    }

    dateFilterVar = "";

    async getOrderList({ dateFilter = this.dateFilterVar } = {}) {
        this.dateFilterVar = dateFilter;
        this.setState({ page: 1 });
        let postData = {
            "baker_id": this.props.match.params.bakerID,
            "search_data": document.getElementById("search-field-order").value,
            "status": document.getElementById("status-dropdown-order").value,
            "date": dateFilter,
            "fromDate": document.getElementById("from-date-filter").value,
            "toDate": document.getElementById("to-date-filter").value
        }
        let resp = await postApi(postData, urlApi.ordersByBaker + this.state.page);
        if (resp.responsecode === "200") {
            this.setState({ orderList: resp.data.data });
            this.setState({ pagination: resp.data.pagination });
            this.totalOrderCount = resp.data.pagination.total_items;
        }
        else {
            this.resetTable();
        }
    }

    async onSearchOrder() {
        await this.setState({ page: 1 }, () => this.getOrderList());
    }

    showStatusUpdateModal(data, status) {
        this.bakerID = data.baker_id;
        this.orderID = data.order_id;
        this.status = status;
        this.setState({ statusUpdateModalShow: true });
    }

    hideStatusUpdateModal() {
        this.setState({ statusUpdateModalShow: false })
    }

    async updateOrderStatus(status, orderID, bakerID) {
        let postData = {
            "status": status,
            "order_id": orderID,
            "baker_id": bakerID
        }
        let resp = await postApi(postData, urlApi.orderStatusUpdate);
        this.hideStatusUpdateModal();
        if (resp.responsecode === "200") {
            this.getOrderList();
            this.alert(resp.message, resp.responsecode);
        }
    }

    async handlePageChangeForOrders(pageNumber) {
        await this.setState({ page: pageNumber });
        this.getOrderList();
    }

    async productDetailsByProductId(productID) {
        let resp = await getApi(urlApi.productDetailsByProductId + productID);
        if (resp.responsecode === "200") {
            this.setState({ singleProductSDetail: resp.data });
        }
        else {
            this.alert(resp.message, resp.responsecode);
        }
    }

    async onUpdateClick() {
        let speciality = "";
        for (let index = 0; index < this.state.valueSp.length; index++) {
            if (index === 0) {
                speciality = this.state.valueSp[index];
            } else {
                speciality = speciality + "," + this.state.valueSp[index];
            }
        }
        if (speciality.split(",").length > 2) {
            this.alert("Specialty Can't be more than 2", "500")
        }
        else {
            if (speciality !== "" || speciality) {
                if (this.state.picUpload) {
                    const formData = new FormData();
                    formData.append(
                        "file_data", this.state.picUpload,
                    );
                    let respPic = await postApi(formData, urlApi.shopPicUpload);
                    if (respPic.responsecode === "200") {
                        let postData = {
                            "shop_id": this.state.shopData.shop_id,
                            "shop_name": document.getElementById("edit-shop-name").value,
                            "delivery_distance": document.getElementById("edit-delivery-distance").value,
                            "open_time": document.getElementById("open-time").value,
                            "close_time": document.getElementById("close-time").value,
                            "speciality": speciality,
                            "shop_image": respPic.data,
                            "rating": document.getElementById("edit-rating").value ?? 0.00,
                            "address_details": document.getElementById("edit-address-details").value,
                            "landmark": document.getElementById("edit-landmark").value,
                            "pincode": document.getElementById("edit-pincode").value,
                            "fssai_number": document.getElementById("edit-shop-fssai").value,
                            "social_link_1": document.getElementById("edit-social-link-1").value,
                            "social_link_2": document.getElementById("edit-social-link-2").value,
                        }
                        let res = await postApi(postData, urlApi.shopDetailsUpdate);
                        if (res.responsecode === "200") {
                            this.alert(res.message, res.responsecode);
                            this.getShopDetails();
                        }
                        else {
                            this.alert(res.message, res.responsecode);
                        }
                    }
                }
                else {
                    let postData = {
                        "shop_id": this.state.shopData.shop_id,
                        "shop_name": document.getElementById("edit-shop-name").value,
                        "delivery_distance": document.getElementById("edit-delivery-distance").value,
                        "open_time": document.getElementById("open-time").value,
                        "close_time": document.getElementById("close-time").value,
                        "speciality": speciality,
                        "shop_image": document.getElementById("pic-preview").src,
                        "rating": document.getElementById("edit-rating").value ?? 0.00,
                        "address_details": document.getElementById("edit-address-details").value,
                        "landmark": document.getElementById("edit-landmark").value,
                        "pincode": document.getElementById("edit-pincode").value,
                        "fssai_number": document.getElementById("edit-shop-fssai").value,
                        "social_link_1": document.getElementById("edit-social-link-1").value,
                        "social_link_2": document.getElementById("edit-social-link-2").value,
                    }
                    let resp = await postApi(postData, urlApi.shopDetailsUpdate);
                    if (resp.responsecode === "200") {
                        this.alert(resp.message, resp.responsecode);
                        this.getShopDetails();
                    }
                    else {
                        this.alert(resp.message, resp.responsecode);
                    }
                }
            }
        }

    }

    showNotificationSideBar() {
        this.setState({ notificationSideBarShow: true })
    }

    hideNotificationSideBar() {
        this.setState({ notificationSideBarShow: false })
    }

    async sendNotification() {
        this.setState({ isComplete: false });
        if (this.state.picUpload) {
            const formData = new FormData();
            formData.append(
                "file_data", this.state.picUpload,
            );
            let respPic = await postApi(formData, urlApi.notificationPicUpload);
            if (respPic.responsecode === "200") {
                let postData = {
                    "type": "SINGLE_SHOP",
                    "title": document.getElementById('title').value,
                    "msg": document.getElementById('message').value,
                    "user_id": this.props.match.params.bakerID,
                    "image": respPic.data,
                }
                let resp = await postApi(postData, urlApi.sendNotification);
                this.hideNotificationSideBar();
                if (resp.responsecode === '200') {
                    this.setState({ isComplete: true });
                    this.alert(resp.message, resp.responsecode);
                    document.getElementById('title').value = null;
                    document.getElementById('message').value = null;
                    document.getElementById("pic-preview").src = null;
                } else {
                    this.alert(resp.message, resp.responsecode);
                }
            } else {
                this.alert(respPic.message, respPic.responsecode);
            }
        }
        else {
            let postData = {
                "type": "SINGLE_SHOP",
                "title": document.getElementById('title').value,
                "msg": document.getElementById('message').value,
                "user_id": this.props.match.params.bakerID,
            }
            let resp = await postApi(postData, urlApi.sendNotification);
            this.hideNotificationSideBar();
            if (resp.responsecode === '200') {
                this.setState({ isComplete: true });
                this.alert(resp.message, resp.responsecode);
                document.getElementById('title').value = null;
                document.getElementById('message').value = null;
                document.getElementById("pic-preview").src = null;
            } else {
                this.alert(resp.message, resp.responsecode);
            }
        }
    }

    render() {
        var x = (this.state.pagination.active_page - 1) * this.state.pagination.per_page + 1;
        return (
            <>
                <div className="container-fluid position-relative bg-white d-flex p-0">
                    <SidebarMenu isOpen={this.state.isOpen} />
                    <div className={this.state.isOpen ? "content open" : "content"}>
                        <Navbar menuBarClick={() => this.menuBarClick()} />
                        <div className="container-fluid">
                            <ToastContainer autoClose={1000} />
                            <div className="row g-4">
                                <div className="col-12">
                                    <div className="bg-light rounded h-100 p-2">
                                        <Tabs defaultActiveKey={this.props.match.params.defaultActiveKey ? this.props.match.params.defaultActiveKey : "shopDetails"}
                                            id="uncontrolled-tab-example"
                                            className="mb-3"
                                            onSelect={(e) => {
                                                this.props.history.push('/single-shop-view/' + e + "/" + this.props.match.params.bakerID);
                                                if (e === "shopDetails") {
                                                    this.getShopDetails();
                                                    this.getAllOrderCountData();
                                                    this.getProductCount();
                                                    this.getAddonCount();
                                                    this.getDecorCount();
                                                }
                                                if (e === "edit") {
                                                    this.editDetailsFetch();
                                                }
                                                if (e === "menu") {
                                                    this.getMenuList();
                                                }
                                                if (e === "orderDetails") {
                                                    this.getOrderList();
                                                }
                                            }
                                            }
                                        >
                                            <Tab eventKey="shopDetails" title={<Link className='tab-link-color' to="/single-shop-view/shopDetails">Shop Details</Link>}>
                                                <div className="row mb-4 mt-md-5" style={{ marginTop: 90 }}>
                                                    <div className="col text-center">
                                                        <img src={this.state.shopData.shop_image} alt="" style={{ height: 300, width: 300, borderRadius: "50%", objectFit: 'cover', border: '2px solid #f08632' }} />
                                                    </div>
                                                    <div className="col d-flex flex-column justify-content-between">
                                                        <h4>{this.state.shopData.shop_name} [ ID : {this.props.match.params.bakerID} ]</h4>
                                                        <h5>{this.state.shopData.baker_name}</h5>
                                                        <h6>Mobile : {this.state.shopData.mobile}</h6>
                                                        <p className='m-0'><span style={{ fontWeight: 600 }}>Email :</span> {this.state.shopData.email}</p>
                                                        <p className='m-0'><span style={{ fontWeight: 600 }}>Specialty :</span> {this.state.shopData.speciality}</p>
                                                        <p className='m-0'><span style={{ fontWeight: 600 }}>Address :</span> {this.state.shopData.address}</p>
                                                        <p className='m-0'><span style={{ fontWeight: 600 }}>PinCode :</span> {this.state.shopData.pincode}</p>
                                                        <p className='m-0'><span style={{ fontWeight: 600 }}>Landmark :</span> {this.state.shopData.landmark}</p>
                                                        <p className='m-0'><span style={{ fontWeight: 600 }}>Social Link 1 :</span> <span className='badge bg-primary'> {this.state.shopData.social_link_1 ? <Link className='text-light' target="_blank" to={{ pathname: this.state.shopData.social_link_1 }}>View</Link> : "--"}</span></p>
                                                    </div>
                                                    <div className="col d-flex flex-column justify-content-between">
                                                        <p className='m-0'>
                                                            <span style={{ fontWeight: 600 }}>Shop Status : </span>
                                                            <span className={this.state.shopData.status === 1 ? 'badge bg-success' : this.state.shopData.status === 0 ? 'badge bg-secondary' : 'badge bg-danger'}>{this.state.shopData.status === 1 ? "Approved" : this.state.shopData.status === 0 ? "In Review" : "Rejected"}</span>
                                                        </p>
                                                        <p className='m-0'>
                                                            <span style={{ fontWeight: 600 }}>FSSAI Certificate :
                                                            </span> {this.state.shopData.fssai_certificate}<span> {this.state.shopData.status === 1 ? <MdVerified color='green' /> : this.state.shopData.status === 0 ? <BsClockHistory /> : <FcCancel />}</span>
                                                        </p>
                                                        <p className='m-0'>
                                                            <span style={{ fontWeight: 600 }}>Rating :</span> <TbStar color='gold' /> {this.state.shopData.rating}
                                                        </p>
                                                        <p className='m-0'>
                                                            <span style={{ fontWeight: 600 }}>Create Date :</span> {dateTimeConverter(this.state.shopData.create_date)}
                                                        </p>
                                                        <p className='m-0'>
                                                            <span style={{ fontWeight: 600 }}>Last Login :</span> {dateTimeConverter(this.state.shopData.last_login)}
                                                        </p>
                                                        <p className='m-0'>
                                                            <span style={{ fontWeight: 600 }}>Delivery Distance :</span><strong> {this.state.shopData.delivery_distance}KM</strong>
                                                        </p>
                                                        <p className='m-0'>
                                                            <span style={{ fontWeight: 600 }}>Delivery Time :</span> {(this.state.shopData.delivery_open_time) + "-" + (this.state.shopData.delivery_close_time)}
                                                        </p>
                                                        <p className='m-0'>
                                                            <span style={{ fontWeight: 600 }}>Social Link 2 :</span> <span className='badge bg-primary'> {this.state.shopData.social_link_2 ? <Link className='text-light' target={"_blank"} to={{ pathname: this.state.shopData.social_link_2 }}>View</Link> : "--"}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-2 ms-auto">
                                                        <button className='w-100 btn-custom action-btn-3' onClick={() => this.showNotificationSideBar()}>Send Notification</button>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row mb-2">
                                                    <div className="col-md mb-1">
                                                        <div className="card rounded-4 border-bottom text-center p-2">
                                                            <MdInventory size={40} color="#2a9d8f" className='me-auto ms-auto' />
                                                            Total Product : {this.productCount}
                                                        </div>
                                                    </div>
                                                    <div className="col-md mb-1">
                                                        <div onClick={() => this.addonViewModalShow()} className="card rounded-4 border-bottom text-center p-2" style={{ cursor: 'pointer' }}>
                                                            <BsPuzzle size={40} color="#e9c46a" className='me-auto ms-auto' />
                                                            Total Addon : {this.addonCount}
                                                        </div>
                                                    </div>
                                                    <div className="col-md mb-1">
                                                        <div onClick={() => this.decorViewModalShow()} className="card rounded-4 border-bottom text-center p-2" style={{ cursor: 'pointer' }}>
                                                            <GiCandles size={40} color="#f4a261" className='me-auto ms-auto' />
                                                            Total Decor : {this.decorCount}
                                                        </div>
                                                    </div>
                                                    <div className="col-md mb-1">
                                                        <div className="card rounded-4 border-bottom text-center p-2">
                                                            <GiShoppingBag size={40} color="#e76f51" className='me-auto ms-auto' />
                                                            Total Order : {(this.state.pendingOrderData.all_pending_orders + this.state.ongoingOrderData.all_ongoing_orders + this.state.deliveredOrderData.all_delivered_orders) ?? "0"}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-2 gx-2">
                                                    <div className="col-md-1 col mb-1">
                                                        <button className='btn-custom action-btn-5 w-100' onClick={() => this.getAllOrderCountData({ dateFilter: "" })}>All</button>
                                                    </div>
                                                    <div className="col-md-1 col mb-1">
                                                        <button className='btn-custom action-btn-1 w-100' onClick={() => this.getAllOrderCountData({ dateFilter: "DAILY" })}>Today</button>
                                                    </div>
                                                    <div className="col-md-1 col mb-1">
                                                        <button className='btn-custom action-btn-4 w-100' onClick={() => this.getAllOrderCountData({ dateFilter: "YESTERDAY" })}>Yesterday</button>
                                                    </div>
                                                    <div className="col-md-1 col mb-1">
                                                        <button className='btn-custom action-btn-3 w-100 text-nowrap' onClick={() => this.getAllOrderCountData({ dateFilter: "WEEKLY" })}>Last Week</button>
                                                    </div>
                                                    <div className="col-md-1 col mb-1">
                                                        <button className='btn-custom action-btn-2 w-100 text-nowrap' onClick={() => this.getAllOrderCountData({ dateFilter: "MONTHLY" })}>Last Month</button>
                                                    </div>
                                                </div>
                                                <div className="row gx-2 mb-2">
                                                    <div className="col-md-2 col-5 mb-2">
                                                        <input type="date" className='form-control form-control-sm w-100' id='from-date-filter' />
                                                    </div>
                                                    <div className="col-md-1 mb-2 col text-center"> <BsArrowLeftRight /> </div>
                                                    <div className="col-md-2 col-5 mb-2">
                                                        <input type="date" className='form-control form-control-sm w-100' id='to-date-filter' />
                                                    </div>
                                                    <div className="col-md-1 col">
                                                        <button className='btn-custom action-btn-1 w-100' onClick={() => this.getAllOrderCountData({ dateFilter: "CUSTOM" })}>Search</button>
                                                    </div>
                                                    <div className="col-md-2 col-2">
                                                        <button className='btn-custom action-btn-5 w-sm-100' onClick={() => this.resetFieldsHome()}><GrPowerReset size={20} /></button>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-md mb-1">
                                                        <div className="card rounded-4 border-bottom text-center p-2">
                                                            <FaShoppingBag size={40} color="#219ebc" className='me-auto ms-auto' />
                                                            Pending Orders : {this.state.pendingOrderData.all_pending_orders ?? "0"}
                                                            <br />
                                                            Amount : ₹ {this.state.pendingOrderData.total_amount ?? "0"}
                                                        </div>
                                                    </div>
                                                    <div className="col-md mb-1">
                                                        <div className="card rounded-4 border-bottom text-center p-2">
                                                            <FaShoppingBasket size={40} color="#ffb703" className='me-auto ms-auto' />
                                                            Ongoing Orders : {this.state.ongoingOrderData.all_ongoing_orders ?? "0"}
                                                            <br />
                                                            Amount : ₹ {this.state.ongoingOrderData.total_amount ?? "0"}
                                                        </div>
                                                    </div>
                                                    <div className="col-md mb-1">
                                                        <div className="card rounded-4 border-bottom text-center p-2">
                                                            <HiShoppingBag size={40} color="#588157" className='me-auto ms-auto' />
                                                            Delivered Orders : {this.state.deliveredOrderData.all_delivered_orders ?? "0"}
                                                            <br />
                                                            Amount : ₹ {this.state.deliveredOrderData.total_amount ?? "0"}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="edit" title={<Link className='tab-link-color' to="/single-shop-view/edit">Edit</Link>}>
                                                <form className='mt-md-5' style={{ marginTop: 90 }} >
                                                    <input id='shop-id' type="text" style={{ display: "none" }} />
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group mb-2">
                                                                <label htmlFor="edit-shop-name">Shop Name</label>
                                                                <div className="input-group input-group-sm">
                                                                    <input id="edit-shop-name" type="text" className="form-control form-control-sm" />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text">
                                                                            <span><TbUser /></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-2">
                                                                <label htmlFor="">FSSAI Number</label>
                                                                <div className="input-group input-group-sm">
                                                                    <input id="edit-shop-fssai" type="text" className="form-control" maxLength={14} />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text">
                                                                            <span><TbCertificate /></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-2">
                                                                <label htmlFor="">Delivery Distance (KM)</label>
                                                                <div className="input-group input-group-sm">
                                                                    <input id="edit-delivery-distance" type="text" className="form-control" />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text">
                                                                            <span><GiPathDistance /></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <label for="">Open time:</label>
                                                                    <input className='form-control' type="time" id="open-time" />
                                                                </div>
                                                                <div className="col">
                                                                    <label for="">Close time:</label>
                                                                    <input className='form-control' type="time" id="close-time" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-2">
                                                                <label htmlFor="">Profile Picture :&nbsp;&nbsp;&nbsp;</label>
                                                                <img id="pic-preview" style={{ maxHeight: 80 }} alt="" />
                                                            </div>
                                                            <div className="input-group mb-2">
                                                                <input id="edit-photo" type="file" className="form-control form-control-sm" onChange={this.fileOnChange} />
                                                            </div>

                                                            <div className="form-group mb-2">
                                                                <label htmlFor="">Specialty</label>
                                                                <div className="input-group input-group-sm">
                                                                    <Select id='edit-speciality' value={this.options.filter(({ value }) => this.state.valueSp.includes(value))} onChange={this.multiDropDownOnchange} isMulti className="basic-multi-select w-100" options={this.options} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group mb-2">
                                                                <label htmlFor="">Rating</label>
                                                                <div className="input-group input-group-sm">
                                                                    <input id="edit-rating" type="text" className="form-control" />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text">
                                                                            <span><TbStar /></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-2">
                                                                <label htmlFor="">Address</label>
                                                                <div className="input-group input-group-sm">
                                                                    <input id="edit-address-details" type="text" className="form-control" />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text">
                                                                            <span><TbLocation /></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-2">
                                                                <label htmlFor="">PinCode</label>
                                                                <div className="input-group input-group-sm">
                                                                    <input id="edit-pincode" type="text" className="form-control" />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text">
                                                                            <span><TbCurrentLocation /></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-2">
                                                                <label htmlFor="">Landmark</label>
                                                                <div className="input-group input-group-sm">
                                                                    <input id="edit-landmark" type="text" className="form-control" />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text">
                                                                            <span><TbPin /></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-2">
                                                                <label htmlFor="edit-social-link-1">Social Link 1</label>
                                                                <div className="input-group input-group-sm">
                                                                    <input id="edit-social-link-1" type="text" className="form-control" />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text">
                                                                            <span><TbPin /></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-2">
                                                                <label htmlFor="edit-social-link-2">Social Link 2</label>
                                                                <div className="input-group input-group-sm">
                                                                    <input id="edit-social-link-2" type="text" className="form-control" />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text">
                                                                            <span><TbPin /></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className="row">
                                                    <div className="col-6 ms-auto">
                                                        <button className='btn-custom w-100 action-btn-1' onClick={() => this.onUpdateClick()}>Update</button>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="menu" title={<Link className='tab-link-color' to="/single-shop-view/menu">Menu</Link>}>
                                                <h6 className='mb-2 mt-md-5' style={{ marginTop: 90 }}>Total Products : {this.totalProductCount}</h6>
                                                <div className='mb-2'>
                                                    <button className='btn-custom action-btn-1 m-1' onClick={() => this.addonViewModalShow()}>Addon</button>
                                                    <button className='btn-custom action-btn-2 m-1' onClick={() => this.decorViewModalShow()}>Decor</button>
                                                </div>
                                                {this.state.productList.length > 0 ?
                                                    <div className="row">
                                                        {this.state.productList.map((data, index) => {
                                                            return (
                                                                <div className="col-md-6" key={index}>
                                                                    <ProductComponent cursor="pointer" data={data} onClick={() => this.productDetailsViewModalShow(data, 1)} />
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    : <div className="row">
                                                        <div className="col-12">
                                                            <h4 className='text-danger text-center'>No Product Available</h4>
                                                        </div>
                                                    </div>
                                                }
                                                <div className='me-2' style={{ float: 'right', display: this.state.productList.length > 0 ? 'unset' : 'none' }}>
                                                    <Pagination
                                                        activePage={parseInt(this.state.pagination.active_page)}
                                                        itemsCountPerPage={this.state.pagination.per_page}
                                                        totalItemsCount={this.state.pagination.total_items}
                                                        pageRangeDisplayed={5}
                                                        onChange={this.handlePageChangeForMenu.bind(this)}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </Tab>
                                            <Tab eventKey="orderDetails" title={<Link className='tab-link-color' to="/single-shop-view/orderDetails">Order Details</Link>}>
                                                <div className="row g-4">
                                                    <div className="col-12">
                                                        <div className="bg-light rounded h-100 p-2">
                                                            <h6 className="mb-2 mt-md-5" style={{ marginTop: 90 }}>Order Details || Total : {this.state.pagination.total_items ?? "0"}</h6>
                                                            <div className="row mb-2 gx-2">
                                                                <div className="col-md-1 col mb-1">
                                                                    <button className='btn-custom action-btn-5 w-100' onClick={() => this.getOrderList({ dateFilter: "" })}>All</button>
                                                                </div>
                                                                <div className="col-md-1 col mb-1">
                                                                    <button className='btn-custom action-btn-1 w-100' onClick={() => this.getOrderList({ dateFilter: "DAILY" })}>Today</button>
                                                                </div>
                                                                <div className="col-md-1 col mb-1">
                                                                    <button className='btn-custom action-btn-4 w-100' onClick={() => this.getOrderList({ dateFilter: "YESTERDAY" })}>Yesterday</button>
                                                                </div>
                                                                <div className="col-md-1 col mb-1">
                                                                    <button className='btn-custom action-btn-3 w-100 text-nowrap' onClick={() => this.getOrderList({ dateFilter: "WEEKLY" })}>Last Week</button>
                                                                </div>
                                                                <div className="col-md-1 col mb-1">
                                                                    <button className='btn-custom action-btn-2 w-100 text-nowrap' onClick={() => this.getOrderList({ dateFilter: "MONTHLY" })}>Last Month</button>
                                                                </div>
                                                            </div>
                                                            <div className="row gx-2 mb-2">
                                                                <div className="col-md-2 col-5 mb-2">
                                                                    <input type="date" className='form-control form-control-sm w-100' id='from-date-filter' />
                                                                </div>
                                                                <div className="col-md-1 mb-2 col text-center"> <BsArrowLeftRight /> </div>
                                                                <div className="col-md-2 col-5 mb-2">
                                                                    <input type="date" className='form-control form-control-sm w-100' id='to-date-filter' />
                                                                </div>
                                                                <div className="col-md-1 col">
                                                                    <button className='btn-custom action-btn-1 w-100' onClick={() => this.getOrderList({ dateFilter: "CUSTOM" })}>Search</button>
                                                                </div>
                                                                <div className="col-md-2 col-2">
                                                                    <button className='btn-custom action-btn-5 w-sm-100' onClick={() => this.resetFields()}><GrPowerReset size={20} /></button>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mb-3">
                                                                <form onSubmit={(e) => { e.preventDefault(); this.onSearchOrder() }}>
                                                                    <div className="row gx-1 search-section">
                                                                        <div className="col-md-3 col-6">
                                                                            <input className='form-control form-control-sm w-100' id='search-field-order' placeholder='Order ID' />
                                                                        </div>
                                                                        <div className="col-md-3 col-4">
                                                                            <select id='status-dropdown-order' defaultValue={6} className='form-control form-control-sm w-100' style={{ width: 80 }}>
                                                                                <option value={6}>ALL Orders</option>
                                                                                <option value={0}>Pending</option>
                                                                                <option value={1}>Accepted</option>
                                                                                <option value={2}>Rejected</option>
                                                                                <option value={3}>Chat</option>
                                                                                <option value={4}>Finalized</option>
                                                                                <option value={5}>Delivered</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="col-md-2 col-2">
                                                                            <button className='btn-custom action-btn-5 w-sm-100' type='submit'><TbSearch size={20} /></button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            {this.state.orderList.length > 0 ?
                                                                <div className="table-responsive">
                                                                    <table className="table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="col">#</th>
                                                                                <th className="col">Order ID</th>
                                                                                <th className="col">Product Name</th>
                                                                                <th className="col">Price</th>
                                                                                <th className="col">Customer Name</th>
                                                                                <th className="col">Address</th>
                                                                                <th className="col">Customer Note</th>
                                                                                <th className="col">Baker Note</th>
                                                                                <th className="col">Delivery</th>
                                                                                <th className="col">Created</th>
                                                                                <th className="col">Modified</th>
                                                                                <th className="col">Status</th>
                                                                                <th className="col">Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {this.state.orderList.map((data, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <th scope="row">{x++}</th>
                                                                                        <td>1000{data.order_id}</td>
                                                                                        <td className='text-left'>
                                                                                            <span className='table-img'><img src={data.picture} alt="" /></span><br />
                                                                                            <span>
                                                                                                {data.preference !== 3 ?
                                                                                                    <TbSquareDot color={data.preference === 2 ? 'red' : data.preference === 1 ? 'green' : 'grey'} /> :
                                                                                                    <span><TbSquareDot color='red' />
                                                                                                        <TbSquareDot color='green' /> </span>}
                                                                                                {data.name}</span>
                                                                                                <span className='fw-bold'> X {data.quantity}</span>
                                                                                        </td>
                                                                                        <td>₹{data.price - data.coupon_value} <br /> <span style={{ display: data.coupon_code !== "NONE" ? 'unset' : 'none' }}>Coupon : {data.coupon_code}</span> </td>
                                                                                        <td className='text-left'>
                                                                                            <Link to={"/single-customer-view/" + data.customer_id} target="_blank">
                                                                                                <span className='table-img'><img src={data.profile_pic} alt="" /></span><br />
                                                                                                <span>{data.customer_name}</span>
                                                                                            </Link>
                                                                                        </td>
                                                                                        <td>{data.address_details.address_details}</td>
                                                                                        <td> {data.customer_note ?? "--"}</td>
                                                                                        <td>{data.baker_note ?? "--"}</td>
                                                                                        <td>{dateTimeConverter(data.delivery_time)}</td>
                                                                                        <td>{dateTimeConverter(data.create_date)}</td>
                                                                                        <td>
                                                                                            <span>{dateTimeConverter(data.modify_date)}</span><br />
                                                                                            <span>{data.modified_by === data.baker_id ? data.shop_name : data.customer_name}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <td>
                                                                                                <select value={data.order_status} onChange={(e) => this.showStatusUpdateModal(data, e.target.value)} className={`form-control fw-bold form-control-sm text-${(data.order_status === 0 || data.order_status === 3) ? "dark" : "white"} bg-${data.order_status === 0 ? "info" : data.order_status === 1 ? "success" : data.order_status === 2 ? "danger" : data.order_status === 3 ? "warning" : data.order_status === 4 ? "primary" : "secondary"}`} style={{ width: 80 }}>
                                                                                                    <option value={0}>Pending</option>
                                                                                                    <option value={1}>Accepted</option>
                                                                                                    <option value={2}>Rejected</option>
                                                                                                    <option value={3}>Chat</option>
                                                                                                    <option value={4}>Finalized</option>
                                                                                                    <option value={5}>Delivered</option>
                                                                                                </select>
                                                                                            </td>
                                                                                        </td>
                                                                                        <td>
                                                                                            <button className='btn-custom action-btn-2 m-1' onClick={() => this.showChatSideBar(data)}>Chat</button>
                                                                                            <button className='btn-custom action-btn-4 m-1' onClick={() => this.productDetailsViewModalShow(data, 2)}>Order Details</button>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                : <h4 className='text-danger text-center'>No Order Details Available </h4>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='me-2' style={{ float: 'right', display: this.state.orderList.length > 0 ? 'unset' : 'none' }}>
                                                    <Pagination
                                                        activePage={parseInt(this.state.pagination.active_page)}
                                                        itemsCountPerPage={this.state.pagination.per_page}
                                                        totalItemsCount={this.state.pagination.total_items}
                                                        pageRangeDisplayed={5}
                                                        onChange={this.handlePageChangeForOrders.bind(this)}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ProductDetailsModalComponent
                    show={this.state.productDetailsModalShow}
                    productData={this.state.singleProductSDetail}
                    addonDecorData={this.allAddonDecorList}
                    productDetailsViewModalHide={() => this.productDetailsViewModalHide()}
                />
                <Offcanvas show={this.state.chatSideBarShow} onHide={() => this.hideChatSideBar()} placement={'end'}>
                    <Offcanvas.Header closeButton style={{ height: 72 }}>
                        <Offcanvas.Title>
                            <span className='table-img'>
                                <img src={this.chatDetails.chat_logo} alt="" />
                            </span>
                            <span id='chat-name'>{this.chatDetails.chat_name}</span>
                        </Offcanvas.Title>

                    </Offcanvas.Header>
                    <Offcanvas.Body className='p-0'>
                        {this.state.chatUserData.length > 0 ?
                            <div className="col-12 mb-1 d-flex justify-content-between">
                                <div className="px-2" style={{ display: this.state.chatUserData.length > 0 ? "unset" : "none" }}>
                                    <img src={this.state.chatUserData.length > 0 ? this.state.chatUserData[0].user_pic : "ProductImage"} alt="" style={{ height: 30, width: 30, objectFit: 'cover', borderRadius: 50 }} />
                                    {this.state.chatUserData.length > 0 ? this.state.chatUserData[0].user_name : ""}
                                </div>
                                <div className="" style={{ display: this.state.chatUserData.length > 1 ? "unset" : "none" }}>
                                    <img src={this.state.chatUserData.length > 1 ? this.state.chatUserData[1].user_pic : "ProductImage"} alt="" style={{ height: 30, width: 30, objectFit: 'cover', borderRadius: 50 }} />
                                    {this.state.chatUserData.length > 1 ? this.state.chatUserData[1].user_name : ""}
                                </div>
                            </div>
                            :
                            <div className="col-12">
                                No One Joined The Chat Yet!
                            </div>
                        }
                        <ChatView
                            chatUserData={this.state.chatUserData}
                            chatMsgList={this.state.chatMsgList}
                        />
                    </Offcanvas.Body>
                </Offcanvas>

                <ConfirmationModal
                    show={this.state.statusUpdateModalShow}
                    headerText="Update Status"
                    bodyText="Are You Sure Want To Update Status?"
                    closeFunction={() => this.hideStatusUpdateModal()}
                    okFunction={() => this.updateOrderStatus(this.status, this.orderID, this.bakerID,)}
                />

                <Offcanvas show={this.state.notificationSideBarShow} onHide={() => this.hideNotificationSideBar()} placement={'end'}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Send Notification</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="form-group mb-2">
                            <label htmlFor="">Title</label>
                            <input id='title' className='form-control' />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="">Message</label>
                            <textarea id='message' className='form-control'></textarea>
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="">Picture :&nbsp;&nbsp;&nbsp;</label>
                            <img id="pic-preview" style={{ maxHeight: 80, width: 'auto', borderRadius: 5 }} alt="" />
                        </div>
                        <div className="input-group mb-2">
                            <input id="edit-photo" type="file" className="form-control form-control-sm" onChange={this.fileOnChange} />
                        </div>
                        <button className='btn-custom action-btn-1 w-100 mt-2' onClick={() => this.sendNotification()}>Send</button>
                    </Offcanvas.Body>
                </Offcanvas>

                <Modal show={this.state.addonListModalShow} onHide={() => this.addonViewModalHide()}>
                    <Modal.Header closeButton style={{ padding: '1px 20px' }}>
                        <Modal.Title>Addon List</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: "10px 14px", maxHeight: '90vh', overflowY: 'scroll' }}>
                        {/* <div className="text-center"><h3 style={{ borderBottom: "4px solid teal" }}>Addon List</h3></div> */}
                        <div className="row">
                            {this.state.addonList.length > 0 ?
                                this.state.addonList.map((data, index) => {
                                    return (
                                        <div key={index} className="col-md-3 col-4 mb-2"><AddonDecorComponent data={data} type="ADDON" /></div>
                                    )
                                }) :
                                <div className="text-center">
                                    <h4 className="text-danger">
                                        No Addon Added!
                                    </h4>
                                </div>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'none' }}>
                        <Button className='action-btn-2' onClick={() => this.addonViewModalHide()}>
                            Close
                        </Button>
                        <Button className='action-btn-1' onClick={() => this.addonViewModalHide()}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.decorListModalShow} onHide={() => this.decorViewModalHide()}>
                    <Modal.Header closeButton style={{ padding: '1px 20px' }}>
                        <Modal.Title>Decor List</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: "10px 14px", maxHeight: '90vh', overflowY: 'scroll' }}>
                        {/* <div className="text-center"><h3 style={{ borderBottom: "4px solid teal" }}>Decor List</h3></div> */}
                        <div className="row">
                            {this.state.decorList.length > 0 ?
                                this.state.decorList.map((data, index) => {
                                    return (
                                        <div key={index} className="col-md-3 col-4 mb-2"><AddonDecorComponent data={data} type="DECOR" /></div>
                                    )
                                }) :
                                <div className="text-center">
                                    <h4 className="text-danger">
                                        No Decor Added!
                                    </h4>
                                </div>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'none' }}>
                        <Button className='action-btn-2' onClick={() => this.decorViewModalHide()}>
                            Close
                        </Button>
                        <Button className='action-btn-1' onClick={() => this.decorViewModalHide()}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}
