import React, { Component } from 'react'
import { TbSearch } from 'react-icons/tb';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { getApi, postApi } from '../api/call.api';
import urlApi from '../api/url.api';
import Navbar from '../components/Navbar';
import SidebarMenu from '../components/SidebarMenu'
import { dateTimeConverter } from '../utils/dateTimeConverter';
import Switch from "react-switch";
import ConfirmationModal from '../components/ConfirmationModal';
import { Button, Modal } from 'react-bootstrap';

export default class Coupon extends Component {
    constructor(p) {
        super(p);
        this.state = {
            isOpen: false,
            tableData: [],
            pagination: ({
                active_page: 0,
                per_page: 10,
                total_items: 0
            }),
            page: 1,
            count: 10,
            statusUpdateModalShow: false,
            addCouponModalShow: false,
            editCouponDetailsModalShow: false,
            staticCategoryList: [],
            staticSubCategoryList: [],
            couponTypeDropdownOnchange: "",
            selectCustomer: "",
            selectBaker: "",
        }
        this.updateCouponStatus = this.updateCouponStatus.bind(this);
    }

    menuBarClick() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    alert = (msg, responseCode) => {
        if (responseCode === "200") {
            toast.success(msg);
        }
        else if (responseCode === "500") {
            toast.error(msg);
        }
        else {
            toast.info(msg);
        }
    }

    async resetTable() {
        await this.setState({ tableData: [] })
        await this.setState({
            pagination: ({
                active_page: 0,
                per_page: 10,
                total_items: 0
            })
        })
    }

    componentDidMount() {
        this.allCouponList();
    }

    async allCouponList() {
        let postData = {
            "search_data": document.getElementById("search-field").value,
            "status": document.getElementById("status-dropdown").value,
        }
        let resp = await postApi(postData, urlApi.allCoupon + this.state.page);
        if (resp.responsecode === "200") {
            this.setState({ tableData: resp.data.data });
            this.setState({ pagination: resp.data.pagination });
        }
        else {
            this.resetTable();
            this.alert(resp.message, "500");
        }
    }

    async handlePageChange(pageNumber) {
        await this.setState({ page: pageNumber });
        this.allCouponList();
    }

    async onSearch() {
        await this.setState({ page: 1 }, () => this.allCouponList());
    }

    showStatusUpdateModal(data) {
        this.couponId = data.coupon_id;
        this.status = data.status;
        this.setState({ statusUpdateModalShow: true });
    }

    hideStatusUpdateModal() {
        this.setState({ statusUpdateModalShow: false })
    }

    showAddCouponModal() {
        this.setState({ addCouponModalShow: true });
    }

    hideAddCouponModal() {
        this.setState({selectCustomer : "ALL"});
        this.setState({selectBaker : "ALL"});
        this.setState({ addCouponModalShow: false });
    }

    showEditCouponDetailsModal(data) {
        this.setState({ editCouponDetailsModalShow: true }, () => {
            document.getElementById("user-limit-edit").value = data.user_limit;
            document.getElementById("coupon-id-edit").value = data.coupon_id;
            document.getElementById("max-limit-edit").value = data.max_limit;
            document.getElementById("coupon-start-date-edit").value = data.start_time.split(".")[0];
            document.getElementById("coupon-end-date-edit").value = data.end_time.split(".")[0];
        });
    }

    hideEditCouponDetailsModal() {
        this.setState({ editCouponDetailsModalShow: false })
    }

    async updateCouponStatus(couponId, status) {
        let postData = {
            "coupon_id": couponId,
            "status": status
        }
        let resp = await postApi(postData, urlApi.couponStatusUpdate);
        this.hideStatusUpdateModal();
        if (resp.responsecode === "200") {
            this.alert(resp.message, "200");
            if (this.state.tableData.length === 1 && this.state.page > 1) {
                let pageNumber = 1;
                this.setState({ page: pageNumber }, () => this.allCouponList());
            }
            else {
                this.allCouponList();
            }
        }
        else {
            this.alert(resp.message, "500");
        }
    }

    async getStaticProductCategoryList() {
        let res = await getApi(urlApi.productCategoryList);
        if (res.responsecode === "200") {
            this.setState({ staticCategoryList: res.data })
        }
    }

    async getStaticProductSubCategoryList() {
        let res = await getApi(urlApi.allProductSubCategoryList);
        if (res.responsecode === "200") {
            this.setState({ staticSubCategoryList: res.data })
        }
    }

    async addCoupon() {
        let postData = {
            "coupon_code": document.getElementById("coupon-code").value + document.getElementById("coupon-type").value,
            "user_id": document.getElementById("select-customer-type").value === "ALL" ? "0" : document.getElementById("customer-id").value,
            "baker_id": document.getElementById("select-baker-type").value === "ALL" ? "0" : document.getElementById("baker-id").value,
            "coupon_type": document.getElementById("coupon-type").value,
            "type_id": (document.getElementById("category-dropdown").value || document.getElementById("sub-category-dropdown").value || document.getElementById("product-id").value),
            "user_limit": document.getElementById("user-limit").value,
            "max_limit": document.getElementById("max-limit").value,
            "campaign": document.getElementById("coupon-campaign").value,
            "description": document.getElementById("coupon-description").value,
            "discount_value": document.getElementById("coupon-discount").value,
            "is_fixed": document.getElementById("coupon-discount-type").value,
            "start_time": document.getElementById("coupon-start-date").value,
            "end_time": document.getElementById("coupon-end-date").value,
        }
        let resp = await postApi(postData, urlApi.createCoupon);
        this.hideAddCouponModal();
        if (resp.responsecode === "200") {
            this.allCouponList();
            this.alert(resp.message, "200");
        }
        else {
            this.alert(resp.message, "500");
        }
    }

    async updateCouponDetails() {
        let postData = {
            "coupon_id": document.getElementById("coupon-id-edit").value,
            "user_limit": document.getElementById("user-limit-edit").value,
            "max_limit": document.getElementById("max-limit-edit").value,
            "start_date": document.getElementById("coupon-start-date-edit").value,
            "end_date": document.getElementById("coupon-end-date-edit").value,
        }
        let resp = await postApi(postData, urlApi.couponDetailsUpdate);
        this.hideEditCouponDetailsModal();
        if (resp.responsecode === "200") {
            this.alert(resp.message, "200");
            if (this.state.tableData.length === 1 && this.state.page > 1) {
                let pageNumber = 1;
                this.setState({ page: pageNumber }, () => this.allCouponList());
            }
            else {
                this.allCouponList();
            }
        }
        else {
            this.alert(resp.message, "500");
        }
    }

    render() {
        var x = (this.state.pagination.active_page - 1) * this.state.pagination.per_page + 1;
        return (
            <>
                <div className="container-fluid position-relative bg-white d-flex p-0">
                    <SidebarMenu isOpen={this.state.isOpen} />
                    <div className={this.state.isOpen ? "content open" : "content"}>
                        <Navbar menuBarClick={() => this.menuBarClick()} />
                        <div className="container-fluid">
                            <ToastContainer autoClose={1000} />
                            <div className="row g-4">
                                <div className="col-12 px-0">
                                    <div className="rounded h-100 p-2">
                                        <div className="d-flex justify-content-between pe-md-4">
                                            <h6 className="mb-4">Coupons || Total : {this.state.pagination.total_items}</h6>
                                            <button className='btn-custom action-btn-1 mb-2' onClick={() => this.showAddCouponModal()}>Add Coupon</button>
                                        </div>
                                        <div className="col-12 mb-2">
                                            <form onSubmit={(e) => { e.preventDefault(); this.onSearch() }}>
                                                <div className="row gx-1 search-section">
                                                    <div className="col-md-3 col-6">
                                                        <input className='form-control form-control-sm' id='search-field' placeholder='Coupon Code' />
                                                    </div>
                                                    <div className="col-md-3 col-4">
                                                        <select className='form-control form-control-sm w-100' id='status-dropdown' defaultValue={""}>
                                                            <option value="">All Coupons</option>
                                                            <option value="1">Active Coupons</option>
                                                            <option value="0">Inactive Coupons</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2 col-2">
                                                        <button className='btn-custom action-btn-5 w-sm-100' type='submit'><TbSearch size={20} /></button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="table-responsive">
                                            {this.state.tableData.length > 0 ?
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="col">#</th>
                                                            <th className="col">Coupon</th>
                                                            <th className="col">Discount</th>
                                                            <th className="col">User</th>
                                                            <th className="col">Baker</th>
                                                            <th className="col">Type</th>
                                                            <th className="col">User Limit</th>
                                                            <th className="col">Max Limit</th>
                                                            <th className="col">Use Count</th>
                                                            <th className="col">Campaign</th>
                                                            <th className="col">Description</th>
                                                            <th className="col">Status</th>
                                                            <th className="col">Duration</th>
                                                            <th className="col">Created</th>
                                                            <th className="col">Modified</th>
                                                            <th className="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.tableData.map((data,index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <th scope="row">{x++}</th>
                                                                    <td>{data.coupon_code}</td>
                                                                    <td className='text-left'>
                                                                        <span>₹{data.discount_value}</span><br />
                                                                    </td>
                                                                    <td className='text-left'>
                                                                        {data.user_id === 0 ? "--" : <Link to={"/single-customer-view/userDetails/" + data.customer_id} target="_blank">
                                                                            <span className='table-img'><img src={data.profile_pic} alt="" /></span><br />
                                                                            <span>[{data.customer_id}] {data.name}</span>
                                                                        </Link>}
                                                                    </td>
                                                                    <td className='text-left'>
                                                                        {data.baker_id === 0 ? "--" : <Link to={"/single-shop-view/shopDetails/" + data.baker_id} target="_blank">
                                                                            <span className='table-img'><img src={data.shop_image} alt="" /></span><br />
                                                                            <span>[{data.baker_id}] {data.shop_name}</span>
                                                                        </Link>}
                                                                    </td>
                                                                    <td>{data.coupon_type === "A" ? "On Category" : data.coupon_type === "B" ? "On Sub Category" : data.coupon_type === "C" ? "On Product" : "On All"}</td>
                                                                    <td>{data.user_limit}</td>
                                                                    <td>{data.max_limit}</td>
                                                                    <td>{data.use_count}</td>
                                                                    <td>{data.campaign}</td>
                                                                    <td>{data.description}</td>
                                                                    <td><Switch onChange={() => this.showStatusUpdateModal(data)} checked={data.status === 0 ? false : data.status === 1 ? true : false} /></td>
                                                                    <td>[{dateTimeConverter(data.start_time)}] - [{dateTimeConverter(data.end_time)}]</td>
                                                                    <td>[{dateTimeConverter(data.create_date)}] <br /> {data.created_by === data.admin_id ? data.full_name : ""}</td>
                                                                    <td>[{dateTimeConverter(data.modified_date)}]<br /> {data.modified_by === data.admin_id ? data.full_name : ""}</td>
                                                                    <td>
                                                                        <button className='btn-custom action-btn-1 m-1' onClick={() => this.showEditCouponDetailsModal(data)}>Edit</button>
                                                                        <Link className='btn-custom action-btn-2 m-1' to={"/used-coupon/" + data.coupon_id + "/" + data.coupon_code}>Details</Link>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                                :
                                                <div className="text-center">
                                                    <h4 className="text-danger">No Data Found!</h4>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='me-2' style={{ float: 'right', display: this.state.tableData.length > 0 ? 'unset' : 'none' }}>
                                <Pagination
                                    activePage={parseInt(this.state.pagination.active_page)}
                                    itemsCountPerPage={this.state.pagination.per_page}
                                    totalItemsCount={this.state.pagination.total_items}
                                    pageRangeDisplayed={5}
                                    onChange={this.handlePageChange.bind(this)}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <ConfirmationModal
                    show={this.state.statusUpdateModalShow}
                    headerText="Update Status"
                    bodyText="Are You Sure Want To Update Status?"
                    closeFunction={() => this.hideStatusUpdateModal()}
                    okFunction={() => this.updateCouponStatus(this.couponId, this.status)}
                />

                <Modal show={this.state.addCouponModalShow} onHide={() => this.hideAddCouponModal()}>
                    <Modal.Header closeButton style={{ display: "none" }}>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: "10px 14px", maxHeight: "85vh", overflow: "auto" }}>
                        <div className="text-center"><h3 style={{ borderBottom: "4px solid teal" }}>Add Coupon</h3></div>
                        <form>
                            <div className="mb-2">
                                <label htmlFor="coupon-code">Coupon Code</label>
                                <div className="input-group input-group-sm">
                                    <input id="coupon-code" onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()} required type="text" className="form-control form-control-sm" />
                                </div>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="coupon-type">Coupon Type</label>
                                <div className="input-group input-group-sm">
                                    <select id="coupon-type" className='form-control form-control-sm' onChange={(e) => {
                                        this.setState({ couponTypeDropdownOnchange: e.target.value });
                                        document.getElementById("category-dropdown").value = "";
                                        document.getElementById("sub-category-dropdown").value = "";
                                        document.getElementById("product-id").value = "";
                                        if (e.target.value === "A") {
                                            this.getStaticProductCategoryList();
                                        } if (e.target.value === "B") {
                                            this.getStaticProductSubCategoryList();
                                        }
                                    }}>
                                        <option value="D">All</option>
                                        <option value="A">On Category</option>
                                        <option value="B">On Sub Category</option>
                                        <option value="C">On Product</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mb-2" style={{ display: this.state.couponTypeDropdownOnchange === "A" ? 'unset' : 'none' }}>
                                <label htmlFor="category-dropdown">Select Category</label>
                                <div className="input-group input-group-sm">
                                    <select id="category-dropdown" className='form-control form-control-sm' defaultValue={""} onChange={(e) => {
                                        document.getElementById("category-dropdown").value = e.target.value;
                                    }}>
                                        <option value="" disabled>--Select Category--</option>
                                        {this.state.staticCategoryList.map((data,index) => {
                                            return (
                                                <option key={index} value={data.product_category_id}>{data.product_category_name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="mb-2" style={{ display: this.state.couponTypeDropdownOnchange === "B" ? 'unset' : 'none' }}>
                                <label htmlFor="sub-category-dropdown">Select Sub Category</label>
                                <div className="input-group input-group-sm">
                                    <select id="sub-category-dropdown" className='form-control form-control-sm' defaultValue={""} onChange={(e) => {
                                        document.getElementById("sub-category-dropdown").value = e.target.value;
                                    }}>
                                        <option value="" disabled>--Select Sub-Category--</option>
                                        {this.state.staticSubCategoryList.map((data,index) => {
                                            return (
                                                <option key={index} value={data.sub_category_id}>{data.sub_category_name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="mb-2" style={{ display: this.state.couponTypeDropdownOnchange === "C" ? 'unset' : 'none' }}>
                                <label htmlFor="product-id">Enter Product ID</label>
                                <div className="input-group input-group-sm">
                                    <input id="product-id" type="number" className="form-control form-control-sm" />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <label htmlFor="user-limit">User Limit</label>
                                    <div className="input-group input-group-sm">
                                        <input id="user-limit" required type="number" className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="col">
                                    <label htmlFor="max-limit">Max Limit</label>
                                    <div className="input-group input-group-sm">
                                        <input id="max-limit" type="number" className="form-control form-control-sm" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <label htmlFor="coupon-discount-type">Discount Type</label>
                                    <select className="form-control form-control-sm" defaultValue={""} id="coupon-discount-type">
                                        <option value="">----Select One----</option>
                                        <option value="0">In Percentage(%)</option>
                                        <option value="1">In Value</option>
                                    </select>
                                </div>
                                <div className="col">
                                    <label htmlFor="coupon-discount">Discount Value</label>
                                    <div className="input-group input-group-sm">
                                        <input id="coupon-discount" type="number" className="form-control form-control-sm" />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="coupon-campaign">Campaign</label>
                                <div className="input-group input-group-sm">
                                    <input id="coupon-campaign" type="text" className="form-control form-control-sm" />
                                </div>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="coupon-description">Description</label>
                                <div className="input-group input-group-sm">
                                    <textarea className='form-control form-control-sm' id="coupon-description" rows="3"></textarea>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <label htmlFor="coupon-start-date">Start Date</label>
                                    <input type="datetime-local" id='coupon-start-date' className='form-control form-control-sm' />
                                </div>
                                <div className="col">
                                    <label htmlFor="coupon-end-date">End Date</label>
                                    <input type="datetime-local" id='coupon-end-date' className='form-control form-control-sm' />
                                </div>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="select-customer-type">Select Customer</label>
                                <div className="input-group input-group-sm">
                                    <select id="select-customer-type" defaultValue="ALL" className='form-control form-control-sm' onChange={(e) => {
                                        this.setState({ selectCustomer: e.target.value });
                                    }}>
                                        <option value="ALL">All Customer</option>
                                        <option value="SINGLE">Single Customer</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mb-2" style={{ display: this.state.selectCustomer === "SINGLE" ? 'unset' : 'none' }}>
                                <label htmlFor="customer-id">Enter Customer ID</label>
                                <div className="input-group input-group-sm">
                                    <input id="customer-id" type="number" className="form-control form-control-sm" />
                                </div>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="select-baker-type">Select Baker</label>
                                <div className="input-group input-group-sm">
                                    <select id="select-baker-type" defaultValue="ALL" className='form-control form-control-sm' onChange={(e) => {
                                        this.setState({ selectBaker: e.target.value });
                                    }}>
                                        <option value="ALL">All Baker</option>
                                        <option value="SINGLE">Single Baker</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mb-2" style={{ display: this.state.selectBaker === "SINGLE" ? 'unset' : 'none' }}>
                                <label htmlFor="baker-id">Enter Baker ID</label>
                                <div className="input-group input-group-sm">
                                    <input id="baker-id" type="number" className="form-control form-control-sm" />
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='btn-custom action-btn-3' onClick={() => this.hideAddCouponModal()}>
                            Close
                        </Button>
                        <Button className='btn-custom action-btn-1' onClick={() => this.addCoupon()}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.editCouponDetailsModalShow} onHide={() => this.hideEditCouponDetailsModal()}>
                    <Modal.Header closeButton style={{ display: "none" }}>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: "10px 14px", maxHeight: "85vh", overflow: "auto" }}>
                        <div className="text-center"><h3 style={{ borderBottom: "4px solid teal" }}>Edit Coupon Details</h3></div>
                        <form>
                            <input type="text" className='d-none' id='coupon-id-edit' />
                            <div className="row mb-2">
                                <div className="col">
                                    <label htmlFor="user-limit-edit">User Limit</label>
                                    <div className="input-group input-group-sm">
                                        <input id="user-limit-edit" required type="number" className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="col">
                                    <label htmlFor="max-limit-edit">Max Limit</label>
                                    <div className="input-group input-group-sm">
                                        <input id="max-limit-edit" type="number" className="form-control form-control-sm" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <label htmlFor="coupon-start-date-edit">Start Date</label>
                                    <input type="datetime-local" id='coupon-start-date-edit' className='form-control form-control-sm' />
                                </div>
                                <div className="col">
                                    <label htmlFor="coupon-end-date-edit">End Date</label>
                                    <input type="datetime-local" id='coupon-end-date-edit' className='form-control form-control-sm' />
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='btn-custom action-btn-3' onClick={() => this.hideEditCouponDetailsModal()}>
                            Close
                        </Button>
                        <Button className='btn-custom action-btn-1' onClick={() => this.updateCouponDetails()}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}
