const { API } = require("./static.data.pnp.js");

module.exports = {
    login: API + "login/admin-login",

    //dashboard
    topBakerByCart: API + "dashboard/top-baker-by-cart",
    topCategorySell: API + "dashboard/top-category-sell",
    allProductCount: API + "dashboard/all-product-count",
    allCustomerCount: API + "dashboard/all-customer-count",
    allBakerCount: API + "dashboard/all-baker-count",
    allShopCount: API + "dashboard/all-shop-count",
    allAddonCount: API + "dashboard/all-addon-count",
    allDecorCount: API + "dashboard/all-decor-count",
    allCartCount: API + "dashboard/all-cart-count",
    allCouponCount: API + "dashboard/all-coupon-count",
    allFeedbackCount: API + "dashboard/all-feedback-count",
    allOrderCount: API + "dashboard/all-order-count",
    topCustomer: API + "dashboard/top-customer",
    topSellingProducts : API + "dashboard/top-selling-product",
    topRatedProducts : API + "dashboard/top-rated-product",
    topRatedShop : API + "dashboard/top-rated-shop",
    topDeliveredShop : API + "dashboard/top-delivered-shop",

    //customer
    allCustomer: API + "customer/all-customer/",
    customerStatusUpdate: API + "customer/update-customer-status/",
    customerDetailsByCustomerID: API + "customer/customer-details-by-customer-id/",
    customerPicUpload: API + "customer/customer-pic-upload",
    customerUpdate: API + "customer/update-customer",
    cartListByCustomerID: API + "customer/cart-list-by-customer/",
    orderListByCustomerID: API + "customer/order-list-by-customer/",

    customerAddressList: API + "customer/address-list/",
    customerDefaultAddressUpdate: API + "customer/update-default-address/",
    customerAddressUpdate: API + "customer/address-update",
    customerAddressDelete: API + "customer/address-delete",

    //bakers
    allBaker: API + "bakers/all-baker/",

    //shops
    allShopList: API + "shop/all-shop/",
    verifyShop: API + "shop/update-shop-status/",
    shopDetailsByBakerID: API + "shop/shop-details-by-baker-id/",
    shopPicUpload: API + "shop/shop-pic-upload",
    shopDetailsUpdate: API + "shop/update-shop-details",
    getFssaiDetails: API + "shop/fssai-data-fetch",

    productCountByBaker: API + "shop/product-count",
    addonCountByBaker: API + "shop/addon-count",
    decorCountByBaker: API + "shop/decor-count",
    pendingOrderCountByBaker: API + "shop/pending-order-count",
    onGoingOrderCountByBaker: API + "shop/ongoing-order-count",
    deliveredOrderCountByBaker: API + "shop/delivered-order-count",

    //product
    allProductList: API + "product/all-product/",
    productListByBaker: API + "product/product-by-baker/",
    productDetailsByProductId: API + "product/product-details-by-product-id/",
    productActive: API + "product/active/",
    productInactive: API + "product/inactive/",
    productUpdate: API + "product/update-product",
    productPicUpload: API + "product/product-img-upload",

    //addon-decors
    addonDetailsByAddonId: API + "addon-decor/addon-details-by-addon-id/",
    addonListByBaker: API + "addon-decor/addon-list-by-baker/",
    decorListByBaker: API + "addon-decor/decor-list-by-baker/",
    decorDetailsByDecorId: API + "addon-decor/decor-details-by-decor-id/",

    // orders
    allOrders: API + "order/all-orders/",
    ordersByBaker: API + "order/order-list-by-baker/",
    orderStatusUpdate: API + "order/order-status-update",

    //coupons
    createCoupon: API + "coupon/create-coupon",
    allCoupon: API + "coupon/all-coupon/",
    couponStatusUpdate: API + "coupon/coupon-status-update",
    couponDetailsUpdate: API + "coupon/coupon-details-update",
    usedCouponList: API + "coupon/used-coupon-list/",

    //cart
    allCart: API + "cart/all-cart/",
    cartStatusUpdate: API + "cart/status-update",
    cartAddressUpdate: API + "cart/address-update",
    cartUpdate: API + "cart/update",

    //wishlist
    allWishlistShop: API + "wishlist/all-wishlist-shop/",
    allWishlistProduct: API + "wishlist/all-wishlist-product/",
    removeWishlistProduct: API + "wishlist/remove-wishlist-product",
    removeWishlistShop: API + "wishlist/remove-wishlist-shop",

    //feedback
    allFeedback: API + "feedback/all-feedback/",
    updateFeedback: API + "feedback/feedback-update/",

    //data
    productCategoryList: API + "data/product-category",
    unitList: API + "data/unit-list",

    //product category
    productCategoryListWithPagination: API + "category/product-category/",
    addProductCategory: API + "category/add-product-category",
    updateProductCategory: API + "category/update-product-category",
    uploadProductCategoryIcon: API + "category/product-category-icon-upload",

    //sub category 
    productSubCategoryList: API + "category/product-sub-category/",
    allProductSubCategoryList: API + "category/all-product-sub-category",
    addProductSubCategory: API + "category/add-product-sub-category",
    updateProductSubCategory: API + "category/update-product-sub-category",
    subCategoryDetailsByCategoryId: API + "category/sub-category-details-by-product-category-id/",
    subCategoryDetailsBySubCategoryId: API + "category/sub-category-details-by-sub-category-id/",

    //addon category
    addonCategoryList: API + "category/add-on-category/",
    addAddonCategory: API + "category/add-add-on-category",
    updateAddonCategory: API + "category/update-add-on-category",

    //decor category
    decorCategoryList: API + "category/decor-category/",
    addDecorCategory: API + "category/add-decor-category",
    updateDecorCategory: API + "category/update-decor-category",

    //quantity category
    quantityCategoryList: API + "category/quantity-category/",
    addQuantityCategory: API + "category/add-quantity-category",
    updateQuantityCategory: API + "category/update-quantity-category",

    //notification
    sendNotification: API + "notification/send",
    notificationPicUpload: API + "notification/pic-upload",

    //settings
    fetchBakerSettings: API + "setting/baker-setting/",
    updateBakerSettings: API + "setting/baker-setting-update",

    fetchCustomerSettings: API + "setting/customer-setting/",
    updateCustomerSettings: API + "setting/customer-setting-update",

    popImageUpload: API + "setting/popup-image-upload",


}