import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import SidebarMenu from '../components/SidebarMenu'
import userImage from "../assets/images/profilePic.jpg"
import Switch from "react-switch";
import { Button, Modal } from 'react-bootstrap';
import { TbCalendar, TbCaretDown, TbNote, TbSearch, TbSquareDot } from 'react-icons/tb';
import { FaWeightHanging } from 'react-icons/fa'
import AddressComponent from '../components/AddressComponent';
import { toast, ToastContainer } from 'react-toastify';
import { getApi, postApi } from '../api/call.api';
import urlApi from '../api/url.api';
import Pagination from 'react-js-pagination';
import { dateTimeConverter } from '../utils/dateTimeConverter';
import { dateRemainingConverter } from '../utils/dateRemainingConverter';
import ConfirmationModal from '../components/ConfirmationModal';
import { BsArrowLeftRight } from 'react-icons/bs';
import { GrPowerReset } from 'react-icons/gr';
import ProductDetailsModalComponent from '../components/ProductDetailsModalComponent';

export default class AllCartView extends Component {
    constructor(p) {
        super(p);
        this.state = {
            isOpen: false,
            editModalShow: false,
            addressModalShow: false,
            productDetailsModalShow: false,
            tableData: [],
            singleProductDetails: [],
            pagination: ({
                active_page: 0,
                per_page: 10,
                total_items: 0
            }),
            page: 1,
            count: 10,
            statusUpdateModalShow: false,
            addressList: []
        }
        this.updateCartStatus = this.updateCartStatus.bind(this);
        this.allAddonDecorList = [];
    }

    showStatusUpdateModal(cartID, status) {
        this.cartID = cartID;
        this.status = status;
        this.setState({ statusUpdateModalShow: true });
    }

    hideStatusUpdateModal() {
        this.setState({ statusUpdateModalShow: false })
    }

    async updateCartStatus(cartID, status) {
        let postData = {
            cart_id: cartID,
            status: status
        }
        let resp = await postApi(postData, urlApi.cartStatusUpdate);
        this.hideStatusUpdateModal();
        if (resp.responsecode === "200") {
            this.alert(resp.message, resp.responsecode);
            this.getAllCartList();
        }
        else {
            this.alert(resp.message, resp.responsecode);
        }
    }

    async getAddressList() {
        let resp = await getApi(urlApi.customerAddressList + this.customer_id);
        if (resp.responsecode === "200") {
            this.setState({ addressList: resp.data });
        }
    }

    async changeCartAddress(addressID) {
        let postData = {
            "cart_id": this.selectedCartId,
            "address_id": addressID
        }
        let resp = await postApi(postData, urlApi.cartAddressUpdate);
        if (resp.responsecode === "200") {
            this.selectedAddressOfCart = addressID;
            this.getAddressList();
            this.getAllCartList();
            this.addressViewModalHide();
            this.alert(resp.message, "200");
        }
        else {
            this.alert(resp.message, "500");
        }
    }

    menuBarClick() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    async editViewModalShow(data) {
        setTimeout(() => {
            document.getElementById("cart-id").value = data.cart_id;
            document.getElementById("customer-id").value = data.customer_id;
            document.getElementById("cart-edit-preference").value = data.preference;
            document.getElementById("cart-edit-quantity").value = data.quantity;
            document.getElementById("cart-delivery-date").value = data.min_delivery_day.split(" ")[0];
            document.getElementById("cart-customer-note").value = data.customer_note;
        }, 100);
        await this.setState({ editModalShow: true });
    }

    editViewModalHide() {
        this.setState({ editModalShow: false })
    }

    async updateCart() {
        let postData = {
            "cart_id": document.getElementById("cart-id").value,
            "customer_id": document.getElementById("customer-id").value,
            "preference": document.getElementById("cart-edit-preference").value,
            "quantity": document.getElementById("cart-edit-quantity").value,
            "delivery_date": document.getElementById("cart-delivery-date").value,
            "customer_note": document.getElementById("cart-customer-note").value
        }
        let resp = await postApi(postData, urlApi.cartUpdate);
        this.editViewModalHide();
        if (resp.responsecode === "200") {
            this.alert(resp.message, "200");
            this.getAllCartList();
        }
        else {
            this.alert(resp.message, "500")
        }
    }

    async productDetailsViewModalShow(data) {
        let addons = JSON.parse(data.cart_add_on_ids);
        let decors = JSON.parse(data.cart_decor_ids);
        let addonTempList = [];
        let decorTempList = [];
        this.allAddonDecorList = [];
        for (let i = 0; i < addons.length; i++) {
            let respAddon = await getApi(urlApi.addonDetailsByAddonId + addons[i]);
            if (respAddon.responsecode === "200") {
                addonTempList.push(respAddon.data);
            }
        }
        for (let j = 0; j < decors.length; j++) {
            let respDecor = await getApi(urlApi.decorDetailsByDecorId + decors[j]);
            if (respDecor.responsecode === "200") {
                decorTempList.push(respDecor.data);
            }
        }
        this.allAddonDecorList = [...this.allAddonDecorList, ...[...addonTempList], ...[...decorTempList]];
        this.setState({ productDetailsModalShow: true }, async () => {
            let resp = await getApi(urlApi.productDetailsByProductId + data.product_id);
            if (resp.responsecode === "200") {
                this.setState({ singleProductDetails: resp.data })
            }
        });
    }

    productDetailsViewModalHide() {
        this.setState({ productDetailsModalShow: false });
    }

    addressViewModalShow(data) {
        setTimeout(() => {
            this.selectedAddressOfCart = data.address_id;
            this.customer_id = data.customer_id;
            this.getAddressList();
        }, 100);
        this.selectedCartId = data.cart_id;
        this.setState({ addressModalShow: true })
    }

    addressViewModalHide() {
        this.setState({ addressModalShow: false })
    }

    componentDidMount() {
        this.getAllCartList();
    }

    async handlePageChange(pageNumber) {
        await this.setState({ page: pageNumber });
        this.getAllCartList();
    }

    async resetTable() {
        await this.setState({ tableData: [] })
        await this.setState({
            pagination: ({
                active_page: 0,
                per_page: 10,
                total_items: 0
            })
        })
    }

    alert = (msg, responseCode) => {
        if (responseCode === "200") {
            toast.success(msg);
        }
        else if (responseCode === "500") {
            toast.error(msg);
        }
        else {
            toast.info(msg);
        }
    }

    async resetFields() {
        document.getElementById("from-date-filter").value = "";
        document.getElementById("to-date-filter").value = "";
        document.getElementById("search-field").value = "";
        document.getElementById("status-dropdown").value = "";
        this.getAllCartList({ dateFilter: "" });
    }

    dateFilterVar = "";
    async getAllCartList({ dateFilter = this.dateFilterVar } = {}) {
        this.dateFilterVar = dateFilter;
        this.setState({ page: 1 });
        let postData = {
            "search_data": document.getElementById("search-field").value,
            "date": dateFilter,
            "fromDate": document.getElementById("from-date-filter").value,
            "toDate": document.getElementById("to-date-filter").value
        }
        let resp = await postApi(postData, urlApi.allCart + this.state.page);
        if (resp.responsecode === "200") {
            this.setState({ tableData: resp.data.data });
            this.setState({ pagination: resp.data.pagination });
        }
        else {
            this.resetTable();
        }
    }

    async onSearch() {
        await this.setState({ page: 1 }, () => this.getAllCartList());
    }

    render() {
        var x = (this.state.pagination.active_page - 1) * this.state.pagination.per_page + 1;
        return (
            <>
                <div className="container-fluid position-relative bg-white d-flex p-0">
                    <SidebarMenu isOpen={this.state.isOpen} />
                    <div className={this.state.isOpen ? "content open" : "content"}>
                        <Navbar menuBarClick={() => this.menuBarClick()} />
                        <div className="container-fluid">
                            <ToastContainer autoClose={1000} />
                            <div className="row g-4">
                                <div className="col-12 px-0">
                                    <div className="rounded h-100 p-2">
                                        <h6 className="mb-4">Cart Details || Total : {this.state.pagination.total_items}</h6>
                                        <div className="row mb-2 gx-2">
                                            <div className="col-md-1 mb-1">
                                                <button className='btn-custom action-btn-5 w-100' onClick={() => this.getAllCartList({ dateFilter: "" })}>All</button>
                                            </div>
                                            <div className="col-md-1 mb-1">
                                                <button className='btn-custom action-btn-1 w-100' onClick={() => this.getAllCartList({ dateFilter: "DAILY" })}>Today</button>
                                            </div>
                                            <div className="col-md-1 mb-1">
                                                <button className='btn-custom action-btn-4 w-100' onClick={() => this.getAllCartList({ dateFilter: "YESTERDAY" })}>Yesterday</button>
                                            </div>
                                            <div className="col-md-1 mb-1">
                                                <button className='btn-custom action-btn-3 w-100' onClick={() => this.getAllCartList({ dateFilter: "WEEKLY" })}>Last Week</button>
                                            </div>
                                            <div className="col-md-1 mb-1">
                                                <button className='btn-custom action-btn-2 w-100' onClick={() => this.getAllCartList({ dateFilter: "MONTHLY" })}>Last Month</button>
                                            </div>
                                        </div>
                                        <div className="row gx-2 mb-2">
                                            <div className="col-md-2">
                                                <input type="date" className='form-control form-control-sm w-100' id='from-date-filter' />
                                            </div>
                                            <div className="col-md-1 text-center"> <BsArrowLeftRight /> </div>
                                            <div className="col-md-2">
                                                <input type="date" className='form-control form-control-sm w-100' id='to-date-filter' />
                                            </div>
                                            <div className="col-md-1">
                                                <button className='btn-custom action-btn-1 w-100' onClick={() => this.getAllCartList({ dateFilter: "CUSTOM" })}>Search</button>
                                            </div>
                                            <div className="col-md-2 col-2">
                                                <button className='btn-custom action-btn-5 w-sm-100' onClick={() => this.resetFields()}><GrPowerReset size={20} /></button>
                                            </div>
                                        </div>
                                        <form onSubmit={(e) => { e.preventDefault(); this.onSearch() }}>
                                            <div className="col-12 mb-2">
                                                <div className="row gx-1 search-section">
                                                    <div className="col-md-3 col-6">
                                                        <input className='form-control form-control-sm w-100' id='search-field' placeholder='Username / Shop Name' />
                                                    </div>
                                                    <div className="col-md-2 col-2">
                                                        <button className='btn-custom action-btn-5 w-sm-100' type='submit'><TbSearch size={20} /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        {this.state.tableData.length > 0 ? <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th className="col">#</th>
                                                        <th className="col">Customer Name</th>
                                                        <th className="col">Shop Name</th>
                                                        <th className="col">Product Name</th>
                                                        <th className="col">Address</th>
                                                        <th className="col">Quantity</th>
                                                        <th className="col">Customer Note</th>
                                                        <th className="col">Delivery</th>
                                                        <th className="col">Status</th>
                                                        <th className="col">Created Date</th>
                                                        <th className="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.tableData.map((data, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <th scope="row">{x++}</th>
                                                                <td className='text-left'>
                                                                    <Link to={"single-customer-view/userDetails/" + data.customer_id} target={"_blank"}>
                                                                        <span className='table-img'><img src={userImage} alt="" /></span><br />
                                                                        <span>[{data.customer_id}] {data.customer_name}</span>
                                                                    </Link>
                                                                </td>
                                                                <td className='text-left'>
                                                                    <Link to={"single-shop-view/shopDetails/" + data.baker_id} target={"_blank"}>
                                                                        <span className='table-img'><img src={data.shop_image} alt="" /></span><br />
                                                                        <span>[{data.baker_id}] {data.shop_name}</span>
                                                                    </Link>
                                                                </td>
                                                                <td className='text-left'>
                                                                    <span className='table-img'><img src={data.picture} alt="" /></span><br />
                                                                    <span>
                                                                        {data.preference !== 3 ?
                                                                            <TbSquareDot color={data.preference === 2 ? 'red' : data.preference === 1 ? 'green' : 'grey'} /> :
                                                                            <span><TbSquareDot color='red' />
                                                                                <TbSquareDot color='green' />
                                                                            </span>}
                                                                        {data.name}
                                                                    </span>
                                                                </td>
                                                                <td>{data.address_details},{data.pincode}</td>
                                                                <td>{data.quantity}</td>
                                                                <td>{data.customer_note ?? "--"}</td>
                                                                <td>{dateRemainingConverter(data.min_delivery_day, data.create_date)}</td>
                                                                <td>
                                                                    <Switch onChange={() => this.showStatusUpdateModal(data.cart_id, data.status === 1 ? 0 : 1)} checked={data.status === 1 ? true : false} />
                                                                </td>
                                                                <td>{dateTimeConverter(data.create_date)}</td>
                                                                <td>
                                                                    <button className='btn-custom action-btn-1 m-1' onClick={() => this.editViewModalShow(data)}>Edit</button>
                                                                    <button className='btn-custom action-btn-2 m-1' onClick={() => this.addressViewModalShow(data)}>Change Address</button>
                                                                    <button className='btn-custom action-btn-3 m-1' onClick={() => this.productDetailsViewModalShow(data)}>Product Details</button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div> : <div className="no-data text-center my-5">
                                            <h4 className='text-danger'>Sorry! No Cart Found!</h4>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            <div className='me-2' style={{ float: 'right', display: this.state.tableData.length > 0 ? 'unset' : 'none' }}>
                                <Pagination
                                    activePage={parseInt(this.state.pagination.active_page)}
                                    itemsCountPerPage={this.state.pagination.per_page}
                                    totalItemsCount={this.state.pagination.total_items}
                                    pageRangeDisplayed={5}
                                    onChange={this.handlePageChange.bind(this)}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            </div>
                        </div>
                    </div>

                    <Modal show={this.state.addressModalShow} onHide={() => this.addressViewModalHide()}>
                        <Modal.Header closeButton style={{ display: "none" }}>
                            <Modal.Title>Modal heading</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ padding: "10px 14px" }}>
                            <div className="text-center"><h3 style={{ borderBottom: "4px solid teal" }}>Address List</h3></div>
                            {this.state.addressList.map((data, index) => {
                                return (
                                    <AddressComponent
                                        key={index}
                                        btn2Function={() => this.changeCartAddress(data.address_id)}
                                        cursor="pointer"
                                        name={data.name}
                                        addressDetails={data.address_details}
                                        pinCode={data.pincode}
                                        mobile={data.mobile}
                                        landmark={data.landmark}
                                        btnText2={data.address_id === this.selectedAddressOfCart ? null : "Select"}
                                        isDefault={data.address_id === this.selectedAddressOfCart ? true : false}
                                    />
                                )
                            })}
                        </Modal.Body>
                        <Modal.Footer style={{ display: 'none' }}>
                            <Button className='action-btn-2' onClick={() => this.addressViewModalHide()}>
                                Close
                            </Button>
                            <Button className='action-btn-1' onClick={() => this.addressViewModalHide()}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.editModalShow} onHide={() => this.editViewModalHide()}>
                        <Modal.Header closeButton style={{ display: "none" }}>
                            <Modal.Title>Modal heading</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ padding: "10px 14px" }}>
                            <div className="text-center"><h3 style={{ borderBottom: "4px solid teal" }}>Edit Cart</h3></div>
                            <form>
                                <input id='cart-id' type="text" style={{ display: "none" }} />
                                <input id='customer-id' type="text" style={{ display: "none" }} />
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group mb-2">
                                            <label htmlFor="cart-edit-preference">Preference</label>
                                            <div className="input-group input-group-sm">
                                                <select className='form-control' id='cart-edit-preference'>
                                                    <option value="2">Veg</option>
                                                    <option value="1">Non-Veg</option>
                                                </select>
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span><TbCaretDown /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group mb-2">
                                            <label htmlFor="cart-edit-quantity">Quantity</label>
                                            <div className="input-group input-group-sm">
                                                <input className='form-control' id='cart-edit-quantity' />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span><FaWeightHanging /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-2">
                                    <label htmlFor="cart-delivery-date">Delivery Date</label>
                                    <div className="input-group input-group-sm">
                                        <input type="date" className='form-control' id='cart-delivery-date' />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span><TbCalendar /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-2">
                                    <label htmlFor="cart-customer-note">Customer Note</label>
                                    <div className="input-group input-group-sm">
                                        <input className='form-control' id="cart-customer-note" />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span><TbNote /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className='btn-custom action-btn-2' onClick={() => this.editViewModalHide()}>
                                Close
                            </Button>
                            <Button className='btn-custom action-btn-1' onClick={() => this.updateCart()}>
                                Update
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <ConfirmationModal
                        show={this.state.statusUpdateModalShow}
                        headerText="Update Status"
                        bodyText="Are You Sure Want To Remove?"
                        closeFunction={() => this.hideStatusUpdateModal()}
                        okFunction={() => this.updateCartStatus(this.cartID, this.status)}
                    />

                    <ProductDetailsModalComponent
                        show={this.state.productDetailsModalShow}
                        productDetailsViewModalHide={() => this.productDetailsViewModalHide()}
                        productData={this.state.singleProductDetails}
                        addonDecorData={this.allAddonDecorList}
                    />
                </div>
            </>
        )
    }
}
