import React from 'react'
import Navbar from '../components/Navbar';
import SidebarMenu from '../components/SidebarMenu'
import "../css/main.css";
import { postApi } from '../api/call.api'
import urlApi from '../api/url.api';
import TopBakerCard from '../components/dashboard/TopBakerCard';
import CountCard from '../components/dashboard/CountCard';
import { BsArrowLeftRight } from 'react-icons/bs';
import { GrPowerReset } from 'react-icons/gr';
import TopCustomerCard from '../components/dashboard/TopCustomerCard';
import TopSellingProductCard from '../components/dashboard/TopSellingProductCard';
import TopRatedProductCard from '../components/dashboard/TopRatedProductCard';
import TopRatedShop from '../components/dashboard/TopRatedShop';

export default class Dashboard extends React.Component {
  constructor(p) {
    super(p);
    this.state = {
      isOpen: false,
      topBakerCart: [],
      topCategorySell: [],
      topCustomer: [],
      productCount: "0",
      customerCount: "0",
      bakerCount: "0",
      shopCount: "0",
      addonCount: "0",
      decorCount: "0",
      cartCount: "0",
      couponCount: "0",
      feedbackCount: "0",
      orderCount: [],
      topSellingProduct: [],
      topRatedProduct: [],
      topRatedShop: [],
      topDeliveredShop: [],
    }
  }

  menuBarClick() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  async getTopBakerByCart({ dateFilter = "" } = {}) {
    this.setState({ topBakerCart: [] });
    let postData = {
      "date": dateFilter,
      "fromDate": document.getElementById("from-date-filter").value,
      "toDate": document.getElementById("to-date-filter").value
    }
    let resp = await postApi(postData, urlApi.topBakerByCart);
    if (resp.responsecode === "200") {
      this.setState({ topBakerCart: resp.data });
    }
  }

  colorArray = ["primary", "info", "warning", "danger", "success"];

  async getTopCategorySell({ dateFilter = "" } = {}) {
    this.setState({ topCategorySell: [] });
    let postData = {
      "date": dateFilter,
      "fromDate": document.getElementById("from-date-filter").value,
      "toDate": document.getElementById("to-date-filter").value
    }
    let resp = await postApi(postData, urlApi.topCategorySell);
    if (resp.responsecode === "200") {
      this.setState({ topCategorySell: resp.data });
    }
  }

  async getAllProductCount({ dateFilter = "" } = {}) {
    this.setState({ productCount: "0" });
    let postData = {
      "date": dateFilter,
      "fromDate": document.getElementById("from-date-filter").value,
      "toDate": document.getElementById("to-date-filter").value
    }
    let resp = await postApi(postData, urlApi.allProductCount);
    if (resp.responsecode === "200") {
      this.setState({ productCount: resp.data.total_products });
    }
  }

  async getAllCustomerCount({ dateFilter = "" } = {}) {
    this.setState({ customerCount: "0" });
    let postData = {
      "date": dateFilter,
      "fromDate": document.getElementById("from-date-filter").value,
      "toDate": document.getElementById("to-date-filter").value
    }
    let resp = await postApi(postData, urlApi.allCustomerCount);
    if (resp.responsecode === "200") {
      this.setState({ customerCount: resp.data.total_customer });
    }
  }

  async getAllBakerCount({ dateFilter = "" } = {}) {
    this.setState({ bakerCount: "0" });
    let postData = {
      "date": dateFilter,
      "fromDate": document.getElementById("from-date-filter").value,
      "toDate": document.getElementById("to-date-filter").value
    }
    let resp = await postApi(postData, urlApi.allBakerCount);
    if (resp.responsecode === "200") {
      this.setState({ bakerCount: resp.data.total_baker });
    }
  }

  async getAllShopCount({ dateFilter = "" } = {}) {
    this.setState({ shopCount: "0" });
    let postData = {
      "date": dateFilter,
      "fromDate": document.getElementById("from-date-filter").value,
      "toDate": document.getElementById("to-date-filter").value
    }
    let resp = await postApi(postData, urlApi.allShopCount);
    if (resp.responsecode === "200") {
      this.setState({ shopCount: resp.data.total_shop });
    }
  }

  async getAllAddonCount({ dateFilter = "" } = {}) {
    this.setState({ addonCount: "0" });
    let postData = {
      "date": dateFilter,
      "fromDate": document.getElementById("from-date-filter").value,
      "toDate": document.getElementById("to-date-filter").value
    }
    let resp = await postApi(postData, urlApi.allAddonCount);
    if (resp.responsecode === "200") {
      this.setState({ addonCount: resp.data.total_addon });
    }
  }

  async getAllDecorCount({ dateFilter = "" } = {}) {
    this.setState({ decorCount: "0" });
    let postData = {
      "date": dateFilter,
      "fromDate": document.getElementById("from-date-filter").value,
      "toDate": document.getElementById("to-date-filter").value
    }
    let resp = await postApi(postData, urlApi.allDecorCount);
    if (resp.responsecode === "200") {
      this.setState({ decorCount: resp.data.total_decor });
    }
  }

  async getAllCartCount({ dateFilter = "" } = {}) {
    this.setState({ cartCount: "0" });
    let postData = {
      "date": dateFilter,
      "fromDate": document.getElementById("from-date-filter").value,
      "toDate": document.getElementById("to-date-filter").value
    }
    let resp = await postApi(postData, urlApi.allCartCount);
    if (resp.responsecode === "200") {
      this.setState({ cartCount: resp.data.total_cart });
    }
  }

  async getAllCouponCount({ dateFilter = "" } = {}) {
    this.setState({ couponCount: "0" });
    let postData = {
      "date": dateFilter,
      "fromDate": document.getElementById("from-date-filter").value,
      "toDate": document.getElementById("to-date-filter").value
    }
    let resp = await postApi(postData, urlApi.allCouponCount);
    if (resp.responsecode === "200") {
      this.setState({ couponCount: resp.data.total_coupon });
    }
  }

  async getAllFeedbackCount({ dateFilter = "" } = {}) {
    this.setState({ feedbackCount: "0" });
    let postData = {
      "date": dateFilter,
      "fromDate": document.getElementById("from-date-filter").value,
      "toDate": document.getElementById("to-date-filter").value
    }
    let resp = await postApi(postData, urlApi.allFeedbackCount);
    if (resp.responsecode === "200") {
      this.setState({ feedbackCount: resp.data.total_feedback });
    }
  }

  async getAllOrderCount({ dateFilter = "" } = {}) {
    this.setState({ orderCount: [] });
    let postData = {
      "date": dateFilter,
      "fromDate": document.getElementById("from-date-filter").value,
      "toDate": document.getElementById("to-date-filter").value
    }
    let resp = await postApi(postData, urlApi.allOrderCount);
    if (resp.responsecode === "200") {
      this.setState({ orderCount: resp.data[0] });
    }
  }

  async getTopCustomer({ dateFilter = "" } = {}) {
    this.setState({ topCustomer: [] });
    let postData = {
      "date": dateFilter,
      "fromDate": document.getElementById("from-date-filter").value,
      "toDate": document.getElementById("to-date-filter").value
    }
    let resp = await postApi(postData, urlApi.topCustomer);
    if (resp.responsecode === "200") {
      this.setState({ topCustomer: resp.data });
    }
  }

  async getTopSellingProduct({ dateFilter = "" } = {}) {
    this.setState({ topSellingProduct: [] });
    let postData = {
      "date": dateFilter,
      "fromDate": document.getElementById("from-date-filter").value,
      "toDate": document.getElementById("to-date-filter").value
    }
    let resp = await postApi(postData, urlApi.topSellingProducts);
    if (resp.responsecode === "200") {
      this.setState({ topSellingProduct: resp.data });
    }
  }

  async getTopRatedProduct({ dateFilter = "" } = {}) {
    this.setState({ topRatedProduct: [] });
    let postData = {
      "date": dateFilter,
      "fromDate": document.getElementById("from-date-filter").value,
      "toDate": document.getElementById("to-date-filter").value
    }
    let resp = await postApi(postData, urlApi.topRatedProducts);
    if (resp.responsecode === "200") {
      this.setState({ topRatedProduct: resp.data });
    }
  }

  async getTopRatedShop({ dateFilter = "" } = {}) {
    this.setState({ topRatedShop: [] });
    let postData = {
      "date": dateFilter,
      "fromDate": document.getElementById("from-date-filter").value,
      "toDate": document.getElementById("to-date-filter").value
    }
    let resp = await postApi(postData, urlApi.topRatedShop);
    if (resp.responsecode === "200") {
      this.setState({ topRatedShop: resp.data });
    }
  }

  async getTopDeliveredShop({ dateFilter = "" } = {}) {
    this.setState({ topDeliveredShop: [] });
    let postData = {
      "date": dateFilter,
      "fromDate": document.getElementById("from-date-filter").value,
      "toDate": document.getElementById("to-date-filter").value
    }
    let resp = await postApi(postData, urlApi.topDeliveredShop);
    console.log(resp);
    if (resp.responsecode === "200") {
      this.setState({ topDeliveredShop: resp.data });
    }
  }

  componentDidMount() {
    this.getAllProductCount({ dateFilter: "DAILY" });
    this.getAllCustomerCount({ dateFilter: "DAILY" });
    this.getAllBakerCount({ dateFilter: "DAILY" });
    this.getAllShopCount({ dateFilter: "DAILY" });
    this.getAllAddonCount({ dateFilter: "DAILY" });
    this.getAllDecorCount({ dateFilter: "DAILY" });
    this.getAllCartCount({ dateFilter: "DAILY" });
    this.getAllCouponCount({ dateFilter: "DAILY" });
    this.getAllFeedbackCount({ dateFilter: "DAILY" });
    this.getTopBakerByCart({ dateFilter: "DAILY" });
    this.getTopDeliveredShop({ dateFilter: "DAILY" });
    this.getTopCategorySell({ dateFilter: "DAILY" });
    this.getAllOrderCount({ dateFilter: "DAILY" });
    this.getTopCustomer({ dateFilter: "DAILY" });
    this.getTopSellingProduct({ dateFilter: "DAILY" });
    this.getTopRatedProduct({ dateFilter: "DAILY" });
    this.getTopRatedShop({ dateFilter: "DAILY" });
  }

  allFunction({ dateFilter = "" } = {}) {
    this.getAllProductCount({ dateFilter: dateFilter });
    this.getAllCustomerCount({ dateFilter: dateFilter });
    this.getAllBakerCount({ dateFilter: dateFilter });
    this.getAllShopCount({ dateFilter: dateFilter });
    this.getAllAddonCount({ dateFilter: dateFilter });
    this.getAllDecorCount({ dateFilter: dateFilter });
    this.getAllCartCount({ dateFilter: dateFilter });
    this.getAllCouponCount({ dateFilter: dateFilter });
    this.getAllFeedbackCount({ dateFilter: dateFilter });
    this.getTopBakerByCart({ dateFilter: dateFilter });
    this.getTopDeliveredShop({ dateFilter: dateFilter });
    this.getTopCategorySell({ dateFilter: dateFilter });
    this.getAllOrderCount({ dateFilter: dateFilter });
    this.getTopCustomer({ dateFilter: dateFilter });
    this.getTopSellingProduct({ dateFilter: dateFilter });
    this.getTopRatedProduct({ dateFilter: dateFilter });
    this.getTopRatedShop({ dateFilter: dateFilter });
  }

  render() {
    return (
      <>
        <div className="container-fluid position-relative bg-white d-flex p-0">
          <SidebarMenu isOpen={this.state.isOpen} />
          <div className={this.state.isOpen ? "content open" : "content"}>
            <Navbar menuBarClick={() => this.menuBarClick()} />
            <div className="container-fluid px-4">
              <div className="row mb-2 gx-2">
                <div className="col-md-1 col mb-1">
                  <button className='btn-custom action-btn-5 w-100' onClick={() => this.allFunction({ dateFilter: "" })}>All</button>
                </div>
                <div className="col-md-1 col mb-1">
                  <button className='btn-custom action-btn-1 w-100' onClick={() => this.allFunction({ dateFilter: "DAILY" })}>Today</button>
                </div>
                <div className="col-md-1 col mb-1">
                  <button className='btn-custom action-btn-4 w-100' onClick={() => this.allFunction({ dateFilter: "YESTERDAY" })}>Yesterday</button>
                </div>
                <div className="col-md-1 col mb-1">
                  <button className='btn-custom action-btn-3 w-100 text-nowrap' onClick={() => this.allFunction({ dateFilter: "WEEKLY" })}>Last Week</button>
                </div>
                <div className="col-md-1 col mb-1">
                  <button className='btn-custom action-btn-2 w-100 text-nowrap' onClick={() => this.allFunction({ dateFilter: "MONTHLY" })}>Last Month</button>
                </div>
              </div>
              <div className="row gx-2 mb-2">
                <div className="col-md-2 col-5 mb-2">
                  <input type="date" className='form-control form-control-sm w-100' id='from-date-filter' />
                </div>
                <div className="col-md-1 mb-2 col text-center"> <BsArrowLeftRight /> </div>
                <div className="col-md-2 col-5 mb-2">
                  <input type="date" className='form-control form-control-sm w-100' id='to-date-filter' />
                </div>
                <div className="col-md-1 col">
                  <button className='btn-custom action-btn-1 w-100' onClick={() => this.allFunction({ dateFilter: "CUSTOM" })}>Search</button>
                </div>
                <div className="col-md-2 col-2">
                  <button className='btn-custom action-btn-5 w-sm-100' onClick={() => this.resetFields()}><GrPowerReset size={20} /></button>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3 col-6">
                  <CountCard title="Total Customer" countData={this.state.customerCount} icon="fa-user" bgColor="#264653" />
                </div>
                <div className="col-md-2 col-6">
                  <CountCard title="Total Baker" countData={this.state.bakerCount} icon="fa-user-circle" bgColor="#2a9d8f" />
                </div>
                <div className="col-md-2 col-6">
                  <CountCard title="Total Shop" countData={this.state.shopCount} icon="fa-store" bgColor="#f4a261" />
                </div>
                <div className="col-md-2 col-6">
                  <CountCard title="Total Product" countData={this.state.productCount} icon="fa-archive" bgColor="#e76f51" />
                </div>
                <div className="col-md-3 col-6">
                  <CountCard title="Addon/Decor" countData={this.state.addonCount + "/" + this.state.decorCount} icon="fa-puzzle-piece" bgColor="#1d3557" />
                </div>
                <div className="col-md-4 col-6">
                  <CountCard title="Total Cart" countData={this.state.cartCount} icon="fa-shopping-cart" bgColor="#AA336A" />
                </div>
                <div className="col-md-4 col-6">
                  <CountCard title="Total Coupon" countData={this.state.couponCount} icon="fa-ticket-alt" bgColor="#eb5e28" />
                </div>
                <div className="col-md-4 col-6">
                  <CountCard title="Feedbacks" countData={this.state.feedbackCount} icon="fa-comment-alt" bgColor="#1b4332" />
                </div>
                <div className="col-md col-6">
                  <CountCard title="Total Order" countData={this.state.orderCount.all_order ?? "0"} icon="fa-shopping-bag" bgColor="#007621" />
                </div>
                <div className="col-md col-6">
                  <CountCard title="Pending Orders" countData={this.state.orderCount.pending ?? "0"} icon="fa-shopping-bag" bgColor="#87c38f" />
                </div>
                <div className="col-md col-6">
                  <CountCard title="Ongoing Orders" countData={this.state.orderCount.ongoing ?? "0"} icon="fa-shopping-bag" bgColor="#118ab2 " />
                </div>
                <div className="col-md col-6">
                  <CountCard title="Delivered Orders" countData={this.state.orderCount.delivered ?? "0"} icon="fa-shopping-bag" bgColor="#00afb9" />
                </div>
                <div className="col-md col-6">
                  <CountCard title="Cancelled Orders" countData={this.state.orderCount.cancel ?? "0"} icon="fa-shopping-bag" bgColor="#d62828" />
                </div>
              </div>
              <hr />
              <div className="row mb-3">
                <h5>Top Shops In Cart</h5>
                {this.state.topBakerCart.length ?
                  this.state.topBakerCart.map((data) => {
                    return (
                      <div className="col-md col-6">
                        <TopBakerCard bakerId={data.baker_id} bakerName={data.shop_name} bakerImage={data.shop_image} count={data.items_in_cart + " Items"} />
                      </div>
                    )
                  }) : <><p className='m-0 text-center text-danger'>No Data Found !!</p></>
                }
              </div>
              <hr />
              <div className="row mb-3">
                <h5>Top Shops By Orders (Delivered)</h5>
                {this.state.topDeliveredShop.length ? this.state.topDeliveredShop.map((data) => {
                  return (
                    <div className="col-md col-6">
                      <TopBakerCard bakerId={data.baker_id} bakerName={data.shop_name} bakerImage={data.shop_image} count={"Delivered " + data.total_order + " Order"} />
                    </div>
                  )
                }) : <><p className='m-0 text-center text-danger'>No Data Found !!</p></>}
              </div>
              <hr />
              <div className="row mb-3">
                <h5>Top Selling Category</h5>
                {this.state.topCategorySell.length ?
                  this.state.topCategorySell.map((data, index) => {
                    return (
                      <div className="col-md col-6">
                        <div className={`bg-${this.colorArray[index]} p-2 text-white text-center`} style={{ borderRadius: 12 }}>
                          <h5 className='m-0'>{data.product_category_name}</h5>
                        </div>
                      </div>
                    )
                  }) : <><p className='m-0 text-center text-danger'>No Data Found !!</p></>
                }
              </div>
              <hr />
              <div className="row mb-3">
                <h5>Top Customers</h5>
                {this.state.topCustomer.length ?
                  this.state.topCustomer.map((data) => {
                    return (
                      <div className="col-md mb-2">
                        <TopCustomerCard customerId={data.customer_id} customerName={data.name} customerImg={data.profile_pic} count={data.total_order} />
                      </div>
                    )
                  }) : <><p className='m-0 text-center text-danger'>No Data Found !!</p></>
                }
              </div>
              <hr />
              <div className="row mb-3">
                <h5>Top Rated Shops</h5>
                {this.state.topRatedShop.length ? this.state.topRatedShop.map((data) => {
                  return (
                    <div className="col-md col-6">
                      <TopRatedShop bakerId={data.baker_id} bakerName={data.shop_name} bakerImage={data.shop_image} count={data.total_rating} />
                    </div>
                  )
                }) : <><p className='m-0 text-center text-danger'>No Data Found !!</p></>}
              </div>
              <hr />
              <div className="row mb-3">
                <h5>Top Rated Products</h5>
                {this.state.topRatedProduct.length ?
                  this.state.topRatedProduct.map((data) => {
                    return (
                      <div className="col-md col-6">
                        <TopRatedProductCard bakerId={data.baker_id} productName={data.name} productImage={data.picture} bakerName={data.shop_name} count={data.total_rating} />
                      </div>
                    )
                  }) : <><p className='m-0 text-center text-danger'>No Data Found !!</p></>
                }
              </div>
              <hr />
              <div className="row mb-3">
                <h5>Top Selling Product (Delivered)</h5>
                {this.state.topSellingProduct.length ? this.state.topSellingProduct.map((data) => {
                  return (
                    <div className="col-md col-6">
                      <TopSellingProductCard bakerId={data.baker_id} productName={data.name} productImage={data.picture} bakerName={data.shop_name} count={data.total_order} />
                    </div>
                  )
                }) : <><p className='m-0 text-center text-danger'>No Data Found !!</p></>}
              </div>

            </div>
          </div>
        </div>
      </>
    )
  }
}
