import React, { Component } from 'react'
import Navbar from '../components/Navbar'
import "../css/main.css"
import SidebarMenu from '../components/SidebarMenu'
import { Link } from 'react-router-dom';
import { Button, Modal, Offcanvas } from 'react-bootstrap'
import ProductComponent from '../components/ProductComponent'
import { TbCertificate, TbCurrentLocation, TbLocation, TbPin, TbSearch, TbStar, TbUser } from 'react-icons/tb'
import { MdAdminPanelSettings } from 'react-icons/md'
import { GrPowerReset } from 'react-icons/gr'
import { GiPathDistance } from 'react-icons/gi'
import { BsArrowLeftRight } from 'react-icons/bs'
import Select from 'react-select'
import ConfirmationModal from '../components/ConfirmationModal'
import { toast, ToastContainer } from 'react-toastify'
import { getApi, postApi } from '../api/call.api'
import urlApi from '../api/url.api'
import Pagination from 'react-js-pagination'
import { dateTimeConverter } from '../utils/dateTimeConverter'

export default class AllShops extends Component {
    constructor(p) {
        super(p);
        this.state = {
            isOpen: false,
            menuModalShow: false,
            editModalShow: false,
            toggleCheck: false,
            addressModalShow: false,
            confirmModalShow: false,
            picUpload: '',
            tableData: [],
            addressList: [],
            pagination: ({
                active_page: 0,
                per_page: 10,
                total_items: 0
            }),
            page: 1,
            count: 10,
            rejectModal: false,
            sideBarShow: false,
            fssaiData: [],
            totalCount: "",
            disableModal: false,
        }
        this.statusChange = this.statusChange.bind(this);
        this.selectedShopID = null;
    }

    menuBarClick() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    statusChange(toggleCheck) {
        this.setState({ toggleCheck: toggleCheck });
    }

    options = [];
    value = [];

    async getAllProductCategoryList() {
        let res = await getApi(urlApi.productCategoryList);
        if (res.responsecode === "200") {
            for (const categoryItem in res.data) {
                this.options.push({
                    value: res.data[categoryItem].product_category_name,
                    label: res.data[categoryItem].product_category_name
                })
            }
        }
    }

    multiDropDownOnchange = (updatedValue, t) => {
        this.value = updatedValue;
    }

    menuViewModalShow() {
        this.setState({ menuModalShow: true });
    }
    menuViewModalHide() {
        this.setState({ menuModalShow: false });
    }

    addressViewModalShow() {
        this.setState({ addressModalShow: true })
    }
    addressViewModalHide() {
        this.setState({ addressModalShow: false })
    }

    async editViewModalShow(shopData) {
        this.setState({ picUpload: "" });
        let specialties = shopData.speciality.split(',');
        this.value = [];
        for (const speciality of specialties) {
            this.value.push({
                value: speciality,
                label: speciality
            })
        }
        await this.setState({ editModalShow: true });
        document.getElementById("shop-id").value = shopData.shop_id;
        document.getElementById("edit-shop-name").value = shopData.shop_name;
        document.getElementById("edit-shop-fssai").value = shopData.fssai_certificate;
        document.getElementById("edit-delivery-distance").value = shopData.delivery_distance;
        document.getElementById("edit-open-time").value = shopData.delivery_open_time;
        document.getElementById("edit-close-time").value = shopData.delivery_close_time;
        document.getElementById("pic-preview").src = shopData.shop_image;
        document.getElementById("edit-rating").value = shopData.rating;
        document.getElementById("edit-address").value = shopData.address;
        document.getElementById("edit-pincode").value = shopData.pincode;
        document.getElementById("edit-landmark").value = shopData.landmark;
        document.getElementById("edit-social-link-1").value = shopData.social_link_1;
        document.getElementById("edit-social-link-2").value = shopData.social_link_2;
    }

    async editModelYesBtnClick() {
        let speciality = "";
        for (let index = 0; index < this.value.length; index++) {
            if (index === 0) {
                speciality = this.value[index].value;
            } else {
                speciality = speciality + "," + this.value[index].value;
            }
        }
        if (speciality.split(",").length > 2) {
            this.alert("Specialty Can't be more than 2", "500")
        }
        else {

            if (speciality !== "" || speciality) {
                if (this.state.picUpload) {
                    const formData = new FormData();
                    formData.append(
                        "file_data", this.state.picUpload,
                    );
                    let respPic = await postApi(formData, urlApi.shopPicUpload);
                    if (respPic.responsecode === "200") {
                        let postData = {
                            "shop_id": document.getElementById("shop-id").value,
                            "shop_name": document.getElementById("edit-shop-name").value,
                            "delivery_distance": document.getElementById("edit-delivery-distance").value,
                            "fssai_number": document.getElementById("edit-shop-fssai").value,
                            "open_time": document.getElementById("edit-open-time").value,
                            "close_time": document.getElementById("edit-close-time").value,
                            "speciality": speciality,
                            "shop_image": respPic.data,
                            "rating": document.getElementById("edit-rating").value ?? 0.00,
                            "address_details": document.getElementById("edit-address").value,
                            "landmark": document.getElementById("edit-landmark").value,
                            "pincode": document.getElementById("edit-pincode").value,
                            "social_link_1": document.getElementById("edit-social-link-1").value,
                            "social_link_2": document.getElementById("edit-social-link-2").value,
                        }
                        let res = await postApi(postData, urlApi.shopDetailsUpdate);
                        this.editViewModalHide();
                        if (res.responsecode === "200") {
                            this.alert(res.message, res.responsecode);
                            this.getAllShopList();
                        }
                        else {
                            this.alert(res.message, res.responsecode);
                        }
                    }
                }
                else {
                    let postData = {
                        "shop_id": document.getElementById("shop-id").value,
                        "shop_name": document.getElementById("edit-shop-name").value,
                        "delivery_distance": document.getElementById("edit-delivery-distance").value,
                        "fssai_number": document.getElementById("edit-shop-fssai").value,
                        "open_time": document.getElementById("edit-open-time").value,
                        "close_time": document.getElementById("edit-close-time").value,
                        "speciality": speciality,
                        "shop_image": document.getElementById("pic-preview").src,
                        "rating": document.getElementById("edit-rating").value ?? 0.00,
                        "address_details": document.getElementById("edit-address").value,
                        "landmark": document.getElementById("edit-landmark").value,
                        "pincode": document.getElementById("edit-pincode").value,
                        "social_link_1": document.getElementById("edit-social-link-1").value,
                        "social_link_2": document.getElementById("edit-social-link-2").value,
                    }
                    let resp = await postApi(postData, urlApi.shopDetailsUpdate);
                    this.editViewModalHide();
                    if (resp.responsecode === "200") {
                        this.alert(resp.message, resp.responsecode);
                        this.getAllShopList();
                    }
                    else {
                        this.alert(resp.message, resp.responsecode);
                    }
                }
            }
        }

    }

    editViewModalHide() {
        this.setState({ editModalShow: false })
    }

    confirmViewModalShow(data) {
        this.selectedShopID = data.shop_id;
        this.getFssaiData(data.fssai_certificate);
        this.setState({ confirmModalShow: true }, () => { });
    }
    confirmViewModalHide() {
        this.setState({ fssaiData: [] });
        this.selectedShopID = null;
        this.setState({ confirmModalShow: false })
    }

    rejectModalShow(shopID) {
        this.selectedShopID = shopID;
        this.setState({ rejectModal: true })
    }
    rejectModalHide() {
        this.selectedShopID = null;
        this.setState({ rejectModal: false })
    }

    disableModalShow(shopID) {
        this.selectedShopID = shopID;
        this.setState({ disableModal: true })
    }
    disableModalHide() {
        this.selectedShopID = null;
        this.setState({ disableModal: false })
    }

    fileOnChange = async (e) => {
        await this.setState({ picUpload: e.target.files[0] });
        document.getElementById("pic-preview").src = URL.createObjectURL(e.target.files[0]);
    }

    alert = (msg, responseCode) => {
        if (responseCode === "200") {
            toast.success(msg);
        }
        else if (responseCode === "500") {
            toast.error(msg);
        }
        else {
            toast.info(msg);
        }
    }

    async resetTable() {
        await this.setState({ tableData: [] })
        await this.setState({
            pagination: ({
                active_page: 0,
                per_page: 10,
                total_items: 0
            })
        })
    }

    async resetFields() {
        document.getElementById("from-date-filter").value = "";
        document.getElementById("to-date-filter").value = "";
        document.getElementById("search-field").value = "";
        document.getElementById("status-dropdown").value = "";
        this.getAllShopList({ dateFilter: "" });
    }

    dateFilterVar = "";

    async getAllShopList({ dateFilter = this.dateFilterVar } = {}) {
        this.dateFilterVar = dateFilter;
        this.setState({ page: 1 });
        var postData = {
            "search_data": document.getElementById("search-field").value,
            "status": document.getElementById("status-dropdown").value,
            "date": dateFilter,
            "fromDate": document.getElementById("from-date-filter").value,
            "toDate": document.getElementById("to-date-filter").value
        }
        let resp = await postApi(postData, urlApi.allShopList + this.state.page);
        if (resp.responsecode === "200") {
            this.setState({ tableData: resp.data.data });
            this.setState({ pagination: resp.data.pagination });
            this.setState({ totalCount: resp.data.pagination.total_items });
        }
        else {
            this.setState({ totalCount: "0" });
            this.resetTable();
        }
    }

    async verifyShop(shopID, status) {
        let resp = await getApi(urlApi.verifyShop + shopID + "/" + status);
        this.confirmViewModalHide();
        if (resp.responsecode === "200") {
            this.alert(resp.message, "200");
            if (this.state.tableData.length === 1 && this.state.page > 1) {
                let pageNumber = 1;
                this.setState({ page: pageNumber }, () => this.getAllShopList());
            }
            else {
                this.getAllShopList();
            }

        }
        else {
            this.alert(resp.message, "500");
        }
    }

    async rejectShop(shopID) {
        let resp = await getApi(urlApi.verifyShop + shopID + "/" + 2);
        this.rejectModalHide();
        if (resp.responsecode === "200") {
            this.alert(resp.message, "200");
            if (this.state.tableData.length === 1 && this.state.page > 1) {
                let pageNumber = 1;
                this.setState({ page: pageNumber }, () => this.getAllShopList());
            }
            else {
                this.getAllShopList();
            }
        }
        else {
            this.alert(resp.message, "500");
        }
    }

    async disableShop(shopID) {
        let resp = await getApi(urlApi.verifyShop + shopID + "/" + 0);
        this.disableModalHide();
        if (resp.responsecode === "200") {
            this.alert(resp.message, "200");
            if (this.state.tableData.length === 1 && this.state.page > 1) {
                let pageNumber = 1;
                this.setState({ page: pageNumber }, () => this.getAllShopList());
            }
            else {
                this.getAllShopList();
            }
        }
        else {
            this.alert(resp.message, "500");
        }
    }

    async onSearch() {
        await this.setState({ page: 1 }, () => this.getAllShopList());
    }

    componentDidMount() {
        this.getAllShopList();
        this.getAllProductCategoryList();
    }

    async handlePageChange(pageNumber) {
        await this.setState({ page: pageNumber });
        this.getAllShopList();
    }

    showSideBar(data) {
        this.bakerID = data.baker_id;
        this.setState({ sideBarShow: true })
    }

    hideSideBar() {
        this.setState({ sideBarShow: false })
    }

    async sendNotification() {
        this.setState({ isComplete: false });
        if (this.state.picUpload) {
            const formData = new FormData();
            formData.append(
                "file_data", this.state.picUpload,
            );
            let respPic = await postApi(formData, urlApi.notificationPicUpload);
            if (respPic.responsecode === "200") {
                let postData = {
                    "type": "SINGLE_SHOP",
                    "title": document.getElementById('title').value,
                    "msg": document.getElementById('message').value,
                    "user_id": this.bakerID,
                    "image": respPic.data,
                }
                let resp = await postApi(postData, urlApi.sendNotification);
                this.hideSideBar();
                if (resp.responsecode === '200') {
                    this.setState({ isComplete: true });
                    this.alert(resp.message, resp.responsecode);
                    document.getElementById('title').value = null;
                    document.getElementById('message').value = null;
                    document.getElementById("pic-preview").src = null;
                } else {
                    this.alert(resp.message, resp.responsecode);
                }
            } else {
                this.alert(respPic.message, respPic.responsecode);
            }
        }
        else {
            let postData = {
                "type": "SINGLE_SHOP",
                "title": document.getElementById('title').value,
                "msg": document.getElementById('message').value,
                "user_id": this.bakerID,
            }
            let resp = await postApi(postData, urlApi.sendNotification);
            this.hideSideBar();
            if (resp.responsecode === '200') {
                this.setState({ isComplete: true });
                this.alert(resp.message, resp.responsecode);
                document.getElementById('title').value = null;
                document.getElementById('message').value = null;
                document.getElementById("pic-preview").src = null;
            } else {
                this.alert(resp.message, resp.responsecode);
            }
        }
    }

    async getFssaiData(fssaiNumber) {
        let postData = {
            "apptype": "R",
            "flrsLicenseNo": fssaiNumber
        }
        let resp = await postApi(postData, urlApi.getFssaiDetails);
        if (resp.responsecode === "200") {
            this.setState({ fssaiData: resp.data[0] });
        }
    }

    render() {
        var x = (this.state.pagination.active_page - 1) * this.state.pagination.per_page + 1;
        return (
            <>
                <div className="container-fluid position-relative bg-white d-flex p-0">
                    <SidebarMenu isOpen={this.state.isOpen} />
                    <div className={this.state.isOpen ? "content open" : "content"}>
                        <Navbar menuBarClick={() => this.menuBarClick()} />
                        <div className="container-fluid">
                            <ToastContainer autoClose={1000} />
                            <div className="row g-4">
                                <div className="col-12 px-0">
                                    <div className="rounded h-100 p-2">
                                        <h6 className="mb-2">Shop Details || Total : {this.state.totalCount ?? "0"}</h6>
                                        <div className="row mb-2 gx-2">
                                            <div className="col-md-1 col mb-1">
                                                <button className='btn-custom action-btn-5 w-100' onClick={() => this.getAllShopList({ dateFilter: "" })}>All</button>
                                            </div>
                                            <div className="col-md-1 col mb-1">
                                                <button className='btn-custom action-btn-1 w-100' onClick={() => this.getAllShopList({ dateFilter: "DAILY" })}>Today</button>
                                            </div>
                                            <div className="col-md-1 col mb-1">
                                                <button className='btn-custom action-btn-4 w-100' onClick={() => this.getAllShopList({ dateFilter: "YESTERDAY" })}>Yesterday</button>
                                            </div>
                                            <div className="col-md-1 col mb-1">
                                                <button className='btn-custom action-btn-3 w-100 text-nowrap' onClick={() => this.getAllShopList({ dateFilter: "WEEKLY" })}>Last Week</button>
                                            </div>
                                            <div className="col-md-1 col mb-1">
                                                <button className='btn-custom action-btn-2 w-100 text-nowrap' onClick={() => this.getAllShopList({ dateFilter: "MONTHLY" })}>Last Month</button>
                                            </div>
                                        </div>
                                        <div className="row gx-2 mb-2">
                                            <div className="col-md-2 col-5 mb-2">
                                                <input type="date" className='form-control form-control-sm w-100' id='from-date-filter' />
                                            </div>
                                            <div className="col-md-1 mb-2 col text-center"> <BsArrowLeftRight /> </div>
                                            <div className="col-md-2 col-5 mb-2">
                                                <input type="date" className='form-control form-control-sm w-100' id='to-date-filter' />
                                            </div>
                                            <div className="col-md-1 col">
                                                <button className='btn-custom action-btn-1 w-100' onClick={() => this.getAllShopList({ dateFilter: "CUSTOM" })}>Search</button>
                                            </div>
                                            <div className="col-md-2 col-2">
                                                <button className='btn-custom action-btn-5 w-sm-100' onClick={() => this.resetFields()}><GrPowerReset size={20} /></button>
                                            </div>
                                        </div>
                                        <form onSubmit={(e) => { e.preventDefault(); this.onSearch() }}>
                                            <div className="col-12 mb-2">
                                                <div className="row gx-1 search-section">
                                                    <div className="col-md-3 col-6">
                                                        <input className='form-control form-control-sm' id='search-field' type="text" placeholder='Shop Name / Mobile / ID' />
                                                    </div>
                                                    <div className="col-md-3 col-4">
                                                        <select className='form-control form-control-sm w-100' id='status-dropdown' defaultValue="">
                                                            <option value="">All Shops</option>
                                                            <option value="0">Pending</option>
                                                            <option value="1">Approved</option>
                                                            <option value="2">Rejected</option>
                                                            <option value="3">No FSSAI</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2 col-2">
                                                        <button className='btn-custom action-btn-5 w-sm-100' type='submit'><TbSearch size={20} /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                        {this.state.tableData.length > 0 ?
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="col">#</th>
                                                            <th className="col">ID</th>
                                                            <th className="col">Shop Name</th>
                                                            <th className="col">Baker</th>
                                                            <th className="col">FSSAI Number</th>
                                                            <th className="col">Rating</th>
                                                            <th className="col">Shop Time</th>
                                                            <th className="col">Address</th>
                                                            <th className="col">Status</th>
                                                            <th className="col">Create Date</th>
                                                            <th className="col">modify</th>
                                                            <th className="col-2">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.tableData.map((data, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <th scope="row">{x++}</th>
                                                                    <td>{data.baker_id}</td>
                                                                    <td className='text-left'>
                                                                        <Link target={"_blank"} to={"/single-shop-view/shopDetails/" + data.baker_id}>
                                                                            <span className='table-img'><img src={data.shop_image} alt="" /></span><br />
                                                                            <span>{data.shop_name}</span>
                                                                        </Link>
                                                                    </td>
                                                                    <td className='text-left'>
                                                                        <Link target={"_blank"} to={"/single-shop-view/shopDetails/" + data.baker_id}>
                                                                            <span>{data.baker_name}</span>
                                                                        </Link>
                                                                    </td>
                                                                    <td>
                                                                        <span>{data.fssai_certificate}</span><br />
                                                                        <button style={{ display: (data.status === 0 || data.status === 2) ? 'unset' : 'none' }} className='btn-custom action-btn-2 m-1' onClick={() => this.confirmViewModalShow(data)}>Verify</button>
                                                                        <button style={{ display: data.status === 0 ? 'unset' : 'none' }} className='btn-custom action-btn-3 m-1' onClick={() => this.rejectModalShow(data.shop_id)}>Reject</button>
                                                                        <button style={{ display: data.status === 1 ? 'unset' : 'none' }} className='btn-custom action-btn-3 m-1' onClick={() => this.disableModalShow(data.shop_id)}>Disable</button>
                                                                    </td>
                                                                    <td><i className='fa fa-star main-color'>&nbsp;</i>{data.rating}</td>
                                                                    <td>{data.delivery_open_time + "-" + data.delivery_close_time}</td>
                                                                    <td><span>{data.address},{data.pincode}</span><br /><span>Delivery : {data.delivery_distance} KM</span></td>
                                                                    <td>
                                                                        <td><span className={`badge ${data.status === 0 ? "bg-warning" : data.status === 1 ? "bg-success" : "bg-danger"}`}>{data.status === 0 ? "Pending" : data.status === 1 ? "Approved" : "Rejected"}</span></td>
                                                                    </td>
                                                                    <td style={{ fontSize: 12 }}>{dateTimeConverter(data.create_date)}</td>
                                                                    <td style={{ fontSize: 12 }}>{data.modified_by === 0 ? "" : data.modified_by === data.baker_id ? data.baker_name : data.admin_name}&nbsp;
                                                                        <span title='Admin' style={{ display: data.modified_by === 0 ? 'none' : data.modified_by === data.baker_id ? "none" : 'unset' }}>
                                                                            <MdAdminPanelSettings size={14} color='#f08632' />
                                                                        </span>
                                                                        <br />
                                                                        {dateTimeConverter(data.modify_date)}</td>
                                                                    <td>
                                                                        <Link className='btn-custom action-btn-1 m-1' target={"_blank"} to={"/single-shop-view/menu/" + data.baker_id}>Menu</Link>
                                                                        <button className='btn-custom action-btn-2 m-1' onClick={() => this.editViewModalShow(data)}>Edit</button>
                                                                        <Link className='btn-custom action-btn-4 m-1' target={"_blank"} to={"/single-shop-view/shopDetails/" + data.baker_id}>View</Link><br />
                                                                        <Link to={"/single-shop-view/orderDetails/" + data.baker_id} target={"_blank"} className='btn-custom action-btn-6 m-1'>Order History</Link>
                                                                        <button className='btn-custom action-btn-5 m-1' onClick={() => this.showSideBar(data)}>Send Notification</button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            : <div className="no-data text-center my-5">
                                                <h4 className='text-danger'>Sorry No Data Found!</h4>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div><div className='me-2' style={{ float: 'right', display: this.state.tableData.length > 0 ? 'unset' : 'none' }}>
                                <Pagination
                                    activePage={parseInt(this.state.pagination.active_page)}
                                    itemsCountPerPage={this.state.pagination.per_page}
                                    totalItemsCount={this.state.pagination.total_items}
                                    pageRangeDisplayed={5}
                                    onChange={this.handlePageChange.bind(this)}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.menuModalShow} onHide={() => this.menuViewModalHide()}>
                    <Modal.Header closeButton style={{ display: "none" }}>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: "10px 14px" }}>
                        <div className="text-center"><h3 style={{ borderBottom: "4px solid teal" }}>View Menu</h3></div>
                        <ProductComponent />
                        <ProductComponent />
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'none' }}>
                        <Button className='action-btn-2' onClick={() => this.menuViewModalHide()}>
                            Close
                        </Button>
                        <Button className='action-btn-1' onClick={() => this.menuViewModalHide()}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.editModalShow} onHide={() => this.editViewModalHide()}>
                    <Modal.Header closeButton style={{ display: "none" }}>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: "10px 14px", maxHeight: "85vh", overflow: "auto" }}>
                        <div className="text-center"><h3 style={{ borderBottom: "4px solid teal" }}>Edit Shop</h3></div>
                        <form>
                            <input id='shop-id' type="text" style={{ display: "none" }} />
                            <div className="form-group mb-2">
                                <label htmlFor="edit-shop-name">Shop Name</label>
                                <div className="input-group input-group-sm">
                                    <input id="edit-shop-name" type="text" className="form-control form-control-sm" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span><TbUser /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="">FSSAI Number</label>
                                <div className="input-group input-group-sm">
                                    <input id="edit-shop-fssai" type="text" maxLength={14} className="form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span><TbCertificate /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="">Delivery Distance(KM)</label>
                                <div className="input-group input-group-sm">
                                    <input id="edit-delivery-distance" type="text" className="form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span><GiPathDistance /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label for="">Open time:</label>
                                    <input className='form-control' type="time" id="edit-open-time" name="open-time" />
                                </div>
                                <div className="col">
                                    <label for="">Close time:</label>
                                    <input className='form-control' type="time" id="edit-close-time" name="open-time" />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="">Specialty</label>
                                <div className="input-group input-group-sm">
                                    <Select id='edit-speciality' defaultValue={this.value} onChange={this.multiDropDownOnchange} isMulti className="basic-multi-select w-100" options={this.options} />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="">Shop Image :&nbsp;&nbsp;&nbsp;</label>
                                <img id="pic-preview" style={{ maxHeight: 60 }} alt="" />
                            </div>
                            <div className="input-group mb-2">
                                <input id="edit-photo" type="file" className="form-control form-control-sm" onChange={this.fileOnChange} />
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="">Rating</label>
                                <div className="input-group input-group-sm">
                                    <input id="edit-rating" type="text" className="form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span><TbStar /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="">Address</label>
                                <div className="input-group input-group-sm">
                                    <input id="edit-address" type="text" className="form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span><TbLocation /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="">PinCode</label>
                                <div className="input-group input-group-sm">
                                    <input id="edit-pincode" type="text" className="form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span><TbCurrentLocation /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="">Landmark</label>
                                <div className="input-group input-group-sm">
                                    <input id="edit-landmark" type="text" className="form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span><TbPin /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="edit-social-link-1">Social Link 1</label>
                                <div className="input-group input-group-sm">
                                    <input id="edit-social-link-1" type="text" className="form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span><TbPin /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="edit-social-link-2">Social Link 2</label>
                                <div className="input-group input-group-sm">
                                    <input id="edit-social-link-2" type="text" className="form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span><TbPin /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='btn-custom action-btn-2' onClick={() => this.editViewModalHide()}>
                            Close
                        </Button>
                        <Button className='btn-custom action-btn-1' onClick={() => this.editModelYesBtnClick()}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>

                <ConfirmationModal
                    show={this.state.confirmModalShow}
                    closeFunction={() => this.confirmViewModalHide()}
                    headerText={"Verify Shop"}
                    bodyText={"Are You Sure Want To Verify This Shop?"}
                    fssaiData={this.state.fssaiData}
                    okFunction={() => this.verifyShop(this.selectedShopID, 1)}
                />

                <ConfirmationModal
                    show={this.state.rejectModal}
                    closeFunction={() => this.rejectModalHide()}
                    headerText={"Reject Shop"}
                    bodyText={"Are You Sure Want To Reject This Shop?"}
                    okFunction={() => this.rejectShop(this.selectedShopID)}
                />

                <ConfirmationModal
                    show={this.state.disableModal}
                    closeFunction={() => this.disableModalHide()}
                    headerText={"Disable Shop"}
                    bodyText={"Are You Sure Want To Disable This Shop?"}
                    okFunction={() => this.disableShop(this.selectedShopID)}
                />

                <Offcanvas show={this.state.sideBarShow} onHide={() => this.hideSideBar()} placement={'end'}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Send Notification</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="form-group mb-2">
                            <label htmlFor="">Title</label>
                            <input id='title' className='form-control' />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="">Message</label>
                            <textarea id='message' className='form-control'></textarea>
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="">Picture :&nbsp;&nbsp;&nbsp;</label>
                            <img id="pic-preview" style={{ maxHeight: 80, width: 'auto', borderRadius: 5 }} alt="" />
                        </div>
                        <div className="input-group mb-2">
                            <input id="edit-photo" type="file" className="form-control form-control-sm" onChange={this.fileOnChange} />
                        </div>
                        <button className='btn-custom action-btn-1 w-100 mt-2' onClick={() => this.sendNotification()}>Send</button>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        )
    }
}
