import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'

export default class ConfirmationModal extends Component {
    render() {
        return (
            <>
                <Modal show={this.props.show} onHide={this.props.closeFunction}>
                    <Modal.Header closeButton style={{ display: "none" }}>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: "10px 14px" }}>
                        <div className="text-center"><h3 style={{ borderBottom: "4px solid teal" }}>{this.props.headerText}</h3></div>
                        <p className='m-0 py-3'>{this.props.bodyText}</p>
                        <div className="fssai_data" style={{ display: this.props.fssaiData ? 'unset' : 'none' }}>
                            {
                                this.props.fssaiData &&
                                this.props.fssaiData.premiseaddress ?
                                    <div className="row">
                                        <span>Address :  &nbsp;&nbsp;{this.props.fssaiData.premiseaddress}</span><br />
                                        <span>Pincode :  &nbsp;&nbsp;{this.props.fssaiData.premisepincode}</span><br />
                                        <span>License No. :  &nbsp;&nbsp;{this.props.fssaiData.licenseno}</span><br />
                                        <span>Fboid :  &nbsp;&nbsp;{this.props.fssaiData.fboid}</span><br />
                                        <span>License Category :  &nbsp;&nbsp;{this.props.fssaiData.licensecategoryname}</span><br />
                                        <span>State :  &nbsp;&nbsp;{this.props.fssaiData.statename}</span><br />
                                        <span>Status :  &nbsp;&nbsp;{this.props.fssaiData.statusdesc}</span><br />
                                        <span>Taluk :  &nbsp;&nbsp;{this.props.fssaiData.talukname}</span><br />
                                        <span>District :  &nbsp;&nbsp;{this.props.fssaiData.districtname}</span><br />
                                        <span>Company :  &nbsp;&nbsp;{this.props.fssaiData.companyname}</span><br />
                                        <span>License Active Flag :  &nbsp;&nbsp;{this.props.fssaiData.licenseactiveflag ? "Active" : "Inactive"}</span>
                                    </div> :
                                    <h4 className='text-danger text-center'>No Record Found On FSSAI Portal!</h4>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='btn-custom action-btn-2' onClick={this.props.closeFunction}>
                            Close
                        </Button>
                        <Button className='btn-custom action-btn-1' onClick={this.props.okFunction}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}
