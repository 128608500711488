import React, { Component } from 'react'
import { Button, Modal, Spinner, Tab, Tabs } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import urlApi from '../api/url.api';
import Navbar from '../components/Navbar';
import SidebarMenu from '../components/SidebarMenu'
import { postApi } from "../api/call.api"
import Pagination from 'react-js-pagination';
import { TbSearch } from 'react-icons/tb';
import { BsApple } from 'react-icons/bs';
import { DiAndroid } from 'react-icons/di';

export default class SettingsView extends Component {
    constructor(p) {
        super(p);
        this.state = {
            isOpen: false,
            updateModalShow: false,
            picUploadBaker: "",
            picUploadCustomer: "",
            isDisable: false,
            pagination: ({
                active_page: 0,
                per_page: 10,
                total_items: 0
            }),
            page: 1,
            count: 10,
            bakerSettingTableData: [],
            customerSettingTableData: [],
        }
        this.pageName = "";
    }

    menuBarClick() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    alert = (msg, responseCode) => {
        if (responseCode === "200") {
            toast.success(msg);
        }
        else if (responseCode === "500") {
            toast.error(msg);
        }
        else {
            toast.info(msg);
        }
    }

    showUpdateModal(data, type) {
        this.pageName = type;
        this.setState({ updateModalShow: true }, () => {
            if (type === "baker") {
                document.getElementById("version-baker").value = data.version;
                document.getElementById("version-status-baker").value = data.active_status;
                document.getElementById("pop-text-baker").value = data.pop_text;
                document.getElementById("pop-text-status-baker").value = data.pop_status;
                document.getElementById("pic-preview-baker").src = data.pop_image_link;
                document.getElementById("pop-image-status-baker").value = data.pop_image_status;
                document.getElementById("message-baker").value = data.message;
                document.getElementById("support-baker").value = data.mobile;
                document.getElementById("app-link-baker").value = data.app_link;
                document.getElementById("tutorial-baker").value = data.tutorial_link;
                document.getElementById("shop-reg-message-baker").value = data.shop_reg_message;
                document.getElementById("id-baker").value = data.id;
            }
            if (type === "customer") {
                document.getElementById("version-customer").value = data.version;
                document.getElementById("version-status-customer").value = data.active_status;
                document.getElementById("pop-text-customer").value = data.pop_text;
                document.getElementById("pop-text-status-customer").value = data.pop_status;
                document.getElementById("pic-preview-customer").src = data.pop_image_link;
                document.getElementById("pop-image-status-customer").value = data.pop_image_status;
                document.getElementById("message-customer").value = data.message;
                document.getElementById("support-customer").value = data.mobile_support;
                document.getElementById("app-link-customer").value = data.app_link;
                document.getElementById("id-customer").value = data.id;
            }
        })
    }

    hideUpdateModal() {
        this.setState({ updateModalShow: false });
    }

    fileOnChangeBaker = async (e) => {
        await this.setState({ picUploadBaker: e.target.files[0] });
        document.getElementById("pic-preview-baker").src = URL.createObjectURL(e.target.files[0]);
    }

    fileOnChangeCustomer = async (e) => {
        await this.setState({ picUploadCustomer: e.target.files[0] });
        document.getElementById("pic-preview-customer").src = URL.createObjectURL(e.target.files[0]);
    }

    async resetTable() {
        await this.setState({ bakerSettingTableData: [] });
        await this.setState({ customerSettingTableData: [] });
        await this.setState({
            pagination: ({
                active_page: 0,
                per_page: 10,
                total_items: 0
            })
        })
    }

    async getBakerSettingData() {
        let postData = {
            "version_status": document.getElementById("version-status-search").value,
            "platform": document.getElementById("platform-search").value,
        };
        let resp = await postApi(postData, urlApi.fetchBakerSettings + this.state.page);
        if (resp.responsecode === "200") {
            this.setState({ bakerSettingTableData: resp.data.data });
            this.setState({ pagination: resp.data.pagination });
        }
        else {
            this.resetTable();
        }
    }

    async onSearchBaker() {
        await this.setState({ page: 1 }, () => this.getBakerSettingData());
    }

    async bakerSettingUpdate() {
        this.setState({ isDisable: true });
        if (this.state.picUploadBaker) {
            const formData = new FormData();
            formData.append(
                "file_data", this.state.picUploadBaker,
            );
            let respPic = await postApi(formData, urlApi.popImageUpload);
            this.setState({ isDisable: false });
            if (respPic.responsecode === "200") {
                let postData = {
                    "setting_id": document.getElementById("id-baker").value,
                    "version_status": document.getElementById("version-status-baker").value,
                    "pop_text": document.getElementById("pop-text-baker").value,
                    "pop_status": document.getElementById("pop-text-status-baker").value,
                    "pop_image_link": respPic.data,
                    "pop_image_status": document.getElementById("pop-image-status-baker").value,
                    "message": document.getElementById("message-baker").value,
                    "app_link": document.getElementById("app-link-baker").value,
                    "mobile": document.getElementById("support-baker").value,
                    "tutorial_link": document.getElementById("tutorial-baker").value,
                    "shop_reg_message": document.getElementById("shop-reg-message-baker").value,
                }
                let resp = await postApi(postData, urlApi.updateBakerSettings);
                this.setState({ isDisable: false });
                this.hideUpdateModal();
                if (resp.responsecode === "200") {
                    this.alert(resp.message, "200");
                    this.getBakerSettingData();
                } else {
                    this.alert(resp.message, "500");
                }
            } else {
                this.alert(respPic.message, "500");
            }
        } else {
            let postData = {
                "setting_id": document.getElementById("id-baker").value,
                "version_status": document.getElementById("version-status-baker").value,
                "pop_text": document.getElementById("pop-text-baker").value,
                "pop_status": document.getElementById("pop-text-status-baker").value,
                "pop_image_link": document.getElementById("pic-preview-baker").src,
                "pop_image_status": document.getElementById("pop-image-status-baker").value,
                "message": document.getElementById("message-baker").value,
                "app_link": document.getElementById("app-link-baker").value,
                "mobile": document.getElementById("support-baker").value,
                "tutorial_link": document.getElementById("tutorial-baker").value,
                "shop_reg_message": document.getElementById("shop-reg-message-baker").value,
            }
            let resp = await postApi(postData, urlApi.updateBakerSettings);
            this.setState({ isDisable: false });
            this.hideUpdateModal();
            if (resp.responsecode === "200") {
                this.alert(resp.message, "200");
                this.getBakerSettingData();
            } else {
                this.alert(resp.message, "500");
            }
        }

    }

    async getCustomerSettingData() {
        let postData = {
            "version_status": document.getElementById("version-status-search-customer").value,
            "platform": document.getElementById("platform-search-customer").value,
        };
        let resp = await postApi(postData, urlApi.fetchCustomerSettings + this.state.page);
        if (resp.responsecode === "200") {
            this.setState({ customerSettingTableData: resp.data.data });
            this.setState({ pagination: resp.data.pagination });
        }
        else {
            this.resetTable();
        }
    }

    async onSearchCustomer() {
        await this.setState({ page: 1 }, () => this.getCustomerSettingData());
    }

    async customerSettingUpdate() {
        this.setState({ isDisable: true });
        if (this.state.picUploadCustomer) {
            const formData = new FormData();
            formData.append(
                "file_data", this.state.picUploadCustomer,
            );
            let respPic = await postApi(formData, urlApi.popImageUpload);
            this.setState({ isDisable: false });
            this.hideUpdateModal();
            if (respPic.responsecode === "200") {
                let postData = {
                    "setting_id": document.getElementById("id-customer").value,
                    "version_status": document.getElementById("version-status-customer").value,
                    "pop_text": document.getElementById("pop-text-customer").value,
                    "pop_status": document.getElementById("pop-text-status-customer").value,
                    "pop_image_link": respPic.data,
                    "pop_image_status": document.getElementById("pop-image-status-customer").value,
                    "message": document.getElementById("message-customer").value,
                    "app_link": document.getElementById("app-link-customer").value,
                    "mobile": document.getElementById("support-customer").value,
                }
                let resp = await postApi(postData, urlApi.updateCustomerSettings);
                this.setState({ isDisable: false });
                this.hideUpdateModal();
                if (resp.responsecode === "200") {
                    this.alert(resp.message, "200");
                    this.getCustomerSettingData();
                } else {
                    this.alert(resp.message, "500");
                }
            } else {
                this.alert(respPic.message, "500");
            }
        } else {
            let postData = {
                "setting_id": document.getElementById("id-customer").value,
                "version_status": document.getElementById("version-status-customer").value,
                "pop_text": document.getElementById("pop-text-customer").value,
                "pop_status": document.getElementById("pop-text-status-customer").value,
                "pop_image_link": document.getElementById("pic-preview-customer").src,
                "pop_image_status": document.getElementById("pop-image-status-customer").value,
                "message": document.getElementById("message-customer").value,
                "app_link": document.getElementById("app-link-customer").value,
                "mobile": document.getElementById("support-customer").value,
            }
            let resp = await postApi(postData, urlApi.updateCustomerSettings);
            this.setState({ isDisable: false });
            this.hideUpdateModal();
            if (resp.responsecode === "200") {
                this.alert(resp.message, "200");
                this.getCustomerSettingData();
            } else {
                this.alert(resp.message, "500");
            }
        }

    }

    async handlePageChangeForBaker(pageNumber) {
        await this.setState({ page: pageNumber });
        this.getBakerSettingData();
    }

    async handlePageChangeForCustomer(pageNumber) {
        await this.setState({ page: pageNumber });
        this.getCustomerSettingData();
    }

    componentDidMount() {
        if (this.props.match.params.defaultActiveKey === "baker") {
            this.getBakerSettingData();
        }
        if (this.props.match.params.defaultActiveKey === "customer") {
            this.getCustomerSettingData();
        }
    }

    render() {
        var bakerX = (this.state.pagination.active_page - 1) * this.state.pagination.per_page + 1;
        var customerX = (this.state.pagination.active_page - 1) * this.state.pagination.per_page + 1;
        return (
            <>
                <div className="container-fluid position-relative bg-white d-flex p-0">
                    <SidebarMenu isOpen={this.state.isOpen} />
                    <div className={this.state.isOpen ? "content open" : "content"}>
                        <Navbar menuBarClick={() => this.menuBarClick()} />
                        <div className="container-fluid">
                            <ToastContainer autoClose={1000} />
                            <div className="row g-4">
                                <div className="col-12 px-0">
                                    <div className="bg-light rounded h-100 p-2">
                                        <Tabs defaultActiveKey={this.props.match.params.defaultActiveKey ? this.props.match.params.defaultActiveKey : "baker"}
                                            id="uncontrolled-tab-example"
                                            className="mb-3"
                                            onSelect={(e) => {
                                                this.props.history.push('/settings/' + e);
                                                if (e === "baker") {
                                                    this.getBakerSettingData();
                                                }
                                                if (e === "customer") {
                                                    this.getCustomerSettingData();
                                                }
                                            }}
                                        >

                                            <Tab eventKey="baker" title={<Link className='tab-link-color' to="/settings/baker">Baker's Setting</Link>}>
                                                <form onSubmit={(e) => { e.preventDefault(); this.onSearchBaker() }} className="mt-md-5" style={{marginTop:50}}>
                                                    <div className="col-12 ">
                                                        <div className="row gx-1 search-section">
                                                            <div className="col-md-3 col-6">
                                                                <select id="platform-search" className='form-control form-control-sm w-100'>
                                                                    <option value="">---All Platform---</option>
                                                                    <option value="ANDROID">Android</option>
                                                                    <option value="IOS">IOS</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-md-3 col-6 mb-1">
                                                                <select id="version-status-search" className='form-control form-control-sm w-100'>
                                                                    <option value="">---All Version---</option>
                                                                    <option value="1">Active</option>
                                                                    <option value="0">Inactive</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-md-2 col-2">
                                                                <button className='btn-custom action-btn-5 w-sm-100' type='submit'><TbSearch size={20} /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className="table-responsive">
                                                    {this.state.bakerSettingTableData.length > 0 ?
                                                        <table className="table">
                                                            <thead>
                                                                <tr className='text-center'>
                                                                    <th className="col">#</th>
                                                                    <th className="col">Version</th>
                                                                    <th className="col">Platform</th>
                                                                    <th className="col">Pop Text</th>
                                                                    <th className="col">Pop Image</th>
                                                                    <th className="col">Update Message</th>
                                                                    <th className="col">Support</th>
                                                                    <th className="col">Tutorial</th>
                                                                    <th className="col">Shop Reg. Message</th>
                                                                    <th className="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.bakerSettingTableData.map((data,index) => {
                                                                    return (
                                                                        <tr className='text-center' key={index}>
                                                                            <th scope="row">{bakerX++}</th>
                                                                            <td className='text-center'>
                                                                                {data.version}<br />
                                                                                <span className={`badge bg-${data.active_status === 0 ? "danger" : "success"}`}>{data.active_status === 0 ? "Inactive" : "Active"}</span>
                                                                            </td>
                                                                            <td>
                                                                                <Link to={{ pathname: data.app_link }} target="_blank">
                                                                                    <span> {data.platform === "ANDROID" ? <DiAndroid color='#a4c639' size={40} /> : <BsApple color='#1690F0' size={40} />} </span> </Link>
                                                                            </td>
                                                                            <td>{data.pop_text}<br /><span className={`badge bg-${data.pop_status === 0 ? "danger" : "success"}`}>{data.pop_status === 0 ? "Inactive" : "Active"}</span></td>
                                                                            <td> <span><img src={data.pop_image_link} style={{ height: 100, width: 100, borderRadius: 10, objectFit: 'cover' }} alt="" /></span><br /><span className={`badge bg-${data.pop_image_status === 0 ? "danger" : "success"}`}>{data.pop_image_status === 0 ? "Inactive" : "Active"}</span> </td>
                                                                            <td>{data.message}</td>
                                                                            <td>{data.mobile}</td>
                                                                            <td> <Link to={{ pathname: data.tutorial_link }} target="_blank"> <span className='badge bg-primary'>Watch Tutorial</span></Link></td>
                                                                            <td>{data.shop_reg_message === "" ? "--" : data.shop_reg_message}</td>
                                                                            <td>
                                                                                <button className='btn-custom action-btn-2' onClick={() => this.showUpdateModal(data, "baker")}>Update</button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                        : <div className="col-12">
                                                            <h4 className='text-center text-danger'>No Settings Found</h4>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='me-2' style={{ float: 'right', display: this.state.bakerSettingTableData.length > 0 ? 'unset' : 'none' }}>
                                                    <Pagination
                                                        activePage={parseInt(this.state.pagination.active_page)}
                                                        itemsCountPerPage={this.state.pagination.per_page}
                                                        totalItemsCount={this.state.pagination.total_items}
                                                        pageRangeDisplayed={5}
                                                        onChange={this.handlePageChangeForBaker.bind(this)}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </Tab>

                                            <Tab eventKey="customer" title={<Link className='tab-link-color' to="/settings/customer">Customer's Setting</Link>}>
                                                <form onSubmit={(e) => { e.preventDefault(); this.onSearchCustomer() }} className="mt-md-5" style={{marginTop:50}}>
                                                    <div className="col-12 mb-3">
                                                        <div className="row gx-1 search-section">
                                                            <div className="col-md-3 col-6">
                                                                <select id="platform-search-customer" className='form-control form-control-sm w-100'>
                                                                    <option value="">---All Platform---</option>
                                                                    <option value="ANDROID">Android</option>
                                                                    <option value="IOS">IOS</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-md-3 col-6 mb-1">
                                                                <select id="version-status-search-customer" className='form-control form-control-sm w-100'>
                                                                    <option value="">---All Version---</option>
                                                                    <option value="1">Active</option>
                                                                    <option value="0">Inactive</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-md-2 col-2">
                                                                <button className='btn-custom action-btn-5 w-sm-100' type='submit'><TbSearch size={20} /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className="table-responsive">
                                                    {this.state.customerSettingTableData.length > 0 ?
                                                        <table className="table">
                                                            <thead>
                                                                <tr className='text-center'>
                                                                    <th className="col">#</th>
                                                                    <th className="col">Version</th>
                                                                    <th className="col">Platform</th>
                                                                    <th className="col">Pop Text</th>
                                                                    <th className="col">Pop Image</th>
                                                                    <th className="col">Update Message</th>
                                                                    <th className="col">Support</th>
                                                                    <th className="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.customerSettingTableData.map((data,index) => {
                                                                    return (
                                                                        <tr className='text-center' key={index}>
                                                                            <th scope="row">{customerX++}</th>
                                                                            <td className='text-center'>
                                                                                {data.version}<br />
                                                                                <span className={`badge bg-${data.active_status === 0 ? "danger" : "success"}`}>{data.active_status === 0 ? "Inactive" : "Active"}</span>
                                                                            </td>
                                                                            <td>
                                                                                <Link to={{ pathname: data.app_link }} target="_blank">
                                                                                    <span> {data.platform === "ANDROID" ? <DiAndroid color='#a4c639' size={40} /> : <BsApple color='#1690F0' size={40} />} </span> </Link>
                                                                            </td>
                                                                            <td>{data.pop_text}<br /><span className={`badge bg-${data.pop_status === 0 ? "danger" : "success"}`}>{data.pop_status === 0 ? "Inactive" : "Active"}</span></td>
                                                                            <td> <span><img src={data.pop_image_link} style={{ height: 100, width: 100, borderRadius: 10, objectFit: 'cover' }} alt="" /></span><br /><span className={`badge bg-${data.pop_image_status === 0 ? "danger" : "success"}`}>{data.pop_image_status === 0 ? "Inactive" : "Active"}</span> </td>
                                                                            <td>{data.message}</td>
                                                                            <td>{data.mobile_support}</td>
                                                                            <td>
                                                                                <button className='btn-custom action-btn-2' onClick={() => this.showUpdateModal(data, "customer")}>Update</button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                        : <div className="col-12">
                                                            <h4 className='text-center text-danger'>No Settings Found</h4>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='me-2' style={{ float: 'right', display: this.state.customerSettingTableData.length > 0 ? 'unset' : 'none' }}>
                                                    <Pagination
                                                        activePage={parseInt(this.state.pagination.active_page)}
                                                        itemsCountPerPage={this.state.pagination.per_page}
                                                        totalItemsCount={this.state.pagination.total_items}
                                                        pageRangeDisplayed={5}
                                                        onChange={this.handlePageChangeForCustomer.bind(this)}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </Tab>
                                        </Tabs>

                                        <Modal show={this.state.updateModalShow} onHide={() => this.hideUpdateModal()}>
                                            <Modal.Header closeButton style={{ display: "none" }}>
                                                <Modal.Title>Modal heading</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body style={{ padding: "10px 14px" }}>
                                                {this.pageName === "baker" ?
                                                    <><div className="text-center"><h3 style={{ borderBottom: "4px solid teal" }}>Edit Baker Setting</h3></div>

                                                        <div className="row g-0">
                                                            <div className="col-md-12">
                                                                <input type="text" className='d-none' id='id-baker' />
                                                                <div className="row mb-4">
                                                                    <div className="col">
                                                                        <label htmlFor="version-baker">Version</label>
                                                                        <input disabled type="text" id='version-baker' className='form-control form-control-sm' />
                                                                    </div>
                                                                    <div className="col">
                                                                        <label htmlFor="version-status-baker">Version Status</label>
                                                                        <select name="" id="version-status-baker" className='form-control form-control-sm'>
                                                                            <option value="1">Active</option>
                                                                            <option value="0">Inactive</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-4">
                                                                    <div className="col">
                                                                        <label htmlFor="pop-text-baker">Pop-Up Text</label>
                                                                        <input type="text" id='pop-text-baker' className='form-control form-control-sm' />
                                                                    </div>
                                                                    <div className="col">
                                                                        <label htmlFor="pop-text-status-baker">Pop-Up Text Status</label>
                                                                        <select name="" id="pop-text-status-baker" className='form-control form-control-sm'>
                                                                            <option value="1">Active</option>
                                                                            <option value="0">Inactive</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="row ">
                                                                    <div className="col">
                                                                        <label htmlFor="">Pop-Up Image :&nbsp;&nbsp;&nbsp;</label>
                                                                        <input id="pop-image-baker" type="file" className="form-control form-control-sm mb-2" onChange={this.fileOnChangeBaker} />
                                                                        <img id="pic-preview-baker" style={{ maxHeight: 120, width: 'auto', borderRadius: 5 }} alt="" />
                                                                    </div>
                                                                    <div className="col">
                                                                        <label htmlFor="pop-image-status-baker">Pop-Up Image Status</label>
                                                                        <select name="" id="pop-image-status-baker" className='form-control form-control-sm'>
                                                                            <option value="1">Active</option>
                                                                            <option value="0">Inactive</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className='row mb-4'>
                                                                    <div className="col">
                                                                        <label htmlFor="message-baker">Message</label>
                                                                        <textArea id="message-baker" rows="4" className="form-control form-control-sm"></textArea>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-4">
                                                                    <div className="col">
                                                                        <label htmlFor="support-baker">Support Contact</label>
                                                                        <input type="text" id='support-baker' className='form-control form-control-sm' />
                                                                    </div>
                                                                    <div className="col">
                                                                        <label htmlFor="app-link-baker">App Link</label>
                                                                        <input type="text" id='app-link-baker' className='form-control form-control-sm' />
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-4">
                                                                    <div className="col">
                                                                        <label htmlFor="tutorial-baker">Tutorial Link</label>
                                                                        <input type="text" id='tutorial-baker' className='form-control form-control-sm' />
                                                                    </div>
                                                                    <div className="col">
                                                                        <label htmlFor="shop-reg-message-baker">Shop Reg. Message</label>
                                                                        <input type="text" id='shop-reg-message-baker' className='form-control form-control-sm' />
                                                                    </div>
                                                                </div>
                                                                <div className="row gx-0">
                                                                    <div className="col-3 ms-auto">
                                                                        <button className={`btn-custom ${this.state.isDisable ? "disable-btn" : "action-btn-1"} w-100`} disabled={this.state.isDisable} onClick={() => this.bakerSettingUpdate()}>
                                                                            {this.state.isDisable ?
                                                                                <Spinner
                                                                                    as="span"
                                                                                    animation="border"
                                                                                    size="sm"
                                                                                    role="status"
                                                                                    aria-hidden="true"
                                                                                /> : "Update"}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div></>
                                                    :
                                                    <>
                                                        <div className="text-center"><h3 style={{ borderBottom: "4px solid teal" }}>Edit Customer Setting</h3></div>
                                                        <div className="row g-0">
                                                            <div className="col-md-12">
                                                                <input type="text" className='d-none' id='id-customer' />
                                                                <div className="row mb-4">
                                                                    <div className="col">
                                                                        <label htmlFor="version-customer">Version</label>
                                                                        <input disabled type="text" id='version-customer' className='form-control form-control-sm' />
                                                                    </div>
                                                                    <div className="col">
                                                                        <label htmlFor="version-status-customer">Version Status</label>
                                                                        <select name="" id="version-status-customer" className='form-control form-control-sm'>
                                                                            <option value="1">Active</option>
                                                                            <option value="0">Inactive</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-4">
                                                                    <div className="col">
                                                                        <label htmlFor="pop-text-customer">Pop-Up Text</label>
                                                                        <input type="text" id='pop-text-customer' className='form-control form-control-sm' />
                                                                    </div>
                                                                    <div className="col">
                                                                        <label htmlFor="pop-text-status-customer">Pop-Up Text Status</label>
                                                                        <select name="" id="pop-text-status-customer" className='form-control form-control-sm'>
                                                                            <option value="1">Active</option>
                                                                            <option value="0">Inactive</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="row ">
                                                                    <div className="col">
                                                                        <label htmlFor="">Pop-Up Image :&nbsp;&nbsp;&nbsp;</label>
                                                                        <input id="pop-image-customer" type="file" className="form-control form-control-sm mb-2" onChange={this.fileOnChangeCustomer} />
                                                                        <img id="pic-preview-customer" style={{ maxHeight: 120, width: 'auto', borderRadius: 5 }} alt="" />
                                                                    </div>
                                                                    <div className="col">
                                                                        <label htmlFor="pop-image-status-customer">Pop-Up Image Status</label>
                                                                        <select name="" id="pop-image-status-customer" className='form-control form-control-sm'>
                                                                            <option value="1">Active</option>
                                                                            <option value="0">Inactive</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className='row mb-4'>
                                                                    <div className="col">
                                                                        <label htmlFor="message-customer">Message</label>
                                                                        <textArea id="message-customer" rows="4" className="form-control form-control-sm"></textArea>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-4">
                                                                    <div className="col">
                                                                        <label htmlFor="support-customer">Support Contact</label>
                                                                        <input type="text" id='support-customer' className='form-control form-control-sm' />
                                                                    </div>
                                                                    <div className="col">
                                                                        <label htmlFor="app-link-customer">App Link</label>
                                                                        <input type="text" id='app-link-customer' className='form-control form-control-sm' />
                                                                    </div>
                                                                </div>
                                                                <div className="row gx-0">
                                                                    <div className="col-3 ms-auto">
                                                                        <button className={`btn-custom ${this.state.isDisable ? "disable-btn" : "action-btn-1"} w-100`} disabled={this.state.isDisable} onClick={() => this.customerSettingUpdate()}>
                                                                            {this.state.isDisable ?
                                                                                <Spinner
                                                                                    as="span"
                                                                                    animation="border"
                                                                                    size="sm"
                                                                                    role="status"
                                                                                    aria-hidden="true"
                                                                                /> : "Update"}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                            </Modal.Body>
                                            <Modal.Footer className='d-none'>
                                                <Button className='btn-custom action-btn-2' onClick={() => this.hideUpdateModal()}>
                                                    Close
                                                </Button>
                                                <Button className='btn-custom action-btn-1' onClick={() => this.hideUpdateModal()}>
                                                    Ok
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
