import React, { Component } from 'react'

export default class CountCard extends Component {
    render() {
        return (
            <>
                <div className="col text-center text-white p-3 mb-2" style={{ borderRadius: 20, backgroundColor: this.props.bgColor }}>
                    <div className="d-flex justify-content-between align-items-center">
                        <i className={"fa " + this.props.icon + " fa-2x mb-2"}></i>
                        <h3 className="timer count-title count-number mb-1">{this.props.countData}</h3>
                    </div>
                    <h6 className="m-0 count-text ">{this.props.title}</h6>
                </div>
            </>
        )
    }
}
