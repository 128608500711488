import React, { Component } from 'react'
import { TbSearch } from 'react-icons/tb';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { postApi } from '../api/call.api';
import urlApi from '../api/url.api';
import Navbar from '../components/Navbar';
import SidebarMenu from '../components/SidebarMenu';
import { dateTimeConverter } from '../utils/dateTimeConverter';

export default class UsedCoupon extends Component {
    constructor(p) {
        super(p);
        this.state = {
            isOpen: false,
            tableData: [],
            pagination: ({
                active_page: 0,
                per_page: 10,
                total_items: 0
            }),
            page: 1,
            count: 10,
        }
    }

    menuBarClick() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    alert = (msg, responseCode) => {
        if (responseCode === "200") {
            toast.success(msg);
        }
        else if (responseCode === "500") {
            toast.error(msg);
        }
        else {
            toast.info(msg);
        }
    }

    async resetTable() {
        await this.setState({ tableData: [] })
        await this.setState({
            pagination: ({
                active_page: 0,
                per_page: 10,
                total_items: 0
            })
        })
    }

    componentDidMount() {
        this.allUsedCouponList();
    }

    async allUsedCouponList() {
        let postData = {
            "search_data": document.getElementById("search-field").value,
            "status": document.getElementById("status-dropdown").value,
            "coupon_id": this.props.match.params.couponId,
        }
        let resp = await postApi(postData, urlApi.usedCouponList + this.state.page);
        if (resp.responsecode === "200") {
            this.setState({ tableData: resp.data.data });
            this.setState({ pagination: resp.data.pagination });
        }
        else {
            this.resetTable();
            this.alert(resp.message, "500");
        }
    }

    async handlePageChange(pageNumber) {
        await this.setState({ page: pageNumber });
        this.allUsedCouponList();
    }

    async onSearch() {
        await this.setState({ page: 1 }, () => this.allUsedCouponList());
    }

    render() {
        var x = (this.state.pagination.active_page - 1) * this.state.pagination.per_page + 1;
        return (
            <>
                <div className="container-fluid position-relative bg-white d-flex p-0">
                    <SidebarMenu isOpen={this.state.isOpen} />
                    <div className={this.state.isOpen ? "content open" : "content"}>
                        <Navbar menuBarClick={() => this.menuBarClick()} />
                        <div className="container-fluid">
                            <ToastContainer autoClose={1000} />
                            <div className="row g-4">
                                <div className="col-12 px-0">
                                    <div className="rounded h-100 p-2">
                                        <div className="d-flex justify-content-between pe-4">
                                            <h6 className="mb-4">Coupon : {this.props.match.params.couponCode} || Uses : {this.state.pagination.total_items}</h6>
                                        </div>
                                        <div className="col-12 mb-2">
                                            <form onSubmit={(e) => { e.preventDefault(); this.onSearch() }}>
                                                <div className="row gx-1 search-section">
                                                    <div className="col-md-3 col-6">
                                                        <input className='form-control form-control-sm' id='search-field' placeholder='Mobile/Order ID/Product ID' />
                                                    </div>
                                                    <div className="col-md-3 col-4">
                                                        <select className='form-control form-control-sm w-100' defaultValue={""} id='status-dropdown'>
                                                            <option value="">All Coupons</option>
                                                            <option value="1">Active Coupons</option>
                                                            <option value="0">Inactive Coupons</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2 col-2">
                                                        <button className='btn-custom action-btn-5 w-sm-100' type='submit'><TbSearch size={20} /></button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="table-responsive">
                                            {this.state.tableData.length > 0 ?
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="col">#</th>
                                                            <th className="col">User</th>
                                                            <th className="col">Baker</th>
                                                            <th className="col">Order ID</th>
                                                            <th className="col">Product</th>
                                                            <th className="col">Status</th>
                                                            <th className="col">Created</th>
                                                            <th className="col">Modified</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.tableData.map((data,index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <th scope="row">{x++}</th>
                                                                    <td className='text-left'>
                                                                        {data.user_id === 0 ? "--" : <Link to={"/single-customer-view/userDetails/" + data.user_id} target="_blank">
                                                                            <span className='table-img'><img src={data.profile_pic} alt="" /></span><br />
                                                                            <span>{data.customer_name}</span>
                                                                        </Link>}
                                                                    </td>
                                                                    <td className='text-left'>
                                                                        {data.baker_id === 0 ? "--" : <Link to={"/single-shop-view/shopDetails/" + data.baker_id} target="_blank">
                                                                            <span className='table-img'><img src={data.shop_image} alt="" /></span><br />
                                                                            <span>{data.shop_name}</span>
                                                                        </Link>}
                                                                    </td>
                                                                    <td>{data.order_id}</td>
                                                                    <td className='text-left'>
                                                                        <span className='table-img'>
                                                                            <img src={data.picture} alt="" />
                                                                        </span><br />
                                                                        <span>{data.name}</span>
                                                                    </td>
                                                                    <td>{data.status}</td>
                                                                    <td>[{dateTimeConverter(data.create_date)}]</td>
                                                                    <td>[{dateTimeConverter(data.modified_date)}]</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                                :
                                                <div className="text-center">
                                                    <h4 className="text-danger">No Data Found!</h4>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='me-2' style={{ float: 'right', display: this.state.tableData.length > 0 ? 'unset' : 'none' }}>
                                <Pagination
                                    activePage={parseInt(this.state.pagination.active_page)}
                                    itemsCountPerPage={this.state.pagination.per_page}
                                    totalItemsCount={this.state.pagination.total_items}
                                    pageRangeDisplayed={5}
                                    onChange={this.handlePageChange.bind(this)}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
