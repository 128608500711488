import React, { Component } from 'react'
import "../css/component.css"
import { TbSquareDot } from 'react-icons/tb'
import { getApi } from '../api/call.api';
import urlApi from '../api/url.api';

export default class ProductComponent extends Component {
    constructor(p){
        super(p);
        this.state ={
            staticCategoryList:[]
        }
    }
    componentDidMount(){
        this.getStaticProductCategoryList();
    }
    async getStaticProductCategoryList() {
        let res = await getApi(urlApi.productCategoryList);
        if (res.responsecode === "200") {
            this.setState({ staticCategoryList: res.data })
        }
    }

    getCategoryNameFromID(categoryID) {
        for (const data of this.state.staticCategoryList) {
            if (categoryID === data.product_category_id) {
                return data.product_category_name;
            }
        }
    }
    render() {
        return (
            <>
                <div className="container-fluid mb-2" onClick={this.props.onClick} style={{ cursor: this.props.cursor ?? "default" }}>
                    <div className="row product-wrapper">
                        <div className="col-md-8">
                            <div className="row">
                                <p className='m-0'>ID : <span className='fw-bold'>{this.props.data.product_id}</span></p>
                                <div className="col-4 product-preference">
                                    {this.props.data.preference !== 3 ?
                                        <TbSquareDot color={this.props.data.preference === 2 ? 'red' : this.props.data.preference === 1 ? 'green' : 'grey'} /> :
                                        <span>
                                            <TbSquareDot color='red' />
                                            <TbSquareDot color='green' />
                                        </span>}
                                </div>
                                <div className="px-0 col d-flex align-items-end product-category">{ this.getCategoryNameFromID(this.props.data.category_id)}</div>
                            </div>
                            <p className='m-0 p-0 product-name'>{this.props.data.name}</p>
                            <div className="row">
                                <div className="col-3 text-bottom product-offer-price">₹{this.props.data.offer_price}</div>
                                <div className="col px-md-0 align-items-center product-price" style={{display:this.props.data.offer_price === this.props.data.price ? 'none':'flex'}}>₹{this.props.data.price}</div>
                            </div>
                            <p className='m-0 p-0 product-short-description'>{this.props.data.short_desc}</p>
                            <p className={`m-0 p-0 product-status`}><span className={`badge bg-${this.props.data.status === 1?"success":this.props.data.status === 2?"danger":"secondary"}`}>{this.props.data.status === 1?"Active":this.props.data.status === 2?"Deleted":"Inactive"}</span></p>
                        </div>
                        <div className="col-md-4 d-flex align-items-center justify-content-center product-image">
                            <img src={this.props.data.picture} alt="" />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
