import React, { Component } from 'react'
import { BiCamera, BiDownload, BiMicrophone, BiSend } from 'react-icons/bi';
import ModalImage from "react-modal-image";
import ScrollToBottom from 'react-scroll-to-bottom';
import { dateTimeConverter } from '../utils/dateTimeConverter';

export default class ChatView extends Component {
    render() {
        return (
            <>
                <ScrollToBottom className="chat-view-wrapper" behavior='smooth' followButtonClassName="btt-button">
                    <div className="chat-view">

                        <div className="col-12">
                            {this.props.chatUserData.length > 0 ?
                                this.props.chatMsgList.map((data,index) => {
                                    return (<>
                                        <div key={index} className={data.sender_id === this.props.chatUserData[0].chat_user_id ? "opponent-chat" : "my-chat"}>
                                            {data.type === 1 ?
                                                <>
                                                    <p className='m-0'>{data.msg} </p>
                                                    <p className='m-0 ms-auto text-end' style={{ fontSize: 10 }}>{data.create_date.includes('T')?dateTimeConverter(data.create_date):data.create_date}</p>
                                                </>
                                                : data.type === 2 ?
                                                    <>
                                                        <ModalImage small={data.msg} large={data.msg} alt="" />
                                                        <p className='m-0 ms-auto text-end' style={{ fontSize: 10 }}>{data.create_date.includes('T')?dateTimeConverter(data.create_date):data.create_date}</p>
                                                    </>
                                                    : data.type === 3 ?
                                                        <>
                                                            <audio controls>
                                                                <source src={data.msg} />
                                                            </audio>
                                                            <p className='m-0 ms-auto text-end' style={{ fontSize: 10 }}>{data.create_date.includes('T')?dateTimeConverter(data.create_date):data.create_date}</p>
                                                        </>
                                                        : data.type === 4 ?
                                                            <>
                                                                <a href={data.msg}>File <BiDownload /></a>
                                                                <p className='m-0 ms-auto text-end' style={{ fontSize: 10 }}>{data.create_date.includes('T')?dateTimeConverter(data.create_date):data.create_date}</p>
                                                            </> : ""
                                            }
                                        </div>
                                        <br />
                                    </>
                                    )
                                })
                                : "No Data!"}
                        </div>
                    </div>
                </ScrollToBottom>

                <div className="col-12 px-2 d-none">
                    <div className="row pe-3" style={{ height: '5vh', bottom: 0, width: '100%', padding: '8px 0px' }}>
                        <div className="col pe-0">
                            <input autoFocus type="text" style={{ height: 20 }} className='form-control form-control-sm' />
                        </div>
                        <div className="col-1 me-1">
                            <button className='icon-btn-1' style={{ backgroundColor: '#f08632', color: 'white' }}><BiMicrophone size={18} /></button>
                        </div>
                        <div className="col-1 me-1">
                            <button className='icon-btn-1' style={{ backgroundColor: '#f08632', color: 'white' }}><BiCamera size={18} /></button>
                        </div>
                        <div className="col-1 me-1">
                            <button className='icon-btn-1' style={{ backgroundColor: '#f08632', color: 'white' }}><BiSend size={18} /></button>
                        </div>

                    </div>
                </div>
            </>
        )
    }
}
