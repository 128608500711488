import React, { Component } from 'react'
import { HiOutlineLocationMarker } from 'react-icons/hi'

export default class AddressComponent extends Component {
    constructor(p) {
        super(p);
        this.state = {
        }
    }

    render() {
        return (
            <>
                <div className="container-fluid mb-2" style={{ backgroundColor: "#f0f0f0", borderRadius: 8, padding: "5px 10px", border: this.props.isDefault ? "2px solid #f08632" : "#f0f0f0" }}>
                    <div className="row d-flex align-items-center">
                        <div className="col-1">
                            <HiOutlineLocationMarker />
                        </div>
                        <div className="col">
                            <div className="address-name">{this.props.name}</div>
                            <div className="address-mobile">Mobile : {this.props.mobile}</div>
                            <div className="address-details">{this.props.addressDetails}</div>
                            <div className="address-pin">Pin-{this.props.pinCode}</div>
                            <div className="address-landmark">{this.props.landmark}</div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-end">
                        <div className="col-3" style={{ display: this.props.btnText1 ? 'unset' : 'none' }}>
                            <button className='btn-custom action-btn-1 w-100' onClick={this.props.function1}>{this.props.btnText1 ?? "Delete"}</button>
                        </div>
                        <div className="col-3" style={{ display: this.props.btnText2 ? 'unset' : 'none' }}>
                            <button onClick={this.props.btn2Function} className='btn-custom action-btn-5 w-100'>{this.props.btnText2 ?? "Edit"}</button>
                        </div>
                        <div className="col-3" style={{ display: this.props.btnText3 ? 'unset' : 'none' }}>
                            <button className='btn-custom action-btn-2 w-100' onClick={this.props.setDefaultFunction}>{this.props.btnText3 ?? "Set Default"}</button>
                        </div>
                    </div>
                </div>
                
            </>
        )
    }
}
