import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class TopCustomerCard extends Component {
    render() {
        return (
            <>
                <div className="p-2 d-flex justify-content-between align-items-center text-center border border-1" style={{borderRadius:15}}>
                    <img src={this.props.customerImg} className="" alt="" style={{ width: 100, height: 100, borderRadius: 15 ,objectFit:'cover'}} />
                    <div>
                        <Link to={"/single-customer-view/userDetails/" + this.props.customerId} target="_blank">
                            <h5>{this.props.customerName}</h5>
                        </Link>
                        <p className='m-0'>Ordered {this.props.count} Times</p>
                    </div>
                </div>
            </>
        )
    }
}
