import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import SidebarMenu from '../components/SidebarMenu'
import { TbSearch, TbSquareDot } from 'react-icons/tb';
import ProductDetailsModalComponent from '../components/ProductDetailsModalComponent';
import { Offcanvas } from 'react-bootstrap';
import ChatView from './ChatView';
import Pagination from 'react-js-pagination';
import { toast, ToastContainer } from 'react-toastify';
import { postApi } from '../api/call.api';
import urlApi from '../api/url.api';
import image from '../assets/images/profilePic.jpg'
import ConfirmationModal from '../components/ConfirmationModal';
import { dateTimeConverter } from '../utils/dateTimeConverter';
import { GrPowerReset } from 'react-icons/gr';
import { BsArrowLeftRight } from 'react-icons/bs';
import PubSub from 'pubsub-js';
import socketService from '../api/socketConnect';


export default class AllOrder extends Component {
  constructor(p) {
    super(p);
    this.state = {
      isOpen: false,
      productDetailsModalShow: false,
      sideBarShow: false,
      tableData: [],
      pagination: ({
        active_page: 0,
        per_page: 10,
        total_items: 0
      }),
      page: 1,
      count: 10,
      singleProductSDetail: [],
      statusUpdateModalShow: false,
      chatDetails: null,
      allAddonDecorList: [],
      chatUserData: [],
      chatMsgList: [],
    }
    this.chatDetails = [];
  }

  menuBarClick() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  async productDetailsViewModalShow(data) {
    await this.setState({ allAddonDecorList: [] });
    this.setState({ singleProductSDetail: data });
    await this.setState({ allAddonDecorList: [...this.state.allAddonDecorList, ...[...data.add_on_details]] });
    await this.setState({ allAddonDecorList: [...this.state.allAddonDecorList, ...[...data.decor_details]] });
    await this.setState({ productDetailsModalShow: true });
  }

  productDetailsViewModalHide() {
    this.setState({ productDetailsModalShow: false });
  }

  showSideBar(data) {
    this.currentChatId = data.chat_id;
    this.setState({ sideBarShow: true }, () => {
      this.getChatDetails((data.chat_id).toString());
      this.getOldMessage((data.chat_id).toString());
    });
  }

  hideSideBar() {
    document.getElementById("chat-name").innerHTML = null;
    this.setState({ sideBarShow: false })
  }

  async handlePageChange(pageNumber) {
    await this.setState({ page: pageNumber });
    this.getAllOrderList();
  }

  async resetTable() {
    await this.setState({ tableData: [] })
    await this.setState({
      pagination: ({
        active_page: 0,
        per_page: 10,
        total_items: 0
      })
    })
  }

  alert = (msg, responseCode) => {
    if (responseCode === "200") {
      toast.success(msg);
    }
    else if (responseCode === "500") {
      toast.error(msg);
    }
    else {
      toast.info(msg);
    }
  }

  dateFilterVar = "";

  async getAllOrderList({ dateFilter = this.dateFilterVar } = {}) {
    this.dateFilterVar = dateFilter;
    this.setState({ tableData: [] });
    var postData = {
      "search_data": document.getElementById("search-field").value,
      "status": document.getElementById("status-dropdown").value,
      "date": dateFilter,
      "fromDate": document.getElementById("from-date-filter").value,
      "toDate": document.getElementById("to-date-filter").value
    }
    let resp = await postApi(postData, urlApi.allOrders + this.state.page);
    if (resp.responsecode === "200") {
      this.setState({ tableData: resp.data.data });
      this.setState({ pagination: resp.data.pagination });
    }
    else {
      this.resetTable();
    }
  }

  async resetFields() {
    document.getElementById("from-date-filter").value = "";
    document.getElementById("to-date-filter").value = "";
    document.getElementById("search-field").value = "";
    document.getElementById("status-search").value = "0";
    this.getAllOrderList({ dateFilter: "" });
  }

  showStatusUpdateModal(data, status) {
    this.bakerID = data.baker_id;
    this.orderID = data.order_id;
    this.status = status;
    this.setState({ statusUpdateModalShow: true });
  }

  hideStatusUpdateModal() {
    this.setState({ statusUpdateModalShow: false })
  }

  async updateOrderStatus(status, orderID, bakerID) {
    let postData = {
      "status": status,
      "order_id": orderID,
      "baker_id": bakerID
    }
    let resp = await postApi(postData, urlApi.orderStatusUpdate);
    this.hideStatusUpdateModal();
    if (resp.responsecode === "200") {
      this.getAllOrderList();
      this.alert(resp.message, resp.responsecode);
    }
  }

  async onSearch() {
    await this.setState({ page: 1 }, () => this.getAllOrderList());
  }

  componentDidMount() {
    this.getAllOrderList();

    PubSub.subscribe("CHAT DETAILS", (msg, data) => {
      this.chatDetails = data.data.chat_details;
      this.setState({ chatDetails: data.data });
      this.setState({ chatUserData: data.data.chat_user });
    });

    PubSub.subscribe("MSG LIST RCV", (msg, data) => {
      this.setState({ chatMsgList: data.data.message_list.reverse() });
    });

    PubSub.subscribe("MSG RCV", (msg, data) => {
      if (this.currentChatId === data.chat_id) {
        let newList = [...this.state.chatMsgList, data];
        this.setState({ chatMsgList: newList });
      }
    });
  }

  componentWillUnmount() {
    PubSub.unsubscribe("CHAT DETAILS");
    PubSub.unsubscribe("MSG LIST RCV");
    PubSub.unsubscribe("MSG RCV");
  }

  async getChatDetails(chat_id) {
    this.chatDetails = [];
    this.setState({ chatUserData: [] });
    let postData = {
      "chat_id": chat_id
    }
    socketService.emitFunc("chat-fetch", JSON.stringify(postData));
  }

  async getOldMessage(chat_id) {
    this.setState({ chatMsgList: [] });
    let postData = {
      "chat_id": chat_id
    }
    socketService.emitFunc("msg-list-fetch", JSON.stringify(postData));
    socketService.emitFunc("join-admin", JSON.stringify(postData));
  }

  render() {
    var x = (this.state.pagination.active_page - 1) * this.state.pagination.per_page + 1;
    return (
      <>
        <div className="container-fluid position-relative bg-white d-flex p-0">
          <SidebarMenu isOpen={this.state.isOpen} />
          <div className={this.state.isOpen ? "content open" : "content"}>
            <Navbar menuBarClick={() => this.menuBarClick()} />
            <div className="container-fluid">
              <ToastContainer autoClose={1000} />
              <div className="row g-4">
                <div className="col-12 px-0">
                  <div className="rounded h-100 p-2">
                    <h6 className="mb-2">Order Details || Total : {this.state.pagination.total_items}</h6>
                    <div className="row mb-2 gx-2">
                      <div className="col-md-1 col mb-1">
                        <button className='btn-custom action-btn-5 w-100' onClick={() => this.getAllOrderList({ dateFilter: "" })}>All</button>
                      </div>
                      <div className="col-md-1 col mb-1">
                        <button className='btn-custom action-btn-1 w-100' onClick={() => this.getAllOrderList({ dateFilter: "DAILY" })}>Today</button>
                      </div>
                      <div className="col-md-1 col mb-1">
                        <button className='btn-custom action-btn-4 w-100' onClick={() => this.getAllOrderList({ dateFilter: "YESTERDAY" })}>Yesterday</button>
                      </div>
                      <div className="col-md-1 col mb-1">
                        <button className='btn-custom action-btn-3 w-100 text-nowrap' onClick={() => this.getAllOrderList({ dateFilter: "WEEKLY" })}>Last Week</button>
                      </div>
                      <div className="col-md-1 col mb-1">
                        <button className='btn-custom action-btn-2 w-100 text-nowrap' onClick={() => this.getAllOrderList({ dateFilter: "MONTHLY" })}>Last Month</button>
                      </div>
                    </div>
                    <div className="row gx-2 mb-2">
                      <div className="col-md-2 col-5 mb-2">
                        <input type="date" className='form-control form-control-sm w-100' id='from-date-filter' />
                      </div>
                      <div className="col-md-1 mb-2 col text-center"> <BsArrowLeftRight /> </div>
                      <div className="col-md-2 col-5 mb-2">
                        <input type="date" className='form-control form-control-sm w-100' id='to-date-filter' />
                      </div>
                      <div className="col-md-1 col">
                        <button className='btn-custom action-btn-1 w-100' onClick={() => this.getAllOrderList({ dateFilter: "CUSTOM" })}>Search</button>
                      </div>
                      <div className="col-md-2 col-2">
                        <button className='btn-custom action-btn-5 w-sm-100' onClick={() => this.resetFields()}><GrPowerReset size={20} /></button>
                      </div>
                    </div>
                    <div className="col-12 mb-2">
                      <form onSubmit={(e) => { e.preventDefault(); this.onSearch() }}>
                        <div className="row gx-1 search-section">
                          <div className="col-md-3 col-6">
                            <input className='form-control form-control-sm w-100' id='search-field' placeholder='Order ID' />
                          </div>
                          <div className="col-md-3 col-4">
                            <select id='status-dropdown' className='form-control form-control-sm w-100' defaultValue={6} style={{ width: 80 }}>
                              <option value={6}>ALL Orders</option>
                              <option value={0}>Pending</option>
                              <option value={1}>Accepted</option>
                              <option value={2}>Rejected</option>
                              <option value={3}>Chat</option>
                              <option value={4}>Finalized</option>
                              <option value={5}>Delivered</option>
                            </select>
                          </div>
                          <div className="col-md-2 col-2">
                            <button className='btn-custom action-btn-5 w-sm-100' type='submit'><TbSearch size={20} /></button>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="table-responsive">
                      {this.state.tableData.length > 0 ?
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="col">#</th>
                              <th className="col">Order ID</th>
                              <th className="col">Product Name</th>
                              <th className="col">Price</th>
                              <th className="col">Shop Name</th>
                              <th className="col">Customer Name</th>
                              <th className="col">Address</th>
                              <th className="col">Customer Note</th>
                              <th className="col">Baker Note</th>
                              <th className="col">Delivery</th>
                              <th className="col">Status</th>
                              <th className="col">Created</th>
                              <th className="col">Modified</th>
                              <th className="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.tableData.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <th scope="row">{x++}</th>
                                  <td>1000{data.order_id}</td>
                                  <td className='text-left'>
                                    <span className='table-img'><img src={data.picture} alt="" /></span><br />
                                    <span>
                                      {data.preference !== 3 ?
                                        <TbSquareDot color={data.preference === 2 ? 'red' : data.preference === 1 ? 'green' : 'grey'} /> :
                                        <span><TbSquareDot color='red' />
                                          <TbSquareDot color='green' />
                                        </span>}
                                      {data.name}</span>
                                      <span className='fw-bold'> X {data.quantity}</span>
                                  </td>
                                  <td>₹{data.price - data.coupon_value} <br /> <span style={{ display: data.coupon_code !== "NONE" ? 'unset' : 'none' }}>Coupon : {data.coupon_code}</span> </td>
                                  <td className='text-left'>
                                    <Link to={"/single-shop-view/" + data.baker_id} target="_blank">
                                      <span className='table-img'><img src={data.shop_image} alt="" /></span><br />
                                      <span>[{data.baker_id}] {data.shop_name}</span>
                                    </Link>
                                  </td>
                                  <td className='text-left'>
                                    <Link to={"/single-customer-view/" + data.customer_id} target="_blank">
                                      <span className='table-img'><img src={data.customer_pic} onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = image;
                                      }} alt="" /></span><br />
                                      <span>[{data.customer_id}] {data.customer_name}</span>
                                    </Link>
                                  </td>
                                  <td>{data.address_details.address_details}</td>
                                  <td>{data.customer_note ?? "[NA]"}</td>
                                  <td>{data.baker_note ?? "[NA]"}</td>
                                  <td>{dateTimeConverter(data.delivery_time)}</td>
                                  <td>
                                    <select value={data.order_status} onChange={(e) => this.showStatusUpdateModal(data, e.target.value)} className={`form-control fw-bold form-control-sm text-${(data.order_status === 0 || data.order_status === 3) ? "dark" : "white"} bg-${data.order_status === 0 ? "info" : data.order_status === 1 ? "success" : data.order_status === 2 ? "danger" : data.order_status === 3 ? "warning" : data.order_status === 4 ? "primary" : "secondary"}`} style={{ width: 80 }}>
                                      <option value={0}>Pending</option>
                                      <option value={1}>Accepted</option>
                                      <option value={2}>Rejected</option>
                                      <option value={3}>Chat</option>
                                      <option value={4}>Finalized</option>
                                      <option value={5}>Delivered</option>
                                    </select>
                                  </td>
                                  <td>{dateTimeConverter(data.create_date)}</td>
                                  <td>
                                    <span>{dateTimeConverter(data.modify_date)}</span><br />
                                    <span>{data.modified_by === data.baker_id ? data.shop_name : data.customer_name}</span>
                                  </td>
                                  <td>
                                    <button style={{ display: data.order_status === 3 ? 'unset' : 'unset' }} className='btn-custom action-btn-2 m-1' onClick={() => this.showSideBar(data)}>Chat</button>
                                    <button className='btn-custom action-btn-4 m-1' onClick={() => this.productDetailsViewModalShow(data)}>Product Details</button>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                        :
                        <div className="col-12">
                          <h4 className='text-danger text-center'>No Data Found</h4>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className='me-2' style={{ float: 'right', display: this.state.tableData.length > 0 ? 'unset' : 'none' }}>
                <Pagination
                  activePage={parseInt(this.state.pagination.active_page)}
                  itemsCountPerPage={this.state.pagination.per_page}
                  totalItemsCount={this.state.pagination.total_items}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </div>
          </div>
        </div>
        <ProductDetailsModalComponent
          show={this.state.productDetailsModalShow}
          productData={this.state.singleProductSDetail}
          addonDecorData={this.state.allAddonDecorList}
          productDetailsViewModalHide={() => this.productDetailsViewModalHide()}
        />

        <Offcanvas show={this.state.sideBarShow} onHide={() => this.hideSideBar()} placement={'end'}>
          <Offcanvas.Header closeButton style={{ height: 72 }}>
            <Offcanvas.Title>
              <span className='table-img'>
                <img src={this.chatDetails.chat_logo} alt="" />
              </span>
              <span id='chat-name'>{this.chatDetails.chat_name}</span>
            </Offcanvas.Title>

          </Offcanvas.Header>
          <Offcanvas.Body className='p-0'>
            {this.state.chatUserData.length > 0 ?
              <div className="col-12 mb-1 d-flex justify-content-between">
                <div className="px-2" style={{ display: this.state.chatUserData.length > 0 ? "unset" : "none" }}>
                  <img src={this.state.chatUserData.length > 0 ? this.state.chatUserData[0].user_pic : "ProductImage"} alt="" style={{ height: 30, width: 30, objectFit: 'cover', borderRadius: 50 }} />
                  {this.state.chatUserData.length > 0 ? this.state.chatUserData[0].user_name : ""}
                </div>
                <div className="" style={{ display: this.state.chatUserData.length > 1 ? "unset" : "none" }}>
                  <img src={this.state.chatUserData.length > 1 ? this.state.chatUserData[1].user_pic : "ProductImage"} alt="" style={{ height: 30, width: 30, objectFit: 'cover', borderRadius: 50 }} />
                  {this.state.chatUserData.length > 1 ? this.state.chatUserData[1].user_name : ""}
                </div>
              </div>
              :
              <div className="col-12">
                No One Joined The Chat Yet!
              </div>
            }
            <ChatView
              chatUserData={this.state.chatUserData}
              chatMsgList={this.state.chatMsgList}
            />
          </Offcanvas.Body>
        </Offcanvas>

        <ConfirmationModal
          show={this.state.statusUpdateModalShow}
          headerText="Update Status"
          bodyText="Are You Sure Want To Update Status?"
          closeFunction={() => this.hideStatusUpdateModal()}
          okFunction={() => this.updateOrderStatus(this.status, this.orderID, this.bakerID,)}
        />

      </>
    )
  }
}
