import React, { Component } from 'react'
import urlApi from '../api/url.api';
import { postApi } from '../api/call.api'
import Navbar from '../components/Navbar'
import SidebarMenu from '../components/SidebarMenu'
import { toast, ToastContainer } from 'react-toastify';

export default class Notification extends Component {
    constructor(p) {
        super(p);
        this.state = {
            isOpen: false,
            showUserField: false,
            picUpload: '',
            isComplete: true
        }
    }

    menuBarClick() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    fileOnChange = async (e) => {
        await this.setState({ picUpload: e.target.files[0] });
        document.getElementById("pic-preview").src = URL.createObjectURL(e.target.files[0]);
    }

    selectUser(value) {
        if (value === "SINGLE_CUSTOMER" || value === "SINGLE_SHOP") {
            this.setState({ showUserField: true });
        }
        else {
            this.setState({ showUserField: false });
        }
    }

    alert = (msg, responseCode) => {
        if (responseCode === "200") {
            toast.success(msg);
        }
        else if (responseCode === "500") {
            toast.error(msg);
        }
        else {
            toast.info(msg);
        }
    }

    async sendNotification() {
        this.setState({ isComplete: false });
        if (this.state.picUpload) {
            const formData = new FormData();
            formData.append(
                "file_data", this.state.picUpload,
            );
            let respPic = await postApi(formData, urlApi.notificationPicUpload);
            if (respPic.responsecode === "200") {
                let postData = {
                    "type": document.getElementById('type').value,
                    "title": document.getElementById('title').value,
                    "msg": document.getElementById('message').value,
                    "user_id": document.getElementById('customer-id').value,
                    "image": respPic.data,
                }
                let resp = await postApi(postData, urlApi.sendNotification);
                this.setState({ isComplete: true });
                if (resp.responsecode === '200') {
                    this.alert(resp.message, resp.responsecode);
                    document.getElementById('type').value = 'ALL';
                    document.getElementById('title').value = null;
                    document.getElementById('message').value = null;
                    document.getElementById("pic-preview").src = null;
                    document.getElementById('customer-id').value = null;
                } else {
                    this.alert(resp.message, resp.responsecode);
                }
            } else {
                this.alert(respPic.message, respPic.responsecode);
            }
        }
        else {
            let postData = {
                "type": document.getElementById('type').value,
                "title": document.getElementById('title').value,
                "msg": document.getElementById('message').value,
                "user_id": document.getElementById('customer-id').value,
                "image": this.state.picUpload.toString(),
            }
            let resp = await postApi(postData, urlApi.sendNotification);
                this.setState({ isComplete: true });
            if (resp.responsecode === '200') {
                this.alert(resp.message, resp.responsecode);
                document.getElementById('type').value = 'ALL';
                document.getElementById('title').value = null;
                document.getElementById('message').value = null;
                document.getElementById("pic-preview").src = null;
                document.getElementById('customer-id').value = null;
            } else {
                this.alert(resp.message, resp.responsecode);
            }
        }
    }

    render() {
        return (
            <>
                <div className="container-fluid position-relative bg-white d-flex p-0">
                    <SidebarMenu isOpen={this.state.isOpen} />
                    <div className={this.state.isOpen ? "content open" : "content"}>
                        <Navbar menuBarClick={() => this.menuBarClick()} />
                        <div className="container-xxl">
                            <ToastContainer autoClose={1000} />
                            <div className="row g-4">
                                <div className="col-2"></div>
                                <div className="col-md-6">
                                    <h4>Notification</h4>
                                    <div className="form-group mb-2">
                                        <label htmlFor="">Title</label>
                                        <input id='title' className='form-control' />
                                    </div>
                                    <div className="form-group mb-2">
                                        <label htmlFor="">Message</label>
                                        <textarea id='message' className='form-control'></textarea>
                                    </div>
                                    <div className="form-group mb-2">
                                        <label htmlFor="">Picture :&nbsp;&nbsp;&nbsp;</label>
                                        <img id="pic-preview" style={{ maxHeight: 80, width: 'auto', borderRadius: 5 }} alt="" />
                                    </div>
                                    <div className="input-group mb-2">
                                        <input id="edit-photo" type="file" className="form-control form-control-sm" onChange={this.fileOnChange} />
                                    </div>
                                    <div className="form-group mb-2">
                                        <label htmlFor="">Send To</label>
                                        <select id='type' className='form-control' onChange={(e) => this.selectUser(e.target.value)}>
                                            <option value='ALL'>All (Shop + Customer)</option>
                                            <option value="ALL_SHOP">All Shop</option>
                                            <option value="SINGLE_SHOP">Single Shop</option>
                                            <option value="ALL_CUSTOMER">All Customer</option>
                                            <option value="SINGLE_CUSTOMER">Single Customer</option>
                                        </select>
                                    </div>
                                    <div className="form-group mb-2" style={{ display: this.state.showUserField ? "unset" : "none" }}>
                                        <label htmlFor="">USER ID</label>
                                        <input id='customer-id' className='form-control' />
                                    </div>
                                    <button className={`${this.state.isComplete ? "btn-custom action-btn-1" : "disable-btn"} w-100 mt-2`} disabled={this.state.isComplete ? false : true} onClick={() => this.sendNotification()}>Send</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
