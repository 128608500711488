import React, { Component } from 'react'
import Switch from "react-switch";
import Navbar from '../components/Navbar';
import SidebarMenu from '../components/SidebarMenu';
import { Link } from 'react-router-dom';
import { TbCaretDown, TbCheck, TbChevronDown, TbCurrencyRupee, TbListNumbers, TbSearch, TbSquareDot, TbStar, TbUser } from 'react-icons/tb';
import { AiOutlineClose } from 'react-icons/ai';
import { Button, Modal } from 'react-bootstrap';
import ProductDetailsModalComponent from '../components/ProductDetailsModalComponent';
import { getApi, postApi } from '../api/call.api';
import urlApi from '../api/url.api';
import { toast, ToastContainer } from 'react-toastify';
import Pagination from 'react-js-pagination';
import { dateTimeConverter } from '../utils/dateTimeConverter';
import ConfirmationModal from '../components/ConfirmationModal';
import Select from 'react-select';
import { BsArrowLeftRight } from 'react-icons/bs';
import { GrPowerReset } from 'react-icons/gr';

export default class AllProducts extends Component {
    constructor(p) {
        super(p);
        this.state = {
            isOpen: false,
            editModalShow: false,
            productDetailsModalShow: false,
            tableData: [],
            pagination: ({
                active_page: 0,
                per_page: 10,
                total_items: 0
            }),
            page: 1,
            count: 10,
            singleProductDetails: [],
            statusUpdateModalShow: false,
            staticCategoryList: [],
            staticQuantityUnitList: [],
            picUpload: "",
        }
        this.productStatusUpdate = this.productStatusUpdate.bind(this);
        this.allAddonDecorList = [];
    }

    menuBarClick() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    options = [];
    value = [];

    async editViewModalShow(data) {
        this.value = [];
        this.options = [];
        let subCategoryIds = JSON.parse(data.sub_category_id);
        let tempList = [];
        for (let i = 0; i < subCategoryIds.length; i++) {
            let res = await getApi(urlApi.subCategoryDetailsBySubCategoryId + subCategoryIds[i]);
            if (res.responsecode === "200") {
                tempList.push(res.data);
            }
        }
        for (const categoryItem in tempList) {
            this.value.push({
                value: tempList[categoryItem].sub_category_id,
                label: tempList[categoryItem].sub_category_name
            })
        }
        this.getSubCategoryDetailsByCategoryId(data.category_id);
        setTimeout(() => {
            document.getElementById("product-id").value = data.product_id;
            document.getElementById("baker-id").value = data.baker_id;
            document.getElementById("product-edit-name").value = data.name.split("(")[0];
            document.getElementById("product-edit-quantity").value = (data.name.split("(")[1]).split(" ")[0];
            document.getElementById("product-edit-unit").value = ((data.name.split("(")[1]).split(" ")[1]).split(")")[0];
            document.getElementById("product-delivery-time").value = data.delivery_time;
            document.getElementById("pic-preview").src = data.picture;
            document.getElementById("product-edit-price").value = data.price;
            document.getElementById("product-edit-offer-price").value = data.offer_price;
            document.getElementById("product-edit-preference").value = data.preference;
            document.getElementById("product-edit-rating").value = data.rating;
            document.getElementById("product-edit-category").value = data.category_id;
            document.getElementById("customizable-edit").checked = data.type === 1 ? false : true;
            document.getElementById("product-edit-short-description").value = data.short_desc;
            document.getElementById("product-edit-long-description").value = data.long_desc;
        }, 100);

        await this.setState({ editModalShow: true });
    }

    editViewModalHide() {
        this.setState({ editModalShow: false })
    }

    async editProduct() {
        let subCategoryIds = [];
        for (let index = 0; index < this.value.length; index++) {
            subCategoryIds.push(this.value[index].value.toString());
        }
        if (this.state.picUpload) {
            const formData = new FormData();
            formData.append(
                "file_data", this.state.picUpload,
            );
            let respPic = await postApi(formData, urlApi.productPicUpload);
            this.editViewModalHide();
            if (respPic.responsecode === "200") {
                let postData = {
                    "baker_id": document.getElementById("baker-id").value,
                    "product_id": document.getElementById("product-id").value,
                    "name": document.getElementById("product-edit-name").value + " (" + document.getElementById("product-edit-quantity").value + " " + document.getElementById("product-edit-unit").value + ")",
                    "price": document.getElementById("product-edit-price").value,
                    "offer_price": document.getElementById("product-edit-offer-price").value,
                    "type": document.getElementById("customizable-edit").checked ? 3 : 1,
                    "picture": respPic.data,
                    "category_id": document.getElementById("product-edit-category").value,
                    "sub_category_id": subCategoryIds,
                    "short_desc": document.getElementById("product-edit-short-description").value,
                    "delivery_time": parseInt(document.getElementById("product-delivery-time").value),
                    "preference": document.getElementById("product-edit-preference").value,
                    "long_desc": document.getElementById("product-edit-long-description").value
                }
                let resp = await postApi(postData, urlApi.productUpdate);
                this.editViewModalHide();
                if (resp.responsecode === "200") {
                    this.alert(resp.message, "200");
                    this.getAllProductList();
                } else {
                    this.alert(resp.message, "500");
                }
            } else {
                this.alert(respPic.message, "500");
            }
        } else {
            let postData = {
                "baker_id": document.getElementById("baker-id").value,
                "product_id": document.getElementById("product-id").value,
                "name": document.getElementById("product-edit-name").value + " (" + document.getElementById("product-edit-quantity").value + " " + document.getElementById("product-edit-unit").value + ")",
                "price": document.getElementById("product-edit-price").value,
                "offer_price": document.getElementById("product-edit-offer-price").value,
                "type": document.getElementById("customizable-edit").checked ? 3 : 1,
                "picture": document.getElementById("pic-preview").src,
                "category_id": document.getElementById("product-edit-category").value,
                "sub_category_id": subCategoryIds,
                "short_desc": document.getElementById("product-edit-short-description").value,
                "delivery_time": parseInt(document.getElementById("product-delivery-time").value),
                "preference": document.getElementById("product-edit-preference").value,
                "long_desc": document.getElementById("product-edit-long-description").value
            }
            let resp = await postApi(postData, urlApi.productUpdate);
            this.editViewModalHide();
            if (resp.responsecode === "200") {
                this.alert(resp.message, "200");
                this.getAllProductList();
            } else {
                this.alert(resp.message, "500");
            }
        }
    }

    async productDetailsViewModalShow(data) {
        let addons = JSON.parse(data.add_on_ids);
        let decors = JSON.parse(data.decor_ids);
        let addonTempList = [];
        let decorTempList = [];
        this.allAddonDecorList = [];
        for (let i = 0; i < addons.length; i++) {
            let respAddon = await getApi(urlApi.addonDetailsByAddonId + addons[i]);
            if (respAddon.responsecode === "200") {
                addonTempList.push(respAddon.data);
            }
        }
        for (let j = 0; j < decors.length; j++) {
            let respDecor = await getApi(urlApi.decorDetailsByDecorId + decors[j]);
            if (respDecor.responsecode === "200") {
                decorTempList.push(respDecor.data);
            }
        }
        this.allAddonDecorList = [...this.allAddonDecorList, ...[...addonTempList], ...[...decorTempList]];
        this.setState({ singleProductDetails: data });
        await this.setState({ productDetailsModalShow: true });
    }

    productDetailsViewModalHide() {
        this.setState({ productDetailsModalShow: false });
    }

    fileOnChange = async (e) => {
        await this.setState({ picUpload: e.target.files[0] });
        document.getElementById("pic-preview").src = URL.createObjectURL(e.target.files[0]);
    }

    alert = (msg, responseCode) => {
        if (responseCode === "200") {
            toast.success(msg);
        }
        else if (responseCode === "500") {
            toast.error(msg);
        }
        else {
            toast.info(msg);
        }
    }

    async resetTable() {
        await this.setState({ tableData: [] })
        await this.setState({
            pagination: ({
                active_page: 0,
                per_page: 10,
                total_items: 0
            })
        })
    }

    showStatusUpdateModal(bakerID, productID, status) {
        this.bakerID = bakerID;
        this.productID = productID;
        this.status = status;
        this.setState({ statusUpdateModalShow: true });
    }

    hideStatusUpdateModal() {
        this.setState({ statusUpdateModalShow: false })
    }

    async productStatusUpdate(bakerID, productID, status) {
        let postData = {
            "baker_id": bakerID
        };
        let resp = await postApi(postData, status === 1 ? (urlApi.productInactive + productID) : (urlApi.productActive + productID));
        this.hideStatusUpdateModal();
        if (resp.responsecode === "200") {
            this.getAllProductList();
            this.alert(resp.message, resp.responsecode);
        }
        else {
            this.alert(resp.message, resp.responsecode);
        }
    }

    async resetFields() {
        document.getElementById("from-date-filter").value = "";
        document.getElementById("to-date-filter").value = "";
        document.getElementById("search-field").value = "";
        document.getElementById("status-dropdown").value = "";
        this.getAllProductList({ dateFilter: "" });
    }

    dateFilterVar = "";

    async getAllProductList({ dateFilter = this.dateFilterVar } = {}) {
        this.dateFilterVar = dateFilter;
        this.setState({ page: 1 });
        var postData = {
            "search_data": document.getElementById("search-field").value,
            "status": document.getElementById("status-dropdown").value,
            "category": document.getElementById("category-dropdown").value,
            "id_search": document.getElementById("id-search-field").value,
            "date": dateFilter,
            "fromDate": document.getElementById("from-date-filter").value,
            "toDate": document.getElementById("to-date-filter").value
        }
        let resp = await postApi(postData, urlApi.allProductList + this.state.page);
        if (resp.responsecode === "200") {
            this.setState({ tableData: resp.data.data });
            this.setState({ pagination: resp.data.pagination });
        }
        else {
            this.resetTable();
            this.alert(resp.message, "500");
        }
    }

    async getSubCategoryDetailsByCategoryId(categoryID) {
        this.options = [];
        let res = await getApi(urlApi.subCategoryDetailsByCategoryId + categoryID);
        if (res.responsecode === "200") {
            for (const categoryItem in res.data) {
                this.options.push({
                    value: res.data[categoryItem].sub_category_id,
                    label: res.data[categoryItem].sub_category_name
                })
            }
        }
    }

    multiDropDownOnchange = (updatedValue, t) => {
        this.value = updatedValue;
    }

    componentDidMount() {
        this.getAllProductList();
        this.getStaticProductCategoryList();
        this.getStaticUnitList();
    }

    async getStaticUnitList() {
        let resp = await getApi(urlApi.unitList);
        if (resp.responsecode === "200") {
            this.setState({ staticQuantityUnitList: resp.data });
        }
    }

    async handlePageChange(pageNumber) {
        await this.setState({ page: pageNumber });
        this.getAllProductList();
    }

    async onSearch() {
        await this.setState({ page: 1 }, () => this.getAllProductList());
    }

    async getStaticProductCategoryList() {
        let res = await getApi(urlApi.productCategoryList);
        if (res.responsecode === "200") {
            this.setState({ staticCategoryList: res.data })
        }
    }

    getCategoryNameFromID(categoryID) {
        for (const data of this.state.staticCategoryList) {
            if (categoryID === data.product_category_id) {
                return data.product_category_name;
            }
        }
    }

    async getAddonDetailsByAddonId(addonID) {
        let resp = await getApi(urlApi.getAddonDetailsByAddonId + addonID);
        if (resp.responsecode === "200") {

        }
    }

    render() {
        var x = (this.state.pagination.active_page - 1) * this.state.pagination.per_page + 1;
        return (
            <>
                <div className="container-fluid position-relative bg-white d-flex p-0">
                    <SidebarMenu isOpen={this.state.isOpen} />
                    <div className={this.state.isOpen ? "content open" : "content"}>
                        <Navbar menuBarClick={() => this.menuBarClick()} />
                        <div className="container-fluid">
                            <ToastContainer autoClose={1000} />
                            <div className="row g-4">
                                <div className="col-12 px-0">
                                    <div className="rounded h-100 p-2">
                                        <h6 className="mb-2">Product Details || Total : {this.state.pagination.total_items}</h6>
                                        <div className="row mb-2 gx-2">
                                            <div className="col-md-1 col mb-1">
                                                <button className='btn-custom action-btn-5 w-100' onClick={() => this.getAllProductList({ dateFilter: "" })}>All</button>
                                            </div>
                                            <div className="col-md-1 col mb-1">
                                                <button className='btn-custom action-btn-1 w-100' onClick={() => this.getAllProductList({ dateFilter: "DAILY" })}>Today</button>
                                            </div>
                                            <div className="col-md-1 col mb-1">
                                                <button className='btn-custom action-btn-4 w-100' onClick={() => this.getAllProductList({ dateFilter: "YESTERDAY" })}>Yesterday</button>
                                            </div>
                                            <div className="col-md-1 col mb-1">
                                                <button className='btn-custom action-btn-3 w-100 text-nowrap' onClick={() => this.getAllProductList({ dateFilter: "WEEKLY" })}>Last Week</button>
                                            </div>
                                            <div className="col-md-1 col mb-1">
                                                <button className='btn-custom action-btn-2 w-100 text-nowrap' onClick={() => this.getAllProductList({ dateFilter: "MONTHLY" })}>Last Month</button>
                                            </div>
                                        </div>
                                        <div className="row gx-2 mb-2">
                                            <div className="col-md-2 col-5 mb-2">
                                                <input type="date" className='form-control form-control-sm w-100' id='from-date-filter' />
                                            </div>
                                            <div className="col-md-1 mb-2 col text-center"> <BsArrowLeftRight /> </div>
                                            <div className="col-md-2 col-5 mb-2">
                                                <input type="date" className='form-control form-control-sm w-100' id='to-date-filter' />
                                            </div>
                                            <div className="col-md-1 col">
                                                <button className='btn-custom action-btn-1 w-100' onClick={() => this.getAllProductList({ dateFilter: "CUSTOM" })}>Search</button>
                                            </div>
                                            <div className="col-md-2 col-2">
                                                <button className='btn-custom action-btn-5 w-sm-100' onClick={() => this.resetFields()}><GrPowerReset size={20} /></button>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-2">
                                            <form onSubmit={(e) => { e.preventDefault(); this.onSearch() }}>
                                                <div className="row gx-1 search-section">
                                                    <div className="col-md-2 col-6 mb-2">
                                                        <input className='form-control form-control-sm' id='id-search-field' placeholder='Product ID' />
                                                    </div>
                                                    <div className="col-md-2 col-6 mb-2">
                                                        <input className='form-control form-control-sm' id='search-field' placeholder='Product Name' />
                                                    </div>
                                                    <div className="col-md-2 col-5">
                                                        <select className='form-control form-control-sm w-100' defaultValue={""} id='status-dropdown'>
                                                            <option value="">All Products</option>
                                                            <option value="1">Active Products</option>
                                                            <option value="0">Inactive Products</option>
                                                            <option value="2">Deleted Products</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2 col-5">
                                                        <select name="" id="category-dropdown" className='form-control form-control-sm'>
                                                            <option value="">-- All Category--</option>
                                                            {this.state.staticCategoryList.map((data, index) => {
                                                                return (
                                                                    <option key={index} value={data.product_category_id}>{data.product_category_name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2 col-2">
                                                        <button className='btn-custom action-btn-5 w-sm-100' type='submit'><TbSearch size={20} /></button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="table-responsive">
                                            {this.state.tableData.length > 0 ?
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="col">#</th>
                                                            <th className="col">ID</th>
                                                            <th className="col">Product Name</th>
                                                            <th className="col">Price</th>
                                                            <th className="col">Shop Name</th>
                                                            <th className="col">Category</th>
                                                            <th className="col">Quantity</th>
                                                            <th className="col">Customizable</th>
                                                            <th className="col">Exp. Delivery</th>
                                                            <th className="col">Status</th>
                                                            <th className="col">Created Date</th>
                                                            <th className="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.tableData.map((data, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <th scope="row">{x++}</th>
                                                                    <td>{data.product_id}</td>
                                                                    <td className='text-left'>
                                                                        <span className='table-img'><img src={data.picture} alt="" /></span><br />
                                                                        <span>
                                                                            {data.preference !== 3 ?
                                                                                <TbSquareDot color={data.preference === 2 ? 'red' : data.preference === 1 ? 'green' : 'grey'} /> :
                                                                                <span><TbSquareDot color='red' />
                                                                                    <TbSquareDot color='green' />
                                                                                </span>}
                                                                            {data.name.split("(")[0]}
                                                                        </span>
                                                                    </td>
                                                                    <td className='text-left'>
                                                                        <span>₹{data.offer_price}</span><br />
                                                                        <span className='disable-price' style={{ display: data.offer_price === data.price ? 'none' : 'unset' }}>₹{data.price}</span>
                                                                    </td>
                                                                    <td className='text-left'>
                                                                        <Link to={"/single-shop-view/shopDetails/" + data.baker_id} target="_blank">
                                                                            <span className='table-img'><img src={data.shop_image} alt="" /></span><br />
                                                                            <span>{data.shop_name}</span>
                                                                        </Link>
                                                                    </td>
                                                                    <td>{this.getCategoryNameFromID(data.category_id)}</td>
                                                                    <td>{data.name.split("(")[1].split(")")[0]}</td>
                                                                    <td className='text-center'>{data.type === 1 ? <AiOutlineClose size={30} color="red" /> : <TbCheck size={30} color="green" />}</td>
                                                                    <td>{data.delivery_time} days</td>
                                                                    <td>
                                                                        <span style={{ display: data.status === 2 ? 'none' : 'unset' }}>
                                                                            <Switch onChange={() => this.showStatusUpdateModal(data.baker_id, data.product_id, data.status)} checked={data.status === 0 ? false : data.status === 1 ? true : false} />
                                                                        </span>
                                                                        <span style={{ display: data.status === 2 ? 'unset' : 'none' }}>
                                                                            <span className='badge bg-danger'>Deleted</span><br />
                                                                            <button className='btn-custom action-btn-3 m-1' onClick={() => this.productStatusUpdate(data.baker_id, data.product_id, 1)}>Restore</button>
                                                                        </span>
                                                                    </td>
                                                                    <td>{dateTimeConverter(data.create_date)}</td>
                                                                    <td>
                                                                        <button className='btn-custom action-btn-1 m-1' onClick={() => this.editViewModalShow(data)}>Edit</button>
                                                                        <button className='btn-custom action-btn-2 m-1' onClick={() => this.productDetailsViewModalShow(data)}>Details</button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                                :
                                                <div className="text-center">
                                                    <h4 className="text-danger">No Data Found!</h4>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='me-2' style={{ float: 'right', display: this.state.tableData.length > 0 ? 'unset' : 'none' }}>
                                <Pagination
                                    activePage={parseInt(this.state.pagination.active_page)}
                                    itemsCountPerPage={this.state.pagination.per_page}
                                    totalItemsCount={this.state.pagination.total_items}
                                    pageRangeDisplayed={5}
                                    onChange={this.handlePageChange.bind(this)}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.editModalShow} onHide={() => this.editViewModalHide()}>
                    <Modal.Header closeButton style={{ display: "none" }}>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: "10px 14px", maxHeight: "85vh", overflow: "auto" }}>
                        <div className="text-center"><h3 style={{ borderBottom: "4px solid teal" }}>Edit Product Details</h3></div>
                        <form>
                            <input id='product-id' type="text" style={{ display: "none" }} />
                            <input id='baker-id' type="text" style={{ display: "none" }} />
                            <div className="form-group mb-2">
                                <label htmlFor="product-edit-cn">Product Name</label>
                                <div className="input-group input-group-sm">
                                    <input id="product-edit-name" type="text" className="form-control form-control-sm" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span><TbUser /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group mb-2">
                                        <label htmlFor="product-edit-quantity">Quantity</label>
                                        <div className="input-group input-group-sm">
                                            <input id="product-edit-quantity" type="text" className="form-control" />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span><TbListNumbers /></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group mb-2">
                                        <label htmlFor="product-edit-unit">Unit</label>
                                        <div className="input-group input-group-sm">
                                            <select id="product-edit-unit" className='form-control form-control-sm'>
                                                {this.state.staticQuantityUnitList.map((data, index) => {
                                                    return (
                                                        <option key={index} value={data.unit_short_name}>{data.unit_short_name}</option>
                                                    )
                                                })}
                                            </select>
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span><TbChevronDown /></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="">Product Picture :&nbsp;&nbsp;&nbsp;</label>
                                <img id="pic-preview" style={{ maxHeight: 60 }} alt="" />
                            </div>
                            <div className="input-group mb-2">
                                <input id="product-edit-photo" type="file" className="form-control form-control-sm" onChange={this.fileOnChange} />
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group mb-2">
                                        <label htmlFor="product-edit-mobile">Price</label>
                                        <div className="input-group input-group-sm">
                                            <input id="product-edit-price" type="text" className="form-control" />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span><TbCurrencyRupee /></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group mb-2">
                                        <label htmlFor="product-edit-mobile">Offer Price</label>
                                        <div className="input-group input-group-sm">
                                            <input id="product-edit-offer-price" type="text" className="form-control" />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span><TbCurrencyRupee /></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group mb-2">
                                        <label htmlFor="product-edit-mobile">Preference</label>
                                        <div className="input-group input-group-sm">
                                            <select className='form-control' id='product-edit-preference'>
                                                <option value={1}>Veg</option>
                                                <option value={2}>Non-Veg</option>
                                                <option value={3}>Both</option>
                                            </select>
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span><TbCaretDown /></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group mb-2">
                                        <label htmlFor="product-edit-mobile">Rating</label>
                                        <div className="input-group input-group-sm">
                                            <input className='form-control' id='product-edit-rating' />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span><TbStar /></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="product-delivery-time">Delivery Time</label>
                                <div className="input-group input-group-sm">
                                    <input id="product-delivery-time" type="number" className="form-control form-control-sm" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span><TbListNumbers /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group mb-2">
                                        <label htmlFor="product-edit">Category</label>
                                        <div className="input-group input-group-sm">
                                            <select className='form-control' id='product-edit-category' onChange={(e) => this.getSubCategoryDetailsByCategoryId(e.target.value)}>
                                                {
                                                    this.state.staticCategoryList.map((data, index) => {
                                                        return (
                                                            <option key={index} value={data.product_category_id}>{data.product_category_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span><TbCaretDown /></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="product-edit-mobile">Sub Category</label>
                                    <div className="input-group input-group-sm">
                                        <Select id='product-edit-sub-category' defaultValue={this.value} onChange={this.multiDropDownOnchange} isMulti className="basic-multi-select w-100" options={this.options} />
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex align-items-center">
                                <div className="col">
                                    <div className="form-group mb-2">
                                        <label htmlFor="customizable-edit">Customizable</label>
                                        <div className="input-group input-group-sm">
                                            <input class="form-check-input" type="checkbox" value="" id="customizable-edit" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="product-edit-mobile">Short Description</label>
                                <div className="input-group input-group-sm">
                                    <textarea id="product-edit-short-description" className="form-control" rows="2"></textarea>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="product-edit-mobile">Long Description</label>
                                <div className="input-group input-group-sm">
                                    <textarea id="product-edit-long-description" className="form-control" rows="4"></textarea>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='btn-custom action-btn-3' onClick={() => this.editViewModalHide()}>
                            Close
                        </Button>
                        <Button className='btn-custom action-btn-1' onClick={() => this.editProduct()}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>

                <ProductDetailsModalComponent
                    show={this.state.productDetailsModalShow}
                    productDetailsViewModalHide={() => this.productDetailsViewModalHide()}
                    productData={this.state.singleProductDetails}
                    addonDecorData={this.allAddonDecorList}
                />

                <ConfirmationModal
                    show={this.state.statusUpdateModalShow}
                    headerText="Update Status"
                    bodyText="Are You Sure Want To Update Status?"
                    closeFunction={() => this.hideStatusUpdateModal()}
                    okFunction={() => this.productStatusUpdate(this.bakerID, this.productID, this.status)}
                />
            </>
        )
    }
}
