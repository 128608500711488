import React, { Component } from 'react'
import image from "../assets/images/defaultAddonDecor.jpg"

export default class AddonDecorComponent extends Component {
    render() {
        return (
            <>
                <div className="item-wrapper w-100 h-100">
                    <img src={this.props.data ? this.props.data.picture : image} alt="" />
                    <br />
                    <p className='m-0 p-0'>{this.props.data ? this.props.data.name : "addon/decor"}</p>
                    <p className='m-0 p-0 text-start ms-2 fw-bold'>{this.props.data ? "₹ " + this.props.data.price : "₹00"}</p>
                    <p className='m-0 p-0 text-start ms-2' style={{ display: (this.props.type === "ADDON" || this.props.type === "DECOR") ? 'flex' : 'none' }}>{this.props.data.category_id ? this.props.data.category_name : ""}</p>
                    <p className={`m-0 p-0 text-start ms-2 text-${this.props.data.status === 0 ? "secondary" : this.props.data.status === 1 ? "success" : "danger"}`} style={{ display: (this.props.type === "ADDON" || this.props.type === "DECOR")? 'flex' : 'none' }}>{this.props.data.status ? this.props.data.status === 0 ? "Inactive" : this.props.data.status === 1 ? "Active" : "Deleted" : ""}</p>
                </div>
            </>
        )
    }
}
