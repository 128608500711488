import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { TbSquareDot } from 'react-icons/tb'
import AddonDecorComponent from './AddonDecorComponent'
import ProductImage from "../assets/images/defaultCake.jpg"
import { getApi } from '../api/call.api'
import urlApi from '../api/url.api'

export default class ProductDetailsModalComponent extends Component {
    constructor(p) {
        super(p);
        this.state = {
            staticCategoryList: []
        }
    }
    componentDidMount() {
        this.getStaticProductCategoryList();
    }
    async getStaticProductCategoryList() {
        let res = await getApi(urlApi.productCategoryList);
        if (res.responsecode === "200") {
            this.setState({ staticCategoryList: res.data })
        }
    }

    getCategoryNameFromID(categoryID) {
        for (const data of this.state.staticCategoryList) {
            if (categoryID === data.product_category_id) {
                return data.product_category_name;
            }
        }
    }
    render() {
        return (
            <>
                <Modal show={this.props.show} onHide={this.props.productDetailsViewModalHide}>
                    <Modal.Header closeButton style={{ display: "none" }}>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: "10px 14px", MaxHeight: "90vh", overflow: "auto" }}>
                        <div className="text-center"><h3 style={{ borderBottom: "4px solid teal" }}>Product Details</h3></div>
                        <div className="product-img">
                        <img
                                    className="d-block w-100"
                                    style={{ height: 250, objectFit: 'cover',aspectRatio:"1/1" }}
                                    src={this.props.productData.picture ?? ProductImage}
                                    alt="First slide"
                                />
                        </div>
                        <div className="d-flex justify-content-between mt-2">
                            <p className='m-0'>ID : <span className='fw-bold'>{this.props.productData.product_id}</span> </p>
                            <p className={`m-0 p-0 product-status`}><span className={`badge bg-${this.props.productData.status === 1?"success":this.props.productData.status === 2?"danger":"secondary"}`}>{this.props.productData.status === 1?"Active":this.props.productData.status === 2?"Deleted":"Inactive"}</span></p>
                        </div>
                        <div className="my-2 row d-flex justify-content-between">
                            <div className="col">
                                {this.props.productData.preference !== 3 ?
                                    <TbSquareDot color={this.props.productData.preference === 2 ? 'red' : this.props.productData.preference === 1 ? 'green' : 'grey'} /> :
                                    <span><TbSquareDot color='red' />
                                        <TbSquareDot color='green' />
                                    </span>}
                            </div>
                            <div className="col text-end">{this.getCategoryNameFromID(this.props.productData.category_id)}</div>
                        </div>
                        <h5 className='m-0'>{this.props.productData.name}<span style={{ display: this.props.productData.quantity ? 'unset' : 'none' }}>{" * (" + this.props.productData.quantity + " pcs)"}</span></h5>
                        {this.props.productData.shop_name}
                        <div className="row">
                            <div className="col-2 text-bottom product-offer-price">₹{this.props.productData.offer_price ?? this.props.productData.price}</div>
                            <div className="col px-md-0 align-items-center product-price" style={{ display: this.props.productData.offer_price ? 'flex' : 'none' }}>₹{this.props.productData.price}</div>
                        </div>
                        <p className='m-0 p-0'>{this.props.productData.short_desc}</p>
                        <hr />
                        <p className='m-0 p-0'>{this.props.productData.long_desc}</p>
                        <div className='d-flex row item-section mt-2'>
                            {this.props.addonDecorData ?
                                this.props.addonDecorData.map((data) => {
                                    return (
                                        <div className="col-md-3 col-4 mb-2"><AddonDecorComponent data={data} /></div>
                                    )
                                })
                                :
                                <div className="col-md-3 col-4 mb-2"><AddonDecorComponent data={""}/></div>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'none' }}>
                        <Button className='action-btn-2' onClick={this.props.productDetailsViewModalHide}>
                            Close
                        </Button>
                        <Button className='action-btn-1' onClick={this.props.productDetailsViewModalHide}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}
