import React, { Component } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import SidebarMenu from '../components/SidebarMenu';
import userImage from "../assets/images/profilePic.jpg"
import ConfirmationModal from '../components/ConfirmationModal';
import ProductDetailsModalComponent from '../components/ProductDetailsModalComponent';
import { getApi, postApi } from '../api/call.api';
import urlApi from '../api/url.api';
import Pagination from 'react-js-pagination';
import { toast, ToastContainer } from 'react-toastify';
import { dateTimeConverter } from '../utils/dateTimeConverter';
import { TbSearch } from 'react-icons/tb';

export default class Wishlists extends Component {
    constructor(p) {
        super(p);
        this.state = {
            isOpen: false,
            editModalShow: false,
            confirmationModalShow: false,
            productDetailsModalShow: false,
            pagination: ({
                active_page: 0,
                per_page: 10,
                total_items: 0
            }),
            page: 1,
            count: 10,
            wishlistShopTableData: [],
            wishlistProductTableData: [],
            singleProductSDetail: []
        }
        this.allAddonDecorList = [];
    }


    menuBarClick() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    showConfirmModal(data, shop) {
        this.isShop = shop;
        this.itemID = (this.isShop ? data.wishlist_shop_id : data.wishlist_product_id);
        this.setState({ confirmationModalShow: true });
    }

    hideConfirmModal() {
        this.setState({ confirmationModalShow: false });
    }

    async productDetailsViewModalShow(data) {
        let addons = JSON.parse(data.add_on_ids);
        let decors = JSON.parse(data.decor_ids);
        let addonTempList = [];
        let decorTempList = [];
        this.allAddonDecorList = [];
        for (let i = 0; i < addons.length; i++) {
            let respAddon = await getApi(urlApi.addonDetailsByAddonId + addons[i]);
            if (respAddon.responsecode === "200") {
                addonTempList.push(respAddon.data);
            }
        }
        for (let j = 0; j < decors.length; j++) {
            let respDecor = await getApi(urlApi.decorDetailsByDecorId + decors[j]);
            if (respDecor.responsecode === "200") {
                decorTempList.push(respDecor.data);
            }
        }
        this.allAddonDecorList = [...this.allAddonDecorList, ...[...addonTempList], ...[...decorTempList]];
        this.setState({ productDetailsModalShow: true });
        this.productDetailsByProductId(data.product_id);
    }

    productDetailsViewModalHide() {
        this.setState({ productDetailsModalShow: false });
    }

    async resetTable() {
        await this.setState({ wishlistProductTableData: [] });
        await this.setState({ wishlistShopTableData: [] });
        await this.setState({
            pagination: ({
                active_page: 0,
                per_page: 10,
                total_items: 0
            })
        })
    }

    async getAllWishlistShop() {
        this.setState({ page: 1 });
        let postData = {
            "search_data": document.getElementById("search-field-shop").value
        }
        let resp = await postApi(postData, urlApi.allWishlistShop + this.state.page);
        if (resp.responsecode === "200") {
            this.setState({ wishlistShopTableData: resp.data.data });
            this.setState({ pagination: resp.data.pagination });
        }
        else {
            this.resetTable();
        }
    }

    async onSearchShop() {
        await this.setState({ page: 1 }, () => this.getAllWishlistShop());
    }

    async getAllWishlistProduct() {
        this.setState({ page: 1 });
        let postData = {
            "search_data": document.getElementById("search-field-product").value
        }
        let resp = await postApi(postData, urlApi.allWishlistProduct + this.state.page);
        if (resp.responsecode === "200") {
            this.setState({ wishlistProductTableData: resp.data.data });
            this.setState({ pagination: resp.data.pagination });
        }
        else {
            this.resetTable();
        }
    }

    async onSearchProduct() {
        await this.setState({ page: 1 }, () => this.getAllWishlistProduct());
    }

    async wishlistProductRemove(productID) {
        this.hideConfirmModal();
        let postData = {
            "status": 0,
            "wishlist_product_id": productID
        }
        let res = await postApi(postData, urlApi.removeWishlistProduct);
        if (res.responsecode === "200") {
            this.alert(res.message, "200");
            if (this.state.wishlistProductTableData.length === 1 && this.state.page > 1) {
                let pageNumber = this.state.page - 1;
                this.setState({ page: pageNumber }, () => {
                    this.getAllWishlistProduct();
                });
            }
            else {
                this.getAllWishlistProduct();
            }
        }
        else {
            this.getAllWishlistProduct();
        }
    }

    async wishlistShopRemove(shopID) {
        let postData = {
            "status": 0,
            "wishlist_shop_id": shopID
        }
        let res = await postApi(postData, urlApi.removeWishlistShop);
        this.hideConfirmModal();
        if (res.responsecode === "200") {
            this.alert("Shop Removed", res.responsecode);
            if (this.state.wishlistShopTableData.length === 1 && this.state.page > 1) {
                let pageNumber = this.state.page - 1;
                this.setState({ page: pageNumber }, () => {
                    this.getAllWishlistShop();
                });
            }
            else {
                this.getAllWishlistShop();
            }
        }
        else {
            this.getAllWishlistShop();
        }
    }

    componentDidMount() {
        if (this.props.match.params.defaultActiveKey === "wishListShop") {
            this.getAllWishlistShop();
        }
        if (this.props.match.params.defaultActiveKey === "wishListProduct") {
            this.getAllWishlistProduct();
        }
    }

    alert = (msg, responseCode) => {
        if (responseCode === "200") {
            toast.success(msg);
        }
        else if (responseCode === "500") {
            toast.error(msg);
        }
        else {
            toast.info(msg);
        }
    }

    async handlePageChangeForShop(pageNumber) {
        await this.setState({ page: pageNumber });
        this.getAllWishlistShop();
    }

    async handlePageChangeForProduct(pageNumber) {
        await this.setState({ page: pageNumber });
        this.getAllWishlistProduct();
    }

    async productDetailsByProductId(productID) {
        let resp = await getApi(urlApi.productDetailsByProductId + productID);
        if (resp.responsecode === "200") {
            this.setState({ singleProductSDetail: resp.data });
        }
        else {
            this.alert(resp.message, resp.responsecode);
        }
    }

    render() {
        var shopX = (this.state.pagination.active_page - 1) * this.state.pagination.per_page + 1;
        var productX = (this.state.pagination.active_page - 1) * this.state.pagination.per_page + 1;
        return (
            <>
                <div className="container-fluid position-relative bg-white d-flex p-0">
                    <SidebarMenu isOpen={this.state.isOpen} />
                    <div className={this.state.isOpen ? "content open" : "content"}>
                        <Navbar menuBarClick={() => this.menuBarClick()} />
                        <div className="container-fluid">
                            <ToastContainer autoClose={1000} />
                            <div className="row g-4">
                                <div className="col-12 px-0">
                                    <div className="bg-light rounded h-100 p-2">
                                        <Tabs defaultActiveKey={this.props.match.params.defaultActiveKey ? this.props.match.params.defaultActiveKey : "wishListShop"}
                                            id="uncontrolled-tab-example"
                                            className="mb-3"
                                            onSelect={(e) => {
                                                this.props.history.push('/wishlist/' + e);
                                                if (e === "wishListShop") {
                                                    this.getAllWishlistShop();
                                                }
                                                if (e === "wishListProduct") {
                                                    this.getAllWishlistProduct();
                                                }
                                            }}
                                        >
                                            <Tab eventKey="wishListShop" title={<Link className='tab-link-color' to="/wishlist/wishListShop">Shop</Link>}>
                                                <h6 className="mb-2 mt-5">Wishlist Shops || Total : {this.state.pagination.total_items}</h6>
                                                <form onSubmit={(e) => { e.preventDefault(); this.onSearchShop() }}>
                                                    <div className="col-12 mb-3">
                                                        <div className="row gx-1 search-section">
                                                            <div className="col-md-3 col-10">
                                                                <input className='form-control form-control-sm w-100' id='search-field-shop' placeholder='Username / Shop Name' />
                                                            </div>
                                                            <div className="col-md-2 col-2">
                                                                <button className='btn-custom action-btn-5 w-sm-100' type='submit'><TbSearch size={20} /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className="table-responsive">
                                                    {this.state.wishlistShopTableData.length > 0 ?
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th className="col">#</th>
                                                                    <th className="col">Shop Name</th>
                                                                    <th className="col">Customer Name</th>
                                                                    <th className="col">Created Date</th>
                                                                    <th className="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.wishlistShopTableData.map((data,index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <th scope="row">{shopX++}</th>
                                                                            <td className='text-left'>
                                                                                <Link to={"/single-shop-view/shopDetails/" + data.baker_id} target="_blank">
                                                                                    <span className='table-img'><img src={data.shop_image} alt="" /></span><br />
                                                                                    <span>[{data.baker_id}] {data.shop_name}</span>
                                                                                </Link>
                                                                            </td>
                                                                            <td>
                                                                                <Link to={"/single-customer-view/userDetails/" + data.customer_id} target="_blank">
                                                                                    <span className='table-img'><img src={data.profile_pic} onError={({ currentTarget }) => {
                                                                                        currentTarget.onerror = null;
                                                                                        currentTarget.src = userImage;
                                                                                    }} alt="" /></span><br />
                                                                                    <span>[{data.customer_id}] {data.customer_name}</span>
                                                                                </Link>
                                                                            </td>
                                                                            <td>{dateTimeConverter(data.create_date)}</td>
                                                                            <td>
                                                                                <button className='btn-custom action-btn-3' onClick={() => this.showConfirmModal(data, true)}>Remove</button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                        : <div className="col-12">
                                                            <h4 className='text-center text-danger'>No Shop in Favorite</h4>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='me-2' style={{ float: 'right', display: this.state.wishlistShopTableData.length > 0 ? 'unset' : 'none' }}>
                                                    <Pagination
                                                        activePage={parseInt(this.state.pagination.active_page)}
                                                        itemsCountPerPage={this.state.pagination.per_page}
                                                        totalItemsCount={this.state.pagination.total_items}
                                                        pageRangeDisplayed={5}
                                                        onChange={this.handlePageChangeForShop.bind(this)}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </Tab>
                                            <Tab eventKey="wishListProduct" title={<Link className='tab-link-color' to="/wishlist/wishListProduct">Product</Link>}>
                                                <h6 className="mb-2 mt-5">Wishlist Products || Total : {this.state.pagination.total_items}</h6>
                                                <form onSubmit={(e) => { e.preventDefault(); this.onSearchProduct() }}>
                                                    <div className="col-12 mb-3">
                                                        <div className="row gx-1 search-section">
                                                            <div className="col-md-3 col-10">
                                                                <input className='form-control form-control-sm w-100' id='search-field-product' placeholder='Username / Product Name' />
                                                            </div>
                                                            <div className="col-md-2 col-2">
                                                                <button className='btn-custom action-btn-5 w-sm-100' type='submit'><TbSearch size={20} /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className="table-responsive">
                                                    {this.state.wishlistProductTableData.length > 0 ?
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th className="col">#</th>
                                                                    <th className="col">Product Name</th>
                                                                    <th className="col">Shop Name</th>
                                                                    <th className="col">Customer Name</th>
                                                                    <th className="col">Create Date</th>
                                                                    <th className="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.wishlistProductTableData.map((data,index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <th scope="row">{productX++}</th>
                                                                            <td className='text-left'>
                                                                                <span className='table-img'><img src={data.picture} alt="" /></span><br />
                                                                                <span>[{data.product_id}] {data.product_name}</span>
                                                                            </td>
                                                                            <td className='text-left'>
                                                                                <Link target="_blank" to={"/single-shop-view/shopDetails/" + data.baker_id}>
                                                                                <span className='table-img'><img src={data.shop_image} alt="" /></span><br />
                                                                                <span>[{data.baker_id}] {data.shop_name}</span>
                                                                                </Link>
                                                                            </td>
                                                                            <td>
                                                                                <Link to={"/single-customer-view/userDetails/" + data.customer_id} target="_blank">
                                                                                    <span className='table-img'><img src={data.profile_pic} onError={({ currentTarget }) => {
                                                                                        currentTarget.onerror = null;
                                                                                        currentTarget.src = userImage;
                                                                                    }} alt="" /></span><br />
                                                                                    <span>[{data.customer_id}] {data.customer_name}</span>
                                                                                </Link>
                                                                            </td>
                                                                            <td>{dateTimeConverter(data.create_date)}</td>
                                                                            <td>
                                                                                <button className='btn-custom action-btn-3 m-1' onClick={() => this.showConfirmModal(data, false)}>Remove</button>
                                                                                <button className='btn-custom action-btn-2 m-1' onClick={() => this.productDetailsViewModalShow(data)}>Product Details</button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                        : <div className="col-12">
                                                            <h4 className='text-center text-danger'>No Product in Favorite</h4>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='me-2' style={{ float: 'right', display: this.state.wishlistProductTableData.length > 0 ? 'unset' : 'none' }}>
                                                    <Pagination
                                                        activePage={parseInt(this.state.pagination.active_page)}
                                                        itemsCountPerPage={this.state.pagination.per_page}
                                                        totalItemsCount={this.state.pagination.total_items}
                                                        pageRangeDisplayed={5}
                                                        onChange={this.handlePageChangeForProduct.bind(this)}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ConfirmationModal
                    show={this.state.confirmationModalShow}
                    headerText={this.isShop ? "Remove Favorite Shop" : "Remove Favorite Product"}
                    bodyText="Are You Sure Want To Remove This?"
                    closeFunction={() => this.hideConfirmModal()}
                    okFunction={() => this.isShop ? this.wishlistShopRemove(this.itemID) : this.wishlistProductRemove(this.itemID)}
                />
                <ProductDetailsModalComponent
                    show={this.state.productDetailsModalShow}
                    productData={this.state.singleProductSDetail}
                    addonDecorData={this.allAddonDecorList}
                    productDetailsViewModalHide={() => this.productDetailsViewModalHide()}
                />
            </>
        )
    }
}