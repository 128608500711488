const { APP_ID } = require("./static.data.pnp.js");

module.exports = {
    APP_ID: APP_ID,
    USERID: localStorage.getItem("user-id", ""),
    TOKEN: () => {
        return localStorage.getItem("token-batterly-admin", "");
    },
    USERNAME: () => {
        return localStorage.getItem("username", "")
    }
}

