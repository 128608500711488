import React, { Component } from 'react'
import "../css/main.css";
import logo from "../assets/images/logo.png"
import { Link } from 'react-router-dom';
import dataApi from '../api/data.api';

export default class Navbar extends Component {

    render() {
        return (
            <>
                <nav className="navbar navbar-expand bg-light navbar-light sticky-top px-4 py-0">
                    <Link to={"/dashboard"} className="navbar-brand d-flex d-lg-none me-4">
                        <h2 className="main-color mb-0"><i className="fa fa-heart"></i></h2>
                    </Link>
                    <Link to="#" className="sidebar-toggler flex-shrink-0" style={{color:'#F08632'}} onClick={()=>this.props.menuBarClick()}>
                        <i className="fa fa-bars"></i>
                    </Link>
                    <div className="navbar-nav align-items-center ms-auto">
                        {/* <div className="nav-item dropdown">
                            <Link href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                                <i className="fa fa-bell me-lg-2"></i>
                                <span className="d-none d-lg-inline-flex">Notification</span>
                            </Link>
                            <div className="dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0">
                                <Link href="#" className="dropdown-item">
                                    <h6 className="fw-normal mb-0">Profile updated</h6>
                                    <small>15 minutes ago</small>
                                </Link>
                                <hr className="dropdown-divider" />
                                <Link href="#" className="dropdown-item">
                                    <h6 className="fw-normal mb-0">New user added</h6>
                                    <small>15 minutes ago</small>
                                </Link>
                                <hr className="dropdown-divider" />
                                <Link href="#" className="dropdown-item">
                                    <h6 className="fw-normal mb-0">Password changed</h6>
                                    <small>15 minutes ago</small>
                                </Link>
                                <hr className="dropdown-divider" />
                                <Link href="#" className="dropdown-item text-center">See all notifications</Link>
                            </div>
                        </div> */}
                        <div className="nav-item dropdown">
                            <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                                <img className="rounded-circle me-lg-2" src={logo} alt="" style={{ width: 40, height: 40 ,objectFit:'cover'}} />
                                <span className="d-none d-lg-inline-flex">{dataApi.USERNAME()}</span>
                            </Link>
                            <div className="dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0">
                                <Link to={"/"} className="dropdown-item">Log Out</Link>
                            </div>
                        </div>
                    </div>
                </nav>
            </>
        )
    }
}
