import React, { Component } from 'react'
import { Button, Modal, Offcanvas, Tab, Tabs } from 'react-bootstrap'
import { TbCalendar, TbCaretDown, TbCurrentLocation, TbFileDescription, TbLocation, TbMail, TbNote, TbPhoneCall, TbPin, TbSearch, TbSquareDot, TbUser } from 'react-icons/tb'
import { Link } from 'react-router-dom'
import SidebarMenu from '../components/SidebarMenu'
import image from "../assets/images/profilePic.jpg"
import Navbar from '../components/Navbar'
import AddressComponent from '../components/AddressComponent'
import Switch from "react-switch";
import { FaWeightHanging } from 'react-icons/fa'
import ProductDetailsModalComponent from '../components/ProductDetailsModalComponent'
import ChatView from './ChatView'
import { getApi, postApi } from '../api/call.api'
import urlApi from '../api/url.api'
import { dateTimeConverter } from '../utils/dateTimeConverter'
import { toast, ToastContainer } from 'react-toastify'
import Pagination from 'react-js-pagination'
import ConfirmationModal from '../components/ConfirmationModal'
import { BsArrowLeftRight } from 'react-icons/bs'
import { GrPowerReset } from 'react-icons/gr';
import socketService from '../api/socketConnect';
import PubSub from 'pubsub-js';

export default class SingleCustomerView extends Component {
    constructor(p) {
        super(p);
        this.state = {
            isOpen: false,
            menuModalShow: false,
            editCartModalShow: false,
            toggleCheck: false,
            addressModalShow: false,
            confirmModalShow: false,
            sideBarShow: false,
            addressChangeModalShow: false,
            productDetailsModalShow: false,
            chatSideBarShow: false,
            statusUpdateModalShow: false,
            sendNotificationShow: false,
            productDetailsCartModalShow: false,
            singleProductDetailsCart: [],
            userData: [],
            picUpload: '',
            isComplete: true,
            addressList: [],
            cartList: [],
            orderList: [],
            pagination: ({
                active_page: 0,
                per_page: 10,
                total_items: 0
            }),
            page: 1,
            count: 10,
            singleProductSDetail: [],
            setDefaultConfirmationModalShow: false,
            deleteModalShow: false,
            chatUserData: [],
            chatMsgList: [],
        }
        this.statusChange = this.statusChange.bind(this);
        this.addressId = null;
        this.selectedAddressOfCart = null;
        this.allAddonDecorList = [];
        this.chatDetails = [];
    }

    menuBarClick() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    statusChange(toggleCheck) {
        this.setState({ toggleCheck: toggleCheck });
    }

    async editCartModalShow(cartData) {
        await this.setState({ editCartModalShow: true }, () => {
            document.getElementById("cart-id").value = cartData.cart_id;
            document.getElementById("cart-edit-preference").defaultValue = cartData.preference;
            document.getElementById("cart-edit-quantity").value = cartData.quantity;
            document.getElementById("cart-delivery-date").value = cartData.min_delivery_day.split(" ")[0];
            document.getElementById("cart-customer-note").value = cartData.customer_note;
        })
    }
    editCartModalHide() {
        this.setState({ editCartModalShow: false })
    }

    addressChangeModalShow(data) {
        setTimeout(() => {
            this.selectedAddressOfCart = data.address_id;
        }, 100);
        this.getAddressList();
        this.selectedCartId = data.cart_id;
        this.setState({ addressChangeModalShow: true });
    }
    addressChangeModalHide() {
        this.setState({ addressChangeModalShow: false });
    }

    showSendNotification() {
        this.setState({ sendNotificationShow: true });
    }
    hideSendNotification() {
        this.setState({ sendNotificationShow: false });
    }

    async productDetailsViewModalShow(data) {

        this.allAddonDecorList = [];

        this.allAddonDecorList = [...this.allAddonDecorList, ...[...data.add_on_details], ...[...data.decor_details]];
        this.setState({ singleProductSDetail: data });
        await this.setState({ productDetailsModalShow: true });
    }

    productDetailsViewModalHide() {
        this.setState({ productDetailsModalShow: false });
    }

    async productDetailsByProductId(productID) {
        let resp = await getApi(urlApi.productDetailsByProductId + productID);
        if (resp.responsecode === "200") {
            this.setState({ singleProductSDetail: resp.data });
        }
        else {
            this.alert(resp.message, resp.responsecode);
        }
    }

    async showSideBar(addressData) {
        await this.setState({ sideBarShow: true }, () => {
            setTimeout(() => {
                document.getElementById("address-id").value = addressData.address_id;
                document.getElementById("edit-address-name").value = addressData.name;
                document.getElementById("edit-address-mobile").value = addressData.mobile;
                document.getElementById("edit-address-details").value = addressData.address_details;
                document.getElementById("edit-address-pincode").value = addressData.pincode;
                document.getElementById("edit-address-landmark").value = addressData.landmark;
            }, 100);
        });

    }

    async updateAddress() {
        let postData = {
            "customer_id": this.props.match.params.customerID,
            "address_id": document.getElementById("address-id").value,
            "name": document.getElementById("edit-address-name").value,
            "mobile": document.getElementById("edit-address-mobile").value,
            "city": "Kolkata",
            "state": "WB",
            "pincode": parseInt(document.getElementById("edit-address-pincode").value),
            "landmark": document.getElementById("edit-address-landmark").value,
            "address_details": document.getElementById("edit-address-details").value
        }
        if (document.getElementById("edit-address-pincode").value <= 700000 || document.getElementById("edit-address-pincode").value >= 700111) {
            this.alert("Pincode Range 700001 - 700110", "500");
        } else {
            let res = await postApi(postData, urlApi.customerAddressUpdate);
            if (res.responsecode === "200") {
                this.getAddressList();
                this.hideSideBar();
                this.alert(res.message, res.responsecode);
            } else {
                this.alert(res.message, res.responsecode);
            }
        }
    }

    async deleteAddress() {
        let postData = {
            "customer_id": this.props.match.params.customerID,
            "address_id": this.addressId,
        }
        let resp = await postApi(postData, urlApi.customerAddressDelete);
        if (resp.responsecode === "200") {
            this.getAddressList();
            this.hideDeleteModal();
            this.alert(resp.message, resp.responsecode);
        } else {
            this.alert(resp.message, resp.responsecode);
        }
    }

    async updateCartStatus(cartID, status) {
        let postData = {
            "cart_id": cartID,
            "status": status
        }
        let resp = await postApi(postData, urlApi.cartStatusUpdate);
        if (resp.responsecode === "200") {
            this.alert(resp.message, "200");
            if (this.state.cartList.length === 1 && this.state.page > 1) {
                let pageNumber = 1;
                this.setState({ page: pageNumber }, () => this.getCartList());
            }
            else {
                this.getCartList();
            }
        }
        else {
            this.alert(resp.message, "500");
        }
    }

    async changeCartAddress(addressID) {
        let postData = {
            "cart_id": this.selectedCartId,
            "address_id": addressID
        }
        let resp = await postApi(postData, urlApi.cartAddressUpdate);
        if (resp.responsecode === "200") {
            this.selectedAddressOfCart = addressID;
            this.getAddressList();
            this.addressChangeModalHide();
            this.alert(resp.message, "200");
        }
        else {
            this.alert(resp.message, "500");
        }
    }

    hideSideBar() {
        this.setState({ sideBarShow: false })
    }

    showChatSideBar(data) {
        this.currentChatId = data.chat_id;
        this.setState({ chatSideBarShow: true }, () => {
            this.getChatDetails((data.chat_id).toString());
            this.getOldMessage((data.chat_id).toString());
        });
    }

    hideChatSideBar() {
        document.getElementById("chat-name").innerHTML = null;
        this.setState({ chatSideBarShow: false })
    }

    async getChatDetails(chat_id) {
        this.chatDetails = [];
        this.setState({ chatUserData: [] });
        let postData = {
            "chat_id": chat_id
        }
        socketService.emitFunc("chat-fetch", JSON.stringify(postData));
    }

    async getOldMessage(chat_id) {
        this.setState({ chatMsgList: [] });
        let postData = {
            "chat_id": chat_id
        }
        socketService.emitFunc("msg-list-fetch", JSON.stringify(postData));
        socketService.emitFunc("join-admin", JSON.stringify(postData));
    }

    componentWillUnmount() {
        PubSub.unsubscribe("CHAT DETAILS");
        PubSub.unsubscribe("MSG LIST RCV");
        PubSub.unsubscribe("MSG RCV");
    }

    showSetDefaultConfirmationModal(data) {
        this.addressId = data.address_id;
        this.setState({ setDefaultConfirmationModalShow: true });
    }
    hideSetDefaultConfirmationModal() {
        this.setState({ setDefaultConfirmationModalShow: false });
    }

    showDeleteModal(data) {
        this.setState({ deleteModalShow: true });
        this.addressId = data.address_id;
    }

    hideDeleteModal() {
        this.setState({ deleteModalShow: false });
    }

    fileOnChange = async (e) => {
        await this.setState({ picUpload: e.target.files[0] });
        document.getElementById("pic-preview").src = URL.createObjectURL(e.target.files[0]);
    }

    async handlePageChangeForCart(pageNumber) {
        await this.setState({ page: pageNumber });
        this.getCartList();
    }

    async handlePageChangeForOrder(pageNumber) {
        await this.setState({ page: pageNumber });
        this.getOrderList();
    }

    alert = (msg, responseCode) => {
        if (responseCode === "200") {
            toast.success(msg);
        }
        else if (responseCode === "500") {
            toast.error(msg);
        }
        else {
            toast.info(msg);
        }
    }

    async productDetailsCartViewModalShow(data) {
        let addons = JSON.parse(data.cart_add_on_ids);
        let decors = JSON.parse(data.cart_decor_ids);
        let addonTempList = [];
        let decorTempList = [];
        this.allAddonDecorListCart = [];
        for (let i = 0; i < addons.length; i++) {
            let respAddon = await getApi(urlApi.addonDetailsByAddonId + addons[i]);
            if (respAddon.responsecode === "200") {
                addonTempList.push(respAddon.data);
            }
        }
        for (let j = 0; j < decors.length; j++) {
            let respDecor = await getApi(urlApi.decorDetailsByDecorId + decors[j]);
            if (respDecor.responsecode === "200") {
                decorTempList.push(respDecor.data);
            }
        }
        this.allAddonDecorListCart = [...this.allAddonDecorListCart, ...[...addonTempList], ...[...decorTempList]];
        this.setState({ productDetailsCartModalShow: true }, async () => {
            let resp = await getApi(urlApi.productDetailsByProductId + data.product_id);
            if (resp.responsecode === "200") {
                this.setState({ singleProductDetailsCart: resp.data })
            }
        });
    }

    productDetailsCartViewModalHide() {
        this.setState({ productDetailsCartModalShow: false });
    }

    componentDidMount() {
        PubSub.subscribe("CHAT DETAILS", (msg, data) => {
            this.chatDetails = data.data.chat_details;
            this.setState({ chatDetails: data.data });
            this.setState({ chatUserData: data.data.chat_user });
        });

        PubSub.subscribe("MSG LIST RCV", (msg, data) => {
            this.setState({ chatMsgList: data.data.message_list.reverse() });
        });

        PubSub.subscribe("MSG RCV", (msg, data) => {
            if (this.currentChatId === data.chat_id) {
                let newList = [...this.state.chatMsgList, data];
                this.setState({ chatMsgList: newList });
            }
        });
        this.getCustomerDetails();
        if (this.props.match.params.defaultActiveKey === "userDetails" || this.props.match.params.defaultActiveKey === "edit") {
            this.getCustomerDetails();
        }
        if (this.props.match.params.defaultActiveKey === "address") {
            this.getAddressList();
        }
        if (this.props.match.params.defaultActiveKey === "cartDetails") {
            this.getCartList();
        }
        if (this.props.match.params.defaultActiveKey === "orderDetails") {
            this.getOrderList();
        }
    }

    async getCustomerDetails() {
        let resp = await getApi(urlApi.customerDetailsByCustomerID + this.props.match.params.customerID);
        if (resp.responsecode === "200") {
            await this.setState({ userData: resp.data }, () => {
                // document.getElementById("edit-customer-name").value = this.state.userData.name;
                document.getElementById("edit-customer-email").value = this.state.userData.email;
                document.getElementById("edit-customer-description").value = this.state.userData.description;
                document.getElementById("pic-preview").src = this.state.userData.profile_pic;
            });

        }
        else {
            this.alert(resp.message, "500");
        }
    }

    async updateUser() {
        this.setState({ isComplete: false });
        if (this.state.picUpload) {
            const formData = new FormData();
            formData.append(
                "file_data", this.state.picUpload,
            );
            let respPic = await postApi(formData, urlApi.customerPicUpload);
            if (respPic.responsecode === "200") {
                let postData = {
                    "customer_id": this.props.match.params.customerID,
                    "name": document.getElementById("edit-customer-name").value,
                    "email": document.getElementById("edit-customer-email").value,
                    "description": document.getElementById("edit-customer-description").value,
                    "profile_pic": respPic.data,
                }
                let resp = await postApi(postData, urlApi.customerUpdate);
                if (resp.responsecode === "200") {
                    this.setState({ isComplete: true });
                    this.alert(resp.message, "200");
                    this.getCustomerDetails();
                }
            }
            else {
                this.alert(respPic.message, "500")
            }
        } else {
            let postData = {
                "customer_id": this.props.match.params.customerID,
                "name": document.getElementById("edit-customer-name").value,
                "email": document.getElementById("edit-customer-email").value,
                "description": document.getElementById("edit-customer-description").value,
                "profile_pic": document.getElementById("pic-preview").src,
            }
            let resp = await postApi(postData, urlApi.customerUpdate);
            if (resp.responsecode === "200") {
                this.setState({ isComplete: true });
                this.alert(resp.message, "200");
                this.getCustomerDetails();
            }
        }
    }

    async getAddressList() {
        let resp = await getApi(urlApi.customerAddressList + this.props.match.params.customerID);
        if (resp.responsecode === "200") {
            this.setState({ addressList: resp.data });
        }
    }

    async setDefaultAddress() {
        let postData = {
            "customer_id": this.props.match.params.customerID,
            "address_id": this.addressId
        }
        let res = await postApi(postData, urlApi.customerDefaultAddressUpdate);
        this.hideSetDefaultConfirmationModal();
        if (res.responsecode === "200") {
            this.getAddressList();
            this.getCustomerDetails();
            this.alert(res.message, res.responsecode);
        }
        else {
            this.alert(res.message, res.responsecode);
        }
    }

    async getCartList() {
        await this.setState({ page: 1 }, async () => {
            let postData = {
                "search_data": document.getElementById("search-field-cart").value,
                "customer_id": this.props.match.params.customerID
            }
            let resp = await postApi(postData, urlApi.cartListByCustomerID + this.state.page);
            if (resp.responsecode === "200") {
                this.setState({ cartList: resp.data.data });
                this.setState({ pagination: resp.data.pagination });
            } else {
                this.resetTable();
            }
        });
    }

    async updateCart() {
        let postData = {
            "cart_id": document.getElementById("cart-id").value,
            "customer_id": this.props.match.params.customerID,
            "preference": document.getElementById("cart-edit-preference").value,
            "quantity": document.getElementById("cart-edit-quantity").value,
            "delivery_date": document.getElementById("cart-delivery-date").value,
            "customer_note": document.getElementById("cart-customer-note").value
        }
        let resp = await postApi(postData, urlApi.cartUpdate);
        this.editCartModalHide();
        if (resp.responsecode === "200") {
            this.alert(resp.message, "200");
            this.getCartList();
        }
        else {
            this.alert(resp.message, "500")
        }
    }

    async onSearchCart() {
        await this.setState({ page: 1 }, () => this.getCartList());
    }

    async resetTable() {
        await this.setState({ orderList: [] });
        await this.setState({ cartList: [] });
        await this.setState({
            pagination: ({
                active_page: 0,
                per_page: 10,
                total_items: 0
            })
        })
    }

    dateFilterVar = "";

    async getOrderList({ dateFilter = this.dateFilterVar } = {}) {
        this.dateFilterVar = dateFilter;
        this.setState({ page: 1 });
        let postData = {
            "customer_id": this.props.match.params.customerID,
            "search_data": document.getElementById("search-field-order").value,
            "status": document.getElementById("status-dropdown-order").value,
            "date": dateFilter,
            "fromDate": document.getElementById("from-date-filter").value,
            "toDate": document.getElementById("to-date-filter").value
        }
        let resp = await postApi(postData, urlApi.orderListByCustomerID + this.state.page);
        if (resp.responsecode === "200") {
            this.setState({ orderList: resp.data.data });
            this.setState({ pagination: resp.data.pagination });
        }
        else {
            this.resetTable();
        }
    }

    async onSearchOrder() {
        await this.setState({ page: 1 }, () => this.getOrderList());
    }

    showStatusUpdateModal(data, status) {
        this.bakerID = data.baker_id;
        this.orderID = data.order_id;
        this.status = status;
        this.setState({ statusUpdateModalShow: true });
    }

    hideStatusUpdateModal() {
        this.setState({ statusUpdateModalShow: false })
    }

    async updateOrderStatus(status, orderID, bakerID) {
        let postData = {
            "status": status,
            "order_id": orderID,
            "baker_id": bakerID
        }
        let resp = await postApi(postData, urlApi.orderStatusUpdate);
        this.hideStatusUpdateModal();
        if (resp.responsecode === "200") {
            this.getOrderList();
            this.alert(resp.message, resp.responsecode);
        }
    }

    async sendNotification() {
        this.setState({ isComplete: false });
        if (this.state.picUpload) {
            const formData = new FormData();
            formData.append(
                "file_data", this.state.picUpload,
            );
            let respPic = await postApi(formData, urlApi.notificationPicUpload);
            if (respPic.responsecode === "200") {
                let postData = {
                    "type": "SINGLE_CUSTOMER",
                    "title": document.getElementById('title').value,
                    "msg": document.getElementById('message').value,
                    "user_id": this.props.match.params.customerID,
                    "image": respPic.data,
                }
                let resp = await postApi(postData, urlApi.sendNotification);
                this.hideSendNotification();
                if (resp.responsecode === '200') {
                    this.setState({ isComplete: true });
                    this.alert(resp.message, resp.responsecode);
                    document.getElementById('title').value = null;
                    document.getElementById('message').value = null;
                    document.getElementById("pic-preview").src = null;
                } else {
                    this.alert(resp.message, resp.responsecode);
                }
            } else {
                this.alert(respPic.message, respPic.responsecode);
            }
        }
        else {
            let postData = {
                "type": "SINGLE_CUSTOMER",
                "title": document.getElementById('title').value,
                "msg": document.getElementById('message').value,
                "user_id": this.props.match.params.customerID,
            }
            let resp = await postApi(postData, urlApi.sendNotification);
            this.hideSendNotification();
            if (resp.responsecode === '200') {
                this.setState({ isComplete: true });
                this.alert(resp.message, resp.responsecode);
                document.getElementById('title').value = null;
                document.getElementById('message').value = null;
                document.getElementById("pic-preview").src = null;
            } else {
                this.alert(resp.message, resp.responsecode);
            }
        }
    }

    render() {
        var xOrder = (this.state.pagination.active_page - 1) * this.state.pagination.per_page + 1;
        var xCart = (this.state.pagination.active_page - 1) * this.state.pagination.per_page + 1;
        return (
            <>
                <div className="container-fluid position-relative bg-white d-flex p-0">
                    <SidebarMenu isOpen={this.state.isOpen} />
                    <div className={this.state.isOpen ? "content open" : "content"}>
                        <Navbar menuBarClick={() => this.menuBarClick()} />
                        <div className="container-fluid">
                            <ToastContainer autoClose={1000} />
                            <div className="row g-4">
                                <div className="col-12">
                                    <div className="bg-light rounded h-100 p-2">
                                        <Tabs defaultActiveKey={this.props.match.params.defaultActiveKey ? this.props.match.params.defaultActiveKey : "userDetails"}
                                            id="uncontrolled-tab-example"
                                            className="mb-3"
                                            onSelect={(e) => {
                                                this.props.history.push('/single-customer-view/' + e + "/" + this.props.match.params.customerID);
                                                if (e === "userDetails" || e === "edit") {
                                                    this.getCustomerDetails();
                                                }
                                                if (e === "address") {
                                                    this.getAddressList();
                                                }
                                                if (e === "cartDetails") {
                                                    this.getCartList();
                                                }
                                                if (e === "orderDetails") {
                                                    this.getOrderList();
                                                }
                                            }
                                            }
                                        >
                                            <Tab eventKey="userDetails" title={<Link className='tab-link-color' to={"/single-customer-view/userDetails/" + this.props.match.params.customerID}>User Details</Link>}>
                                                <div className="row mb-3 mt-md-5" style={{ marginTop: 90 }}>
                                                    <div className="col text-center">
                                                        <img src={this.state.userData.profile_pic}
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null;
                                                                currentTarget.src = image;
                                                            }} alt=""
                                                            style={{ height: 200, width: 200, borderRadius: "50%", objectFit: 'cover', border: '2px solid #f08632' }} />
                                                    </div>
                                                    <div className="col d-flex flex-column justify-content-between">
                                                        <h4>{this.state.userData.name} [ ID : {this.props.match.params.customerID} ]</h4>
                                                        <h6>Mobile: {this.state.userData.mobile}</h6>
                                                        <p className='m-0'><span style={{ fontWeight: 600 }}>Email</span> : {this.state.userData.email}</p>
                                                        <p className='m-0'><span style={{ fontWeight: 600 }}>Address :</span> {this.state.userData.address_details}</p>
                                                        <p className='m-0'><span style={{ fontWeight: 600 }}>PinCode :</span> {this.state.userData.pincode}</p>
                                                        <p className='m-0'><span style={{ fontWeight: 600 }}>Landmark :</span> {this.state.userData.landmark}</p>
                                                    </div>
                                                    <div className="col d-flex flex-column justify-content-between">
                                                        <p className='m-0'><span style={{ fontWeight: 600 }}>User Status :</span> <span className={this.state.userData.status === 1 ? 'badge bg-success' : 'badge bg-secondary'}>{this.state.userData.status === 1 ? "Active" : "Inactive"}</span></p>
                                                        <p className='m-0'><span style={{ fontWeight: 600 }}>Description : </span>{this.state.userData.description}</p>
                                                        <p className='m-0'><span style={{ fontWeight: 600 }}>Create Date :</span> {dateTimeConverter(this.state.userData.create_date)}</p>
                                                        <p className='m-0'><span style={{ fontWeight: 600 }}>Last Login :</span> {dateTimeConverter(this.state.userData.lastLogin)}</p>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-2 ms-auto">
                                                        <button className='w-100 btn-custom action-btn-3' onClick={() => this.showSendNotification()}>Send Notification</button>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="edit" title={<Link className='tab-link-color' to={"/single-customer-view/edit/" + this.props.match.params.customerID}>Edit</Link>}>
                                                <div className="row mt-md-5" style={{ marginTop: 90 }}>
                                                    <div className="col-md-6 ms-auto me-auto">
                                                        <form>
                                                            <input id='user-id' type="text" style={{ display: "none" }} />
                                                            <div className="form-group mb-2">
                                                                <label htmlFor="edit-customer-name">Customer Name</label>
                                                                <div className="input-group input-group-sm">
                                                                    <input id="edit-customer-name" type="text" defaultValue={this.state.userData.name} className="form-control form-control-sm" />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text">
                                                                            <span><TbUser /></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-2">
                                                                <label htmlFor="edit-customer-email">Email</label>
                                                                <div className="input-group input-group-sm">
                                                                    <input id="edit-customer-email" type="text" className="form-control" />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text">
                                                                            <span><TbMail /></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-2">
                                                                <label htmlFor="edit-customer-description">Description</label>
                                                                <div className="input-group input-group-sm">
                                                                    <input id="edit-customer-description" type="text" className="form-control" />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text">
                                                                            <span><TbFileDescription /></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-2">
                                                                <label htmlFor="">Profile Picture :&nbsp;&nbsp;&nbsp;</label>
                                                                <img id="pic-preview" style={{ maxHeight: 60, borderRadius: 5 }} alt="" />
                                                            </div>
                                                            <div className="input-group mb-2">
                                                                <input id="user-edit-photo" type="file" className="form-control form-control-sm" onChange={this.fileOnChange} />
                                                            </div>
                                                        </form>
                                                        <div className="row">
                                                            <div className="col-6 ms-auto">
                                                                <button disabled={!this.state.isComplete} className='btn-custom w-100 action-btn-1 fw-bold' onClick={() => this.updateUser()}>{!this.state.isComplete && (
                                                                    <span className="spinner-grow spinner-grow-sm"></span>
                                                                )}UPDATE</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="address" title={<Link className='tab-link-color' to={"/single-customer-view/address/" + this.props.match.params.customerID}>Address</Link>}>
                                                {this.state.addressList.length > 0 ? <div className="row mt-md-5" style={{ marginTop: 90 }}>
                                                    {this.state.addressList.map((data, index) => {
                                                        return (
                                                            <div className="col-md-6" key={index}>
                                                                <AddressComponent
                                                                    btn2Function={() => this.showSideBar(data)}
                                                                    cursor="pointer"
                                                                    name={data.name}
                                                                    addressDetails={data.address_details}
                                                                    pinCode={data.pincode}
                                                                    mobile={data.mobile}
                                                                    landmark={data.landmark}
                                                                    btnText1="Delete"
                                                                    btnText2="Edit"
                                                                    btnText3={data.address_id === this.state.userData.default_address ? null : "Set Default"}
                                                                    isDefault={data.address_id === this.state.userData.default_address ? true : false}
                                                                    setDefaultFunction={() => this.showSetDefaultConfirmationModal(data)}
                                                                    function1={() => this.showDeleteModal(data)}
                                                                />
                                                            </div>
                                                        )
                                                    })}
                                                </div> :
                                                    <div className="no-data text-center my-5 mt-md-5" style={{ marginTop: 90 }}>
                                                        <h4 className='text-danger'>Sorry! No Address Found!</h4>
                                                    </div>}
                                            </Tab>
                                            <Tab eventKey="cartDetails" title={<Link className='tab-link-color' to={"/single-customer-view/cartDetails/" + this.props.match.params.customerID}>Cart</Link>}>
                                                <div className="row g-4 mt-md-3" style={{ marginTop: 60 }}>
                                                    <div className="col-12">
                                                        <div className="bg-light rounded h-100 p-2">
                                                            <h6 className="mb-2">Cart Details</h6>
                                                            <form onSubmit={(e) => { e.preventDefault(); this.onSearchCart() }}>
                                                                <div className="col-12 ">
                                                                    <div className="row gx-1 search-section">
                                                                        <div className="col-md-3 col-10">
                                                                            <input className='form-control form-control-sm w-100' id='search-field-cart' placeholder='Shop Name / Product Name' />
                                                                        </div>
                                                                        <div className="col-md-2 col-2">
                                                                            <button className='btn-custom action-btn-5 w-sm-100' type='submit'><TbSearch size={20} /></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                            {this.state.cartList.length > 0 ?
                                                                <div className="table-responsive">
                                                                    <table className="table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="col">#</th>
                                                                                <th className="col">Shop Name</th>
                                                                                <th className="col">Product Name</th>
                                                                                <th className="col">Address</th>
                                                                                <th className="col">Quantity</th>
                                                                                <th className="col">Customer Note</th>
                                                                                <th className="col">Delivery</th>
                                                                                <th className="col">Status</th>
                                                                                <th className="col">Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {this.state.cartList.map((data, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <th scope="row">{xCart++}</th>
                                                                                        <td className='text-left'>
                                                                                            <Link to={"/single-shop-view/" + data.baker_id} target="_blank">
                                                                                                <span className='table-img'><img src={data.shop_image} alt="" /></span><br />
                                                                                                <span>{data.shop_name}</span>
                                                                                            </Link>
                                                                                        </td>
                                                                                        <td className='text-left'>
                                                                                            <span className='table-img'><img src={data.picture} alt="" /></span><br />
                                                                                            <span>
                                                                                                {data.preference !== 3 ?
                                                                                                    <TbSquareDot color={data.preference === 2 ? 'red' : data.preference === 1 ? 'green' : 'grey'} /> :
                                                                                                    <span><TbSquareDot color='red' />
                                                                                                        <TbSquareDot color='green' /> </span>}
                                                                                                {data.name}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td>{data.address_details}</td>
                                                                                        <td>{data.quantity} Pcs</td>
                                                                                        <td>{data.customer_note ?? "--"}</td>
                                                                                        <td>{data.min_delivery_day}</td>
                                                                                        <td>
                                                                                            <Switch onChange={() => this.updateCartStatus(data.cart_id, data.status === 1 ? 0 : 1)} checked={data.status === 1 ? true : false} />
                                                                                        </td>
                                                                                        <td>
                                                                                            <button className='btn-custom action-btn-1 m-1' onClick={() => this.editCartModalShow(data)}>Edit</button>
                                                                                            <button className='btn-custom action-btn-2 m-1' onClick={() => this.addressChangeModalShow(data)}>Change Address</button>
                                                                                            <button className='btn-custom action-btn-3 m-1' onClick={() => this.productDetailsCartViewModalShow(data)}>Product Details</button>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                :
                                                                <div className="no-data text-center my-5">
                                                                    <h4 className='text-danger'>Sorry! No Cart Found!</h4>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='me-2' style={{ float: 'right', display: this.state.cartList.length > 0 ? 'unset' : 'none' }}>
                                                    <Pagination
                                                        activePage={parseInt(this.state.pagination.active_page)}
                                                        itemsCountPerPage={this.state.pagination.per_page}
                                                        totalItemsCount={this.state.pagination.total_items}
                                                        pageRangeDisplayed={5}
                                                        onChange={this.handlePageChangeForCart.bind(this)}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </Tab>
                                            <Tab eventKey="orderDetails" title={<Link className='tab-link-color' to={"/single-customer-view/orderDetails/" + this.props.match.params.customerID}>Order Details</Link>}>
                                                <div className="row g-4 mt-md-3" style={{ marginTop: 60 }}>
                                                    <div className="col-12">
                                                        <div className="bg-light rounded h-100 p-2">
                                                            <h6 className="mb-2">Order Details || Total : {this.state.pagination.total_items}</h6>
                                                            <div className="row mb-2 gx-2">
                                                                <div className="col-md-1 col mb-1">
                                                                    <button className='btn-custom action-btn-5 w-100' onClick={() => this.getOrderList({ dateFilter: "" })}>All</button>
                                                                </div>
                                                                <div className="col-md-1 col mb-1">
                                                                    <button className='btn-custom action-btn-1 w-100' onClick={() => this.getOrderList({ dateFilter: "DAILY" })}>Today</button>
                                                                </div>
                                                                <div className="col-md-1 col mb-1">
                                                                    <button className='btn-custom action-btn-4 w-100' onClick={() => this.getOrderList({ dateFilter: "YESTERDAY" })}>Yesterday</button>
                                                                </div>
                                                                <div className="col-md-1 col mb-1">
                                                                    <button className='btn-custom action-btn-3 w-100 text-nowrap' onClick={() => this.getOrderList({ dateFilter: "WEEKLY" })}>Last Week</button>
                                                                </div>
                                                                <div className="col-md-1 col mb-1">
                                                                    <button className='btn-custom action-btn-2 w-100 text-nowrap' onClick={() => this.getOrderList({ dateFilter: "MONTHLY" })}>Last Month</button>
                                                                </div>
                                                            </div>
                                                            <div className="row gx-2 mb-2">
                                                                <div className="col-md-2 col-5 mb-2">
                                                                    <input type="date" className='form-control form-control-sm w-100' id='from-date-filter' />
                                                                </div>
                                                                <div className="col-md-1 mb-2 col text-center"> <BsArrowLeftRight /> </div>
                                                                <div className="col-md-2 col-5 mb-2">
                                                                    <input type="date" className='form-control form-control-sm w-100' id='to-date-filter' />
                                                                </div>
                                                                <div className="col-md-1 col">
                                                                    <button className='btn-custom action-btn-1 w-100' onClick={() => this.getOrderList({ dateFilter: "CUSTOM" })}>Search</button>
                                                                </div>
                                                                <div className="col-md-2 col-2">
                                                                    <button className='btn-custom action-btn-5 w-sm-100' onClick={() => this.resetFields()}><GrPowerReset size={20} /></button>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mb-3">
                                                                <form onSubmit={(e) => { e.preventDefault(); this.onSearchOrder() }}>
                                                                    <div className="row gx-1 search-section">
                                                                        <div className="col-md-3 col-6">
                                                                            <input className='form-control form-control-sm w-100' id='search-field-order' placeholder='Order ID' />
                                                                        </div>
                                                                        <div className="col-md-3 col-4">
                                                                            <select id='status-dropdown-order' className='form-control form-control-sm w-100' style={{ width: 80 }} defaultValue={6}>
                                                                                <option value={6}>ALL Orders</option>
                                                                                <option value={0}>Pending</option>
                                                                                <option value={1}>Accepted</option>
                                                                                <option value={2}>Rejected</option>
                                                                                <option value={3}>Chat</option>
                                                                                <option value={4}>Finalized</option>
                                                                                <option value={5}>Delivered</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="col-md-2 col-2">
                                                                            <button className='btn-custom action-btn-5 w-sm-100' type='submit'><TbSearch size={20} /></button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            {this.state.orderList.length > 0 ? <div className="table-responsive">
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="col">#</th>
                                                                            <th className="col">Order ID</th>
                                                                            <th className="col">Product Name</th>
                                                                            <th className="col">Price</th>
                                                                            <th className="col">Shop Name</th>
                                                                            <th className="col">Address</th>
                                                                            <th className="col">Customer Note</th>
                                                                            <th className="col">Baker Note</th>
                                                                            <th className="col">Delivery</th>
                                                                            <th className="col">Created</th>
                                                                            <th className="col">Modified</th>
                                                                            <th className="col">Status</th>
                                                                            <th className="col">Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.orderList.map((data, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <th scope="row">{xOrder++}</th>
                                                                                    <td>1000{data.order_id}</td>
                                                                                    <td className='text-left'>
                                                                                        <span className='table-img'><img src={data.picture} alt="" /></span><br />
                                                                                        <span>
                                                                                            {data.preference !== 3 ?
                                                                                                <TbSquareDot color={data.preference === 2 ? 'red' : data.preference === 1 ? 'green' : 'grey'} /> :
                                                                                                <span><TbSquareDot color='red' />
                                                                                                    <TbSquareDot color='green' />
                                                                                                </span>}
                                                                                            {data.name}</span>
                                                                                            <span className='fw-bold'> X {data.quantity}</span>
                                                                                    </td>
                                                                                    <td>₹{data.price - data.coupon_value} <br /> <span style={{ display: data.coupon_code !== "NONE" ? 'unset' : 'none' }}>Coupon : {data.coupon_code}</span> </td>
                                                                                    <td className='text-left'>
                                                                                        <Link to={"/single-shop-view/" + data.baker_id} target="_blank">
                                                                                            <span className='table-img'><img src={image} alt="" /></span><br />
                                                                                            <span>{data.shop_name}</span>
                                                                                        </Link>
                                                                                    </td>
                                                                                    <td>{data.address_details.address_details}</td>
                                                                                    <td> {data.customer_note ?? "--"}</td>
                                                                                    <td>{data.baker_note ?? "--"}</td>
                                                                                    <td>{dateTimeConverter(data.delivery_time)}</td>
                                                                                    <td>{dateTimeConverter(data.create_date)}</td>
                                                                                    <td>
                                                                                        <span>{dateTimeConverter(data.modify_date)}</span><br />
                                                                                        <span>{data.modified_by === data.baker_id ? data.shop_name : data.customer_name}</span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <select value={data.order_status} onChange={(e) => this.showStatusUpdateModal(data, e.target.value)} className={`form-control fw-bold form-control-sm text-${(data.order_status === 0 || data.order_status === 3) ? "dark" : "white"} bg-${data.order_status === 0 ? "info" : data.order_status === 1 ? "success" : data.order_status === 2 ? "danger" : data.order_status === 3 ? "warning" : data.order_status === 4 ? "primary" : "secondary"}`} style={{ width: 80 }}>
                                                                                            <option value={0}>Pending</option>
                                                                                            <option value={1}>Accepted</option>
                                                                                            <option value={2}>Rejected</option>
                                                                                            <option value={3}>Chat</option>
                                                                                            <option value={4}>Finalized</option>
                                                                                            <option value={5}>Delivered</option>
                                                                                        </select>
                                                                                    </td>
                                                                                    <td>
                                                                                        <button className='btn-custom action-btn-2 m-1' onClick={() => this.showChatSideBar(data)}>Chat</button>
                                                                                        <button className='btn-custom action-btn-4 m-1' onClick={() => this.productDetailsViewModalShow(data)}>Product Details</button>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div> :
                                                                <div className="no-data text-center my-5">
                                                                    <h4 className='text-danger'>Sorry! No Order Found!</h4>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='me-2' style={{ float: 'right', display: this.state.orderList.length > 0 ? 'unset' : 'none' }}>
                                                    <Pagination
                                                        activePage={parseInt(this.state.pagination.active_page)}
                                                        itemsCountPerPage={this.state.pagination.per_page}
                                                        totalItemsCount={this.state.pagination.total_items}
                                                        pageRangeDisplayed={5}
                                                        onChange={this.handlePageChangeForOrder.bind(this)}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.editCartModalShow} onHide={() => this.editCartModalHide()}>
                    <Modal.Header closeButton style={{ display: "none" }}>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: "10px 14px" }}>
                        <div className="text-center"><h3 style={{ borderBottom: "4px solid teal" }}>Edit Cart</h3></div>
                        <form>
                            <input id='cart-id' type="text" style={{ display: "none" }} />
                            <div className="row">
                                <div className="col">
                                    <div className="form-group mb-2">
                                        <label htmlFor="cart-edit-preference">Preference</label>
                                        <div className="input-group input-group-sm">
                                            <select className='form-control' id='cart-edit-preference'>
                                                <option value="2">Veg</option>
                                                <option value="1">Non-Veg</option>
                                            </select>
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span><TbCaretDown /></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group mb-2">
                                        <label htmlFor="cart-edit-quantity">Quantity</label>
                                        <div className="input-group input-group-sm">
                                            <input className='form-control' id='cart-edit-quantity' />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span><FaWeightHanging /></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="cart-delivery-date">Delivery Date</label>
                                <div className="input-group input-group-sm">
                                    <input type="date" className='form-control' id='cart-delivery-date' />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span><TbCalendar /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="cart-customer-note">Customer Note</label>
                                <div className="input-group input-group-sm">
                                    <input className='form-control' id='cart-customer-note' />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span><TbNote /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='btn-custom action-btn-2' onClick={() => this.editCartModalHide()}>
                            Close
                        </Button>
                        <Button className='btn-custom action-btn-1' onClick={() => this.updateCart()}>
                            Update
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.addressChangeModalShow} onHide={() => this.addressChangeModalHide()}>
                    <Modal.Header closeButton style={{ display: "none" }}>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: "10px 14px" }}>
                        <div className="text-center"><h3 style={{ borderBottom: "4px solid teal" }}>Address List</h3></div>
                        {this.state.addressList.map((data, index) => {
                            return (
                                <AddressComponent key={index}
                                    btn2Function={() => this.changeCartAddress(data.address_id)}
                                    cursor="pointer"
                                    name={data.name}
                                    addressDetails={data.address_details}
                                    pinCode={data.pincode}
                                    mobile={data.mobile}
                                    landmark={data.landmark}
                                    btnText2={data.address_id === this.selectedAddressOfCart ? null : "Select"}
                                    isDefault={data.address_id === this.selectedAddressOfCart ? true : false}
                                />
                            )
                        })}
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'none' }}>
                        <Button className='action-btn-2' onClick={() => this.addressChangeModalHide()}>
                            Close
                        </Button>
                        <Button className='action-btn-1' onClick={() => this.addressChangeModalHide()}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>

                <ProductDetailsModalComponent
                    show={this.state.productDetailsModalShow}
                    productData={this.state.singleProductSDetail}
                    addonDecorData={this.allAddonDecorList}
                    productDetailsViewModalHide={() => this.productDetailsViewModalHide()}
                />

                <ProductDetailsModalComponent
                    show={this.state.productDetailsCartModalShow}
                    productDetailsViewModalHide={() => this.productDetailsCartViewModalHide()}
                    productData={this.state.singleProductDetailsCart}
                    addonDecorData={this.allAddonDecorListCart}
                />

                <Offcanvas show={this.state.sendNotificationShow} onHide={() => this.hideSendNotification()} placement={'end'}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Send Notification</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="form-group mb-2">
                            <label htmlFor="">Title</label>
                            <input id='title' className='form-control' />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="">Message</label>
                            <textarea id='message' className='form-control'></textarea>
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="">Picture :&nbsp;&nbsp;&nbsp;</label>
                            <img id="pic-preview" style={{ maxHeight: 80, width: 'auto', borderRadius: 5 }} alt="" />
                        </div>
                        <div className="input-group mb-2">
                            <input id="edit-photo" type="file" className="form-control form-control-sm" onChange={this.fileOnChange} />
                        </div>
                        <button className='btn-custom action-btn-1 w-100 mt-2' onClick={() => this.sendNotification()}>Send</button>
                    </Offcanvas.Body>
                </Offcanvas>

                <Offcanvas show={this.state.sideBarShow} onHide={() => this.hideSideBar()} placement={'end'}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Edit Address</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <form>
                            <input id='address-id' type="text" style={{ display: "none" }} />
                            <div className="form-group mb-2">
                                <label htmlFor="">Name</label>
                                <div className="input-group input-group-sm">
                                    <input id="edit-address-name" type="text" className="form-control form-control-sm" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span><TbUser /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="">Mobile</label>
                                <div className="input-group input-group-sm">
                                    <input id="edit-address-mobile" type="text" className="form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span><TbPhoneCall /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="">Address</label>
                                <div className="input-group input-group-sm">
                                    <input id="edit-address-details" type="text" className="form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span><TbLocation /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="">PinCode</label>
                                <div className="input-group input-group-sm">
                                    <input id="edit-address-pincode" type="text" className="form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span><TbCurrentLocation /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="">Landmark</label>
                                <div className="input-group input-group-sm">
                                    <input id="edit-address-landmark" type="text" className="form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span><TbPin /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="row">
                            <div className="col">
                                <button className='btn-custom action-btn-1 w-100 fw-bold mt-3' onClick={() => this.updateAddress()}>UPDATE</button>
                            </div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>

                <Offcanvas show={this.state.chatSideBarShow} onHide={() => this.hideChatSideBar()} placement={'end'}>
                    <Offcanvas.Header closeButton style={{ height: 72 }}>
                        <Offcanvas.Title>
                            <span className='table-img'>
                                <img src={this.chatDetails.chat_logo} alt="" />
                            </span>
                            <span id='chat-name'>{this.chatDetails.chat_name}</span>
                        </Offcanvas.Title>

                    </Offcanvas.Header>
                    <Offcanvas.Body className='p-0'>
                        {this.state.chatUserData.length > 0 ?
                            <div className="col-12 mb-1 d-flex justify-content-between">
                                <div className="px-2" style={{ display: this.state.chatUserData.length > 0 ? "unset" : "none" }}>
                                    <img src={this.state.chatUserData.length > 0 ? this.state.chatUserData[0].user_pic : "ProductImage"} alt="" style={{ height: 30, width: 30, objectFit: 'cover', borderRadius: 50 }} />
                                    {this.state.chatUserData.length > 0 ? this.state.chatUserData[0].user_name : ""}
                                </div>
                                <div className="" style={{ display: this.state.chatUserData.length > 1 ? "unset" : "none" }}>
                                    <img src={this.state.chatUserData.length > 1 ? this.state.chatUserData[1].user_pic : "ProductImage"} alt="" style={{ height: 30, width: 30, objectFit: 'cover', borderRadius: 50 }} />
                                    {this.state.chatUserData.length > 1 ? this.state.chatUserData[1].user_name : ""}
                                </div>
                            </div>
                            :
                            <div className="col-12">
                                No One Joined The Chat Yet!
                            </div>
                        }
                        <ChatView
                            chatUserData={this.state.chatUserData}
                            chatMsgList={this.state.chatMsgList}
                        />
                    </Offcanvas.Body>
                </Offcanvas>

                <ConfirmationModal
                    show={this.state.setDefaultConfirmationModalShow}
                    headerText="Set Default"
                    bodyText="Are You Sure Want To Set This Address As Default Address?"
                    closeFunction={() => this.hideSetDefaultConfirmationModal()}
                    okFunction={() => this.setDefaultAddress()}
                />

                <ConfirmationModal
                    show={this.state.deleteModalShow}
                    headerText="Delete Address"
                    bodyText="Are You Sure Want To Delete This Address?"
                    closeFunction={() => this.hideDeleteModal()}
                    okFunction={() => this.deleteAddress()}
                />

                <ConfirmationModal
                    show={this.state.statusUpdateModalShow}
                    headerText="Update Status"
                    bodyText="Are You Sure Want To Update Status?"
                    closeFunction={() => this.hideStatusUpdateModal()}
                    okFunction={() => this.updateOrderStatus(this.status, this.orderID, this.bakerID,)}
                />
            </>
        )
    }
}
