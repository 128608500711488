import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import SidebarMenu from '../components/SidebarMenu';
import Switch from "react-switch";
import { Button, Modal, Offcanvas } from 'react-bootstrap';
import { TbFileDescription, TbMail, TbSearch, TbUser } from 'react-icons/tb';
import AddressComponent from '../components/AddressComponent';
import { getApi, postApi } from '../api/call.api';
import urlApi from '../api/url.api';
import { toast, ToastContainer } from 'react-toastify';
import Pagination from 'react-js-pagination';
import { dateTimeConverter } from '../utils/dateTimeConverter';
import image from "../assets/images/profilePic.jpg"
import ConfirmationModal from '../components/ConfirmationModal';
import { BsArrowLeftRight } from 'react-icons/bs';
import { GrPowerReset } from 'react-icons/gr';

export default class AllCustomerView extends Component {
    constructor(p) {
        super(p);
        this.state = {
            isOpen: false,
            editModalShow: false,
            addressModalShow: false,
            sideBarShow: false,
            picUpload: '',
            tableData: [],
            addressList: [],
            pagination: ({
                active_page: 0,
                per_page: 10,
                total_items: 0
            }),
            page: 1,
            count: 10,
            setDefaultConfirmationModalShow: false,
            statusUpdateModalShow: false,
        }
        this.updateCustomerStatus = this.updateCustomerStatus.bind(this);
        this.defaultAddressId = null;
        this.addressId = null;
        this.customerIdStatic = null;
    }

    async resetTable() {
        await this.setState({ tableData: [] })
        await this.setState({
            pagination: ({
                active_page: 0,
                per_page: 10,
                total_items: 0
            })
        })
    }

    showStatusUpdateModal(customerID, status) {
        this.customerId = customerID;
        this.status = status;
        this.setState({ statusUpdateModalShow: true });
    }

    hideStatusUpdateModal() {
        this.setState({ statusUpdateModalShow: false })
    }

    menuBarClick() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    async editViewModalShow(customerData) {
        await this.setState({ editModalShow: true })
        document.getElementById("customer-id").value = customerData.customer_id;
        document.getElementById("edit-customer-name").value = customerData.name;
        document.getElementById("edit-customer-email").value = customerData.email;
        document.getElementById("edit-customer-description").value = customerData.description;
        document.getElementById("pic-preview").src = customerData.profile_pic;
    }
    editViewModalHide() {
        this.setState({ editModalShow: false })
    }

    async editModalYesButton() {
        if (this.state.picUpload) {
            const formData = new FormData();
            formData.append(
                "file_data", this.state.picUpload,
            );
            let respPic = await postApi(formData, urlApi.customerPicUpload);
            if (respPic.responsecode === "200") {
                let postData = {
                    "customer_id": document.getElementById("customer-id").value,
                    "name": document.getElementById("edit-customer-name").value,
                    "email": document.getElementById("edit-customer-email").value,
                    "description": document.getElementById("edit-customer-description").value,
                    "profile_pic": respPic.data,
                }
                let resp = await postApi(postData, urlApi.customerUpdate);
                if (resp.responsecode === "200") {
                    this.alert(resp.message, "200");
                    this.editViewModalHide();
                    this.getAllCustomerList();
                }
            }
            else {
                this.alert(respPic.message, "500")
            }
        } else {
            let postData = {
                "customer_id": document.getElementById("customer-id").value,
                "name": document.getElementById("edit-customer-name").value,
                "email": document.getElementById("edit-customer-email").value,
                "description": document.getElementById("edit-customer-description").value,
                "profile_pic": document.getElementById("pic-preview").src,
            }
            let resp = await postApi(postData, urlApi.customerUpdate);
            if (resp.responsecode === "200") {
                this.alert(resp.message, "200");
                this.editViewModalHide();
                this.getAllCustomerList();
            }
        }
    }

    addressViewModalShow(customerData) {
        this.setState({ addressList: [] });
        this.defaultAddressId = customerData.default_address;
        this.customerId = customerData.customer_id;
        this.setState({ addressModalShow: true }, () => this.getAddressList(customerData.customer_id));
    }
    addressViewModalHide() {
        this.setState({ addressModalShow: false })
    }

    showSideBar(data) {
        this.customerId = data.customer_id;
        this.setState({ sideBarShow: true })
    }

    hideSideBar() {
        this.setState({ sideBarShow: false })
    }

    async sendNotification() {
        this.setState({ isComplete: false });
        if (this.state.picUpload) {
            const formData = new FormData();
            formData.append(
                "file_data", this.state.picUpload,
            );
            let respPic = await postApi(formData, urlApi.notificationPicUpload);
            if (respPic.responsecode === "200") {
                let postData = {
                    "type": "SINGLE_CUSTOMER",
                    "title": document.getElementById('title').value,
                    "msg": document.getElementById('message').value,
                    "user_id": this.customerId,
                    "image": respPic.data,
                }
                let resp = await postApi(postData, urlApi.sendNotification);
                this.hideSideBar();
                if (resp.responsecode === '200') {
                    this.setState({ isComplete: true });
                    this.alert(resp.message, resp.responsecode);
                    document.getElementById('title').value = null;
                    document.getElementById('message').value = null;
                    document.getElementById("pic-preview").src = null;
                } else {
                    this.alert(resp.message, resp.responsecode);
                }
            } else {
                this.alert(respPic.message, respPic.responsecode);
            }
        }
        else {
            let postData = {
                "type": "SINGLE_CUSTOMER",
                "title": document.getElementById('title').value,
                "msg": document.getElementById('message').value,
                "user_id": this.customerId,
            }
            let resp = await postApi(postData, urlApi.sendNotification);
            this.hideSideBar();
            if (resp.responsecode === '200') {
                this.setState({ isComplete: true });
                this.alert(resp.message, resp.responsecode);
                document.getElementById('title').value = null;
                document.getElementById('message').value = null;
                document.getElementById("pic-preview").src = null;
            } else {
                this.alert(resp.message, resp.responsecode);
            }
        }
    }

    fileOnChange = async (e) => {
        await this.setState({ picUpload: e.target.files[0] });
        document.getElementById("pic-preview").src = URL.createObjectURL(e.target.files[0]);
    }

    alert = (msg, responseCode) => {
        if (responseCode === "200") {
            toast.success(msg);
        }
        else if (responseCode === "500") {
            toast.error(msg);
        }
        else {
            toast.info(msg);
        }
    }

    async resetFields() {
        document.getElementById("from-date-filter").value = "";
        document.getElementById("to-date-filter").value = "";
        document.getElementById("search-field").value = "";
        document.getElementById("status-dropdown").value = "";
        this.getAllCustomerList({ dateFilter: "" });
    }

    dateFilterVar = "";

    async getAllCustomerList({ dateFilter = this.dateFilterVar } = {}) {
        this.dateFilterVar = dateFilter;
        this.setState({ page: 1 });
        var postData = {
            "search_data": document.getElementById("search-field").value,
            "status": document.getElementById("status-dropdown").value,
            "date": dateFilter,
            "fromDate": document.getElementById("from-date-filter").value,
            "toDate": document.getElementById("to-date-filter").value
        }
        let resp = await postApi(postData, urlApi.allCustomer + this.state.page);
        if (resp.responsecode === "200") {
            this.setState({ tableData: resp.data.data });
            this.setState({ pagination: resp.data.pagination });
        }
        else {
            this.resetTable();
        }
    }

    async getAddressList(customerID) {
        let resp = await getApi(urlApi.customerAddressList + customerID);
        if (resp.responsecode === "200") {
            this.setState({ addressList: resp.data });
        }
        else {
            this.alert(resp.message, "500");
            this.addressViewModalHide();
        }
    }

    async onSearch() {
        await this.setState({ page: 1 }, () => this.getAllCustomerList());
    }

    async updateCustomerStatus(customerID, status) {
        let resp = await getApi(urlApi.customerStatusUpdate + customerID + "/" + status);
        this.hideStatusUpdateModal();
        if (resp.responsecode === "200") {
            this.alert(resp.message, "200");
            if (this.state.tableData.length === 1 && this.state.page > 1) {
                let pageNumber = 1;
                this.setState({ page: pageNumber }, () => this.getAllCustomerList());
            }
            else {
                this.getAllCustomerList();
            }

        }
        else {
            this.alert(resp.message, "500");
        }
    }

    componentDidMount() {
        this.getAllCustomerList();
    }

    async setDefaultAddress() {
        let postData = {
            "customer_id": this.customerIdStatic,
            "address_id": this.addressId
        }
        let res = await postApi(postData, urlApi.customerDefaultAddressUpdate);
        this.hideSetDefaultConfirmationModal();
        if (res.responsecode === "200") {
            this.defaultAddressId = this.addressId;
            this.getAddressList(this.customerIdStatic);
            this.alert(res.message, res.responsecode);
        }
        else {
            this.alert(res.message, res.responsecode);
        }
    }

    async handlePageChange(pageNumber) {
        await this.setState({ page: pageNumber });
        this.getAllCustomerList();
    }

    showSetDefaultConfirmationModal(data) {
        this.customerIdStatic = data.customer_id;
        this.addressId = data.address_id;
        this.setState({ setDefaultConfirmationModalShow: true });
    }
    hideSetDefaultConfirmationModal() {
        this.setState({ setDefaultConfirmationModalShow: false });
    }

    render() {
        var x = (this.state.pagination.active_page - 1) * this.state.pagination.per_page + 1;
        return (
            <>
                <div className="container-fluid position-relative bg-white d-flex p-0">
                    <SidebarMenu isOpen={this.state.isOpen} />
                    <div className={this.state.isOpen ? "content open" : "content"}>
                        <Navbar menuBarClick={() => this.menuBarClick()} />
                        <div className="container-fluid">
                            <ToastContainer autoClose={1000} />
                            <div className="row g-4">
                                <div className="col-12 px-0">
                                    <div className="rounded h-100 p-2">
                                        <h6 className="mb-2">Customer Details || Total : {this.state.pagination.total_items}</h6>
                                        <div className="row mb-2 gx-2">
                                            <div className="col-md-1 col mb-1">
                                                <button className='btn-custom action-btn-5 w-100' onClick={() => this.getAllCustomerList({ dateFilter: "" })}>All</button>
                                            </div>
                                            <div className="col-md-1 col mb-1">
                                                <button className='btn-custom action-btn-1 w-100' onClick={() => this.getAllCustomerList({ dateFilter: "DAILY" })}>Today</button>
                                            </div>
                                            <div className="col-md-1 col mb-1">
                                                <button className='btn-custom action-btn-4 w-100' onClick={() => this.getAllCustomerList({ dateFilter: "YESTERDAY" })}>Yesterday</button>
                                            </div>
                                            <div className="col-md-1 col mb-1">
                                                <button className='btn-custom action-btn-3 w-100 text-nowrap' onClick={() => this.getAllCustomerList({ dateFilter: "WEEKLY" })}>Last Week</button>
                                            </div>
                                            <div className="col-md-1 col mb-1">
                                                <button className='btn-custom action-btn-2 w-100 text-nowrap' onClick={() => this.getAllCustomerList({ dateFilter: "MONTHLY" })}>Last Month</button>
                                            </div>
                                        </div>
                                        <div className="row gx-2 mb-2">
                                            <div className="col-md-2 col-5 mb-2">
                                                <input type="date" className='form-control form-control-sm w-100' id='from-date-filter' />
                                            </div>
                                            <div className="col-md-1 mb-2 col text-center"> <BsArrowLeftRight /> </div>
                                            <div className="col-md-2 col-5 mb-2">
                                                <input type="date" className='form-control form-control-sm w-100' id='to-date-filter' />
                                            </div>
                                            <div className="col-md-1 col">
                                                <button className='btn-custom action-btn-1 w-100' onClick={() => this.getAllCustomerList({ dateFilter: "CUSTOM" })}>Search</button>
                                            </div>
                                            <div className="col-md-2 col-2">
                                                <button className='btn-custom action-btn-5 w-sm-100' onClick={() => this.resetFields()}><GrPowerReset size={20} /></button>
                                            </div>
                                        </div>
                                        <form onSubmit={(e) => { e.preventDefault(); this.onSearch() }}>
                                            <div className="col-12 mb-2">
                                                <div className="row gx-1 search-section">
                                                    <div className="col-md-3 col-6">
                                                        <input className='form-control form-control-sm' id='search-field' placeholder='Username / Mobile / ID' />
                                                    </div>
                                                    <div className="col-md-3 col-4">
                                                        <select className='form-control form-control-sm w-100' defaultValue={""} id='status-dropdown'>
                                                            <option value="">All Users</option>
                                                            <option value="1">Active Users</option>
                                                            <option value="0">Inactive Users</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2 col-2">
                                                        <button className='btn-custom action-btn-5 w-sm-100' type='submit'><TbSearch size={20} /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        {this.state.tableData.length > 0 ?
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="col">#</th>
                                                            <th className="col">ID</th>
                                                            <th className="col">Name</th>
                                                            <th className="col">Profile Pic</th>
                                                            <th className="col">Mobile</th>
                                                            <th className="col">Status</th>
                                                            <th className="col">Create Date</th>
                                                            <th className="col">Modified By</th>
                                                            <th className="col-3">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.tableData.map((data, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <th scope="row">{x++}</th>
                                                                    <td >{data.customer_id}</td>
                                                                    <td className='text-left'>
                                                                        <Link target={"_blank"} to={"/single-customer-view/userDetails/" + data.customer_id}>{data.name}</Link>
                                                                    </td>
                                                                    <td><span className='table-img'><img src={data.profile_pic}
                                                                        onError={({ currentTarget }) => {
                                                                            currentTarget.onerror = null;
                                                                            currentTarget.src = image;
                                                                        }} alt="" /></span></td>
                                                                    <td>{data.mobile}</td>
                                                                    <td>
                                                                        <Switch onChange={() => this.showStatusUpdateModal(data.customer_id, data.status === 1 ? 0 : 1)} checked={data.status === 0 ? false : true} />
                                                                    </td>
                                                                    <td>{dateTimeConverter(data.create_date)}</td>
                                                                    <td style={{ textAlign: 'center' }}>
                                                                        {data.modified_by === 0 ? data.name : data.admin_name}
                                                                        <br />
                                                                        {dateTimeConverter(data.modified_date)}
                                                                    </td>
                                                                    <td>
                                                                        <button className='btn-custom action-btn-1 m-1' onClick={() => this.editViewModalShow(data)}>Edit</button>
                                                                        <Link className='btn-custom action-btn-2 m-1' target={"_blank"} to={"/single-customer-view/userDetails/" + data.customer_id}>View</Link>
                                                                        <button className='btn-custom action-btn-3 m-1' onClick={() => this.addressViewModalShow(data)}>Addresses</button>
                                                                        <Link className='btn-custom action-btn-5 m-1' target={"_blank"} to={"/single-customer-view/cartDetails/" + data.customer_id}>Cart</Link><br />
                                                                        <Link to={"/single-customer-view/orderDetails/" + data.customer_id} target={"_blank"} className='btn-custom action-btn-6 m-1'>Order History</Link>
                                                                        <button className='btn-custom action-btn-4 m-1' onClick={() => this.showSideBar(data)}>Send Notification</button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            : <div className="no-data text-center my-5">
                                                <h4 className='text-danger'>Sorry No Data Found!</h4>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='me-2' style={{ float: 'right', display: this.state.tableData.length > 0 ? 'unset' : 'none' }}>
                                <Pagination
                                    activePage={parseInt(this.state.pagination.active_page)}
                                    itemsCountPerPage={this.state.pagination.per_page}
                                    totalItemsCount={this.state.pagination.total_items}
                                    pageRangeDisplayed={5}
                                    onChange={this.handlePageChange.bind(this)}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.editModalShow} onHide={() => this.editViewModalHide()}>
                    <Modal.Header closeButton style={{ display: "none" }}>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: "10px 14px" }}>
                        <div className="text-center"><h3 style={{ borderBottom: "4px solid teal" }}>Edit Customer Details</h3></div>
                        <form>
                            <input id='customer-id' type="text" style={{ display: "none" }} />
                            <div className="form-group mb-2">
                                <label htmlFor="edit-customer-name">Customer Name</label>
                                <div className="input-group input-group-sm">
                                    <input id="edit-customer-name" type="text" className="form-control form-control-sm" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span><TbUser /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="edit-customer-email">Email</label>
                                <div className="input-group input-group-sm">
                                    <input id="edit-customer-email" type="text" className="form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span><TbMail /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="edit-customer-description">Description</label>
                                <div className="input-group input-group-sm">
                                    <input id="edit-customer-description" type="text" className="form-control" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span><TbFileDescription /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="">Profile Picture :&nbsp;&nbsp;&nbsp;</label>
                                <img id="pic-preview" style={{ maxHeight: 100, minHeight: 80, borderRadius: 5 }} alt="" />
                            </div>
                            <div className="input-group mb-2">
                                <input id="customer-edit-photo" type="file" className="form-control form-control-sm" onChange={this.fileOnChange} />
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='btn-custom action-btn-2' onClick={() => this.editViewModalHide()}>
                            Close
                        </Button>
                        <Button className='btn-custom action-btn-1' onClick={() => this.editModalYesButton()}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.addressModalShow} onHide={() => this.addressViewModalHide()}>
                    <Modal.Header closeButton style={{ display: "none" }}>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: "10px 14px", maxHeight: '90vh', overflow: 'auto' }}>
                        <div className="text-center"><h3 style={{ borderBottom: "4px solid teal" }}>Address List</h3></div>
                        {this.state.addressList.map((data, index) => {
                            return (
                                <AddressComponent
                                    key={index}
                                    name={data.name}
                                    addressDetails={data.address_details}
                                    pinCode={data.pincode}
                                    mobile={data.mobile}
                                    landmark={data.landmark}
                                    isDefault={this.defaultAddressId === data.address_id ? true : false}
                                    btnText3={this.defaultAddressId === data.address_id ? null : "Set Default"}
                                    setDefaultFunction={() => this.showSetDefaultConfirmationModal(data)}
                                />
                            )
                        })}
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'none' }}>
                        <Button className='action-btn-2' onClick={() => this.addressViewModalHide()}>
                            Close
                        </Button>
                        <Button className='action-btn-1' onClick={() => this.addressViewModalHide()}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Offcanvas show={this.state.sideBarShow} onHide={() => this.hideSideBar()} placement={'end'}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Send Notification</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="form-group mb-2">
                            <label htmlFor="">Title</label>
                            <input id='title' className='form-control' />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="">Message</label>
                            <textarea id='message' className='form-control'></textarea>
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="">Picture :&nbsp;&nbsp;&nbsp;</label>
                            <img id="pic-preview" style={{ maxHeight: 80, width: 'auto', borderRadius: 5 }} alt="" />
                        </div>
                        <div className="input-group mb-2">
                            <input id="edit-photo" type="file" className="form-control form-control-sm" onChange={this.fileOnChange} />
                        </div>
                        <button className='btn-custom action-btn-1 w-100 mt-2' onClick={() => this.sendNotification()}>Send</button>
                    </Offcanvas.Body>
                </Offcanvas>

                <ConfirmationModal
                    show={this.state.setDefaultConfirmationModalShow}
                    headerText="Set Default"
                    bodyText="Are You Sure Want To Set This Address As Default Address?"
                    closeFunction={() => this.hideSetDefaultConfirmationModal()}
                    okFunction={() => this.setDefaultAddress()}
                />

                <ConfirmationModal
                    show={this.state.statusUpdateModalShow}
                    headerText="Update Status"
                    bodyText="Are You Sure Want To Update Status?"
                    closeFunction={() => this.hideStatusUpdateModal()}
                    okFunction={() => this.updateCustomerStatus(this.customerId, this.status)}
                />
            </>
        )
    }
}
