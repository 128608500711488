import { io } from "socket.io-client";
import dataApi from "./data.api";
import { SOCKET_URL } from "./static.data.pnp";
import PubSub from 'pubsub-js';

class socketConnect {
    socketConnect;
    initializeSocket = () => {
        this.socketConnect = io.connect(SOCKET_URL, {
            transports: ['polling'],
            reconnect: false,
            query: { "token": dataApi.TOKEN(), "type": "baker", "fb_token": dataApi.TOKEN() }
        });

        this.socketConnect.on("connect", (res) => {
            setInterval(() => {
                this.socketConnect.on("ping", "ping");
            }, 1000);
        });

        this.socketConnect.on("error", (res) => {
            console.log(res);
        });

        this.socketConnect.on("chat-details", (data) => {
            PubSub.publish('CHAT DETAILS', data);
        });

        this.socketConnect.on("msg-list-rcv", (data) => {
            PubSub.publish('MSG LIST RCV',data);
        });

        this.socketConnect.on("msg-rcv", (data) => {
            PubSub.publish('MSG RCV',data);
        })
    }
    emitFunc = (key, postData) => {
        this.socketConnect.emit(key, postData);
    }
}


const socketService = new socketConnect();

export default socketService;