import React, { Component } from 'react'
import "../css/login.css";
import logo from "../assets/images/logo.png"
import { toast, ToastContainer } from 'react-toastify';
import { postApi } from '../api/call.api';
import urlApi from '../api/url.api.js';
import { Spinner } from 'react-bootstrap';
import dataApi from '../api/data.api';

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            loginData: [],
            isDone: true
        }
    }

    alert = (msg, responseCode) => {
        if (responseCode === "200") {
            toast.success(msg);
        }
        else if (responseCode === "500") {
            toast.error(msg);
        }
        else {
            toast.info(msg);
        }
    }

    componentDidMount() {
        this.setState({ isDone: true });
        localStorage.setItem("token-batterly-admin", "");
    }

    async onLogin() {
        this.setState({ isDone: false });
        if (!this.state.username || !this.state.password) {
            this.alert("Username or Password Can Not be Empty", "500");
        }
        else {
            let postData = {
                username: this.state.username,
                password: this.state.password
            }
            let responseData = await postApi(postData, urlApi.login);
            this.setState({ isDone: true });
            if (responseData.responsecode === "200") {
                this.setState({ loginData: responseData.data });
                localStorage.setItem("token-batterly-admin", responseData.data.token);
                localStorage.setItem("username", responseData.data.full_name);
                if (dataApi.TOKEN()) {
                    this.props.history.push("/dashboard");
                }
                // window.location.reload(false);
            }
            else {
                this.alert(responseData.message, "500")
            }
        }
    }

    handleEnter(event) {
        if (event.keyCode === 13) {
            const form = event.target.form;
            const index = Array.prototype.indexOf.call(form, event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    }

    render() {
        return (
            <div>
                <div className="container p-md-5 px-4 py-5">
                    <div className="row">
                        <div className="col-md-5 mx-auto">
                            <div className="myForm form">
                                <div className="logo mb-3">
                                    <div className="col-md-12 text-center">
                                        <img src={logo} alt="logo" />
                                    </div>
                                </div>
                                <form className="form-fields">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">User Name / ID</label>
                                        <input onKeyDown={(e) => this.handleEnter(e)} onChange={(e) => { this.setState({ username: e.target.value }) }} type="text" name="username" className="form-control" id="phone" placeholder="Enter User Name" />
                                    </div>
                                    <div className="form-group pt-3">
                                        <label htmlFor="exampleInputEmail1">Password</label>
                                        <input onChange={(e) => { this.setState({ password: e.target.value }) }} type="password" name="password" id="password" className="form-control" placeholder="Enter Password" />
                                    </div>
                                    <div className="col-md-12 text-center pt-4">
                                        <button onClick={() => this.onLogin()} type="submit" disabled={this.state.isDone ? false : true} className={this.state.isDone ? "login-btn btn btn-primary" : "btn disable-btn w-100"}>{!this.state.isDone ?
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            /> : "Login"}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <ToastContainer autoClose={1000} />
                    </div>
                </div>
            </div>
        )
    }
}
