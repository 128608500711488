import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class TopBakerCard extends Component {
    render() {
        return (
            <>
                <div className="card p-2 text-center border-0">
                    <img src={this.props.bakerImage} className="ms-auto me-auto mb-3" alt="" style={{ width: 150, height: 150, borderRadius: 15 }} />
                    <Link to={"/single-shop-view/shopDetails/" + this.props.bakerId} target="_blank">
                        <h4>{this.props.bakerName}</h4>
                    </Link>
                    <p className='m-0'>{this.props.count}</p>
                </div>
            </>
        )
    }
}
