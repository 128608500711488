import React, { Component } from 'react'
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import Navbar from '../components/Navbar'
import SidebarMenu from '../components/SidebarMenu'
import { MdDriveFileRenameOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { getApi, postApi } from '../api/call.api';
import urlApi from '../api/url.api';
import { toast, ToastContainer } from 'react-toastify';
import Pagination from 'react-js-pagination';
import { TbCaretDown, TbSearch } from 'react-icons/tb';

export default class Category extends Component {
    constructor(p) {
        super(p);
        this.state = {
            isOpen: false,
            toggleCheck: false,
            addModalShow: false,
            productCategoryList: [],
            staticCategoryList: [],
            productSubCategoryList: [],
            addonCategoryList: [],
            decorCategoryList: [],
            quantityCategoryList: [],
            pagination: ({
                active_page: 0,
                per_page: 10,
                total_items: 0
            }),
            page: 1,
            count: 10,
            picUpload: null,
        }
        this.statusChange = this.statusChange.bind(this);
    }

    menuBarClick() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    statusChange(toggleCheck) {
        this.setState({ toggleCheck: toggleCheck });
    }

    showAddModal(data, isEdit, page) {
        this.page = page;
        if (isEdit) {
            this.editModal = true;
        } else {
            this.editModal = false;
        }
        setTimeout(() => {
            if (isEdit && page === "productCategory") {
                document.getElementById('category-id').value = data.product_category_id;
                document.getElementById("category-name").value = data.product_category_name;
                document.getElementById("pic-preview").src = data.category_icon;
            }
            if (isEdit && page === "productSubCategory") {
                document.getElementById('category-id').value = data.sub_category_id;
                document.getElementById("category-name").value = data.sub_category_name;
                document.getElementById("product-category-dropdown").value = data.product_category_id;
            }
            if (isEdit && page === "addonCategory") {
                document.getElementById('category-id').value = data.add_on_category_id;
                document.getElementById("category-name").value = data.add_on_category_name;
            }
            if (isEdit && page === "decorCategory") {
                document.getElementById('category-id').value = data.decor_category_id;
                document.getElementById("category-name").value = data.decor_category_name;
            }
            if (isEdit && page === "quantityUnit") {
                document.getElementById('category-id').value = data.unit_id;
                document.getElementById("category-name").value = data.unit_name;
                document.getElementById("unit-short-name").value = data.unit_short_name;
            }
        }, 100);
        this.setState({ addModalShow: true });
    }

    hideAddModal() {
        this.setState({ addModalShow: false });
    }

    fileOnChange = async (e) => {
        await this.setState({ picUpload: e.target.files[0] });
        document.getElementById("pic-preview").src = URL.createObjectURL(e.target.files[0]);
    }

    async addProductCategory() {
        this.editModal = false;
        const formData = new FormData();
        formData.append(
            "file_data", this.state.picUpload,
        );
        let respPic = await postApi(formData, urlApi.uploadProductCategoryIcon);
        if (respPic.responsecode === "200") {
            let postData = {
                "product_category_name": document.getElementById("category-name").value,
                "category_icon": respPic.data
            }
            let resp = await postApi(postData, urlApi.addProductCategory);
            this.hideAddModal();
            if (resp.responsecode === "200") {
                this.getAllProductCategoryList();
                this.alert(resp.message, resp.responsecode);
            }
        }
    }

    async addProductSubCategory() {
        this.editModal = false;
        let postData = {
            "sub_category_name": document.getElementById("category-name").value,
            "product_category_id": document.getElementById("product-category-dropdown").value
        }
        let resp = await postApi(postData, urlApi.addProductSubCategory);
        this.hideAddModal();
        if (resp.responsecode === "200") {
            this.alert(resp.message, resp.responsecode)
            this.getAllSubCategoryList();
        } else {
            this.alert(resp.message, resp.responsecode)
        }
    }

    async editProductSubCategory() {
        this.editModal = true;
        let postData = {
            "sub_category_name": document.getElementById("category-name").value,
            "product_category_id": document.getElementById("product-category-dropdown").value,
            "sub_category_id": document.getElementById('category-id').value
        }
        let resp = await postApi(postData, urlApi.updateProductSubCategory);
        this.hideAddModal();
        if (resp.responsecode === "200") {
            this.alert(resp.message, resp.responsecode)
            this.getAllSubCategoryList();
        } else {
            this.alert(resp.message, resp.responsecode)
        }
    }

    async updateProductCategory() {
        this.editModal = true;
        if (this.state.picUpload) {
            const formData = new FormData();
            formData.append(
                "file_data", this.state.picUpload,
            );
            let respPic = await postApi(formData, urlApi.uploadProductCategoryIcon);
            if (respPic.responsecode === "200") {
                let postData = {
                    "category_id": document.getElementById('category-id').value,
                    "product_category_name": document.getElementById("category-name").value,
                    "category_icon": respPic.data,
                }
                let resp = await postApi(postData, urlApi.updateProductCategory);
                this.hideAddModal();
                if (resp.responsecode === "200") {
                    this.alert(resp.message, "200");
                    this.getAllProductCategoryList();
                }
                else {
                    this.alert(resp.message, "500");
                }
            }
            else {
                this.alert(respPic.message, "500")
            }
        } else {
            let postData = {
                "category_id": document.getElementById('category-id').value,
                "product_category_name": document.getElementById("category-name").value,
                "category_icon": document.getElementById("pic-preview").src,
            }
            let resp = await postApi(postData, urlApi.updateProductCategory);
            this.hideAddModal();
            if (resp.responsecode === "200") {
                this.alert(resp.message, "200");
                this.getAllProductCategoryList();
            }
            else {
                this.alert(resp.message, "500");
            }
        }
    }

    alert = (msg, responseCode) => {
        if (responseCode === "200") {
            toast.success(msg);
        }
        else if (responseCode === "500") {
            toast.error(msg);
        }
        else {
            toast.info(msg);
        }
    }

    async resetTable() {
        await this.setState({ productCategoryList: [] })
        await this.setState({
            pagination: ({
                active_page: 0,
                per_page: 10,
                total_items: 0
            })
        })
    }

    async handlePageChangeProductCategory(pageNumber) {
        await this.setState({ page: pageNumber });
        this.getAllProductCategoryList();
    }

    async handlePageChangeProductSubCategory(pageNumber) {
        await this.setState({ page: pageNumber });
        this.getAllSubCategoryList();
    }

    async handlePageChangeAddonCategory(pageNumber) {
        await this.setState({ page: pageNumber });
        this.getAllAddonCategoryList();
    }

    async handlePageChangeDecorCategory(pageNumber) {
        await this.setState({ page: pageNumber });
        this.getAllDecorCategoryList();
    }

    async handlePageChangeQuantityCategory(pageNumber) {
        await this.setState({ page: pageNumber });
        this.getAllQuantityCategoryList();
    }

    async getAllProductCategoryList() {
        this.setState({ page: 1 });
        let resp = await getApi(urlApi.productCategoryListWithPagination + this.state.page);
        if (resp.responsecode === "200") {
            this.setState({ productCategoryList: resp.data.data });
            this.setState({ pagination: resp.data.pagination });
        }
        else {
            this.resetTable();
        }
    }

    async getStaticProductCategoryList() {
        let res = await getApi(urlApi.productCategoryList);
        if (res.responsecode === "200") {
            this.setState({ staticCategoryList: res.data })
        }
    }

    getCategoryNameFromID(categoryID) {
        for (const data of this.state.staticCategoryList) {
            if (categoryID === data.product_category_id) {
                return data.product_category_name;
            }
        }
    }

    async getAllSubCategoryList() {
        let postData = {
            "category_id" : document.getElementById("category-dropdown-search").value
        }
        this.setState({ page: 1 });
        let resp = await postApi(postData,urlApi.productSubCategoryList + this.state.page);
        if (resp.responsecode === "200") {
            this.setState({ productSubCategoryList: resp.data.data });
            this.setState({ pagination: resp.data.pagination });
        }
        else {
            this.resetTable();
        }
    }

    async getAllAddonCategoryList() {
        this.setState({ page: 1 });
        let resp = await getApi(urlApi.addonCategoryList + this.state.page);
        if (resp.responsecode === "200") {
            this.setState({ addonCategoryList: resp.data.data });
            this.setState({ pagination: resp.data.pagination });
        }
        else {
            this.resetTable();
        }
    }

    async addAddonCategory() {
        this.editModal = false;
        let postData = {
            "add_On_category_name": document.getElementById("category-name").value,
        }
        let resp = await postApi(postData, urlApi.addAddonCategory);
        this.hideAddModal();
        if (resp.responsecode === "200") {
            this.alert(resp.message, resp.responsecode);
            this.getAllAddonCategoryList();
        } else {
            this.alert(resp.message, resp.responsecode)
        }
    }

    async updateAddonCategory() {
        this.editModal = true;
        let postData = {
            "add_On_category_name": document.getElementById("category-name").value,
            "add_On_category_id": document.getElementById('category-id').value
        }
        let resp = await postApi(postData, urlApi.updateAddonCategory);
        this.hideAddModal();
        if (resp.responsecode === "200") {
            this.alert(resp.message, resp.responsecode);
            this.getAllAddonCategoryList();
        } else {
            this.alert(resp.message, resp.responsecode)
        }
    }

    async getAllDecorCategoryList() {
        this.setState({ page: 1 });
        let resp = await getApi(urlApi.decorCategoryList + this.state.page);
        if (resp.responsecode === "200") {
            this.setState({ decorCategoryList: resp.data.data });
            this.setState({ pagination: resp.data.pagination });
        }
        else {
            this.resetTable();
        }
    }

    async addDecorCategory() {
        this.editModal = false;
        let postData = {
            "decor_category_name": document.getElementById("category-name").value,
        }
        let resp = await postApi(postData, urlApi.addDecorCategory);
        this.hideAddModal();
        if (resp.responsecode === "200") {
            this.alert(resp.message, resp.responsecode);
            this.getAllDecorCategoryList();
        } else {
            this.alert(resp.message, resp.responsecode);
        }
    }

    async updateDecorCategory() {
        this.editModal = true;
        let postData = {
            "decor_category_name": document.getElementById("category-name").value,
            "decor_category_id": document.getElementById('category-id').value
        }
        let resp = await postApi(postData, urlApi.updateDecorCategory);
        this.hideAddModal();
        if (resp.responsecode === "200") {
            this.alert(resp.message, resp.responsecode);
            this.getAllDecorCategoryList();
        } else {
            this.alert(resp.message, resp.responsecode);
        }
    }

    async getAllQuantityCategoryList() {
        this.setState({ page: 1 });
        let resp = await getApi(urlApi.quantityCategoryList + this.state.page);
        if (resp.responsecode === "200") {
            this.setState({ quantityCategoryList: resp.data.data });
            this.setState({ pagination: resp.data.pagination });
        }
        else {
            this.resetTable();
        }
    }

    async addQuantityCategory() {
        this.editModal = false;
        let postData = {
            "quantity_category_name": document.getElementById("category-name").value,
            "quantity_short_name": document.getElementById("unit-short-name").value,
        }
        let resp = await postApi(postData, urlApi.addQuantityCategory);
        this.hideAddModal();
        if (resp.responsecode === "200") {
            this.alert(resp.message, resp.responsecode);
            this.getAllQuantityCategoryList();
        } else {
            this.alert(resp.message, resp.responsecode)
        }
    }

    async updateQuantityCategory() {
        this.editModal = true;
        let postData = {
            "quantity_category_name": document.getElementById("category-name").value,
            "quantity_short_name": document.getElementById("unit-short-name").value,
            "quantity_unit_id": document.getElementById('category-id').value
        }
        let resp = await postApi(postData, urlApi.updateQuantityCategory);
        this.hideAddModal();
        if (resp.responsecode === "200") {
            this.alert(resp.message, resp.responsecode);
            this.getAllQuantityCategoryList();
        } else {
            this.alert(resp.message, resp.responsecode)
        }
    }

    componentDidMount() {
        this.getStaticProductCategoryList();
        if (this.props.match.params.defaultActiveKey === "productCategory") {
            this.getAllProductCategoryList();
        }
        if (this.props.match.params.defaultActiveKey === "productSubCategory") {
            this.getStaticProductCategoryList();
            this.getAllSubCategoryList();
        }
        if (this.props.match.params.defaultActiveKey === "addonCategory") {
            this.getAllAddonCategoryList();
        }
        if (this.props.match.params.defaultActiveKey === "decorCategory") {
            this.getAllDecorCategoryList();
        }
        if (this.props.match.params.defaultActiveKey === "quantityUnit") {
            this.getAllQuantityCategoryList();
        }
    }

    render() {
        var productCategoryx = (this.state.pagination.active_page - 1) * this.state.pagination.per_page + 1;
        var subCategoryx = (this.state.pagination.active_page - 1) * this.state.pagination.per_page + 1;
        var addonCategoryx = (this.state.pagination.active_page - 1) * this.state.pagination.per_page + 1;
        var decorCategoryx = (this.state.pagination.active_page - 1) * this.state.pagination.per_page + 1;
        var quantityCategoryx = (this.state.pagination.active_page - 1) * this.state.pagination.per_page + 1;
        return (
            <>
                <div className="container-fluid position-relative bg-white d-flex p-0">
                    <SidebarMenu isOpen={this.state.isOpen} />
                    <div className={this.state.isOpen ? "content open" : "content"}>
                        <Navbar menuBarClick={() => this.menuBarClick()} />
                        <div className="container-fluid">
                            <ToastContainer autoClose={1000} />
                            <div className="row g-4">
                                <Tabs
                                    defaultActiveKey={this.props.match.params.defaultActiveKey ? this.props.match.params.defaultActiveKey : "productCategory"}
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                    onSelect={(e) => {
                                        this.props.history.push('/category/' + e);
                                        if (e === "productCategory") {
                                            this.getAllProductCategoryList();
                                        }
                                        if (e === "productSubCategory") {
                                            this.getAllSubCategoryList();
                                        }
                                        if (e === "addonCategory") {
                                            this.getAllAddonCategoryList();
                                        }
                                        if (e === "decorCategory") {
                                            this.getAllDecorCategoryList();
                                        }
                                        if (e === "quantityUnit") {
                                            this.getAllQuantityCategoryList();
                                        }
                                    }}
                                >
                                    <Tab eventKey="productCategory" title={<Link className='tab-link-color' to="/category/productCategory">Product Category</Link>}>
                                        <div className="row mb-3 mt-md-5" style={{marginTop:90}}>
                                            <div className="col-3 mt-md-auto mt-5 ms-auto">
                                                <button onClick={() => this.showAddModal(null, false, "productCategory")} className='w-100 btn-custom action-btn-5'>Add</button>
                                            </div>
                                        </div>
                                        <div className="col-12 px-0">
                                            <div className="bg-light rounded h-100 p-2">
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th className="col">#</th>
                                                                <th className="col">Category Name</th>
                                                                <th className="col">Category Icon</th>
                                                                <th className="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.productCategoryList.map((data,index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{productCategoryx++}</td>
                                                                        <td>{data.product_category_name}</td>
                                                                        <td><span className='table-img'><img src={data.category_icon} alt="" /></span></td>
                                                                        <td><button onClick={() => this.showAddModal(data, true, "productCategory")} className='btn-custom action-btn-1'>Edit</button></td>
                                                                    </tr>
                                                                )
                                                            })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className='me-2' style={{ float: 'right', display: this.state.productCategoryList.length > 0 ? 'unset' : 'none' }}>
                                                <Pagination
                                                    activePage={parseInt(this.state.pagination.active_page)}
                                                    itemsCountPerPage={this.state.pagination.per_page}
                                                    totalItemsCount={this.state.pagination.total_items}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handlePageChangeProductCategory.bind(this)}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                />
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="productSubCategory" title={<Link className='tab-link-color' to="/category/productSubCategory">Product Sub-Category</Link>}>
                                        <div className="row mt-md-5 mb-3" style={{marginTop:90}}>
                                            <div className="col-md-3 mt-md-auto mt-5 mb-md-auto mb-2">
                                                <select name="" id="category-dropdown-search" defaultValue={""} className='form-control form-control-sm'>
                                                    <option value="">-- All --</option>
                                                    {this.state.staticCategoryList.map((data,index) => {
                                                        return (
                                                            <option key={index} value={data.product_category_id}>{data.product_category_name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            <div className="col-md-2 col-2">
                                                <button className='btn-custom action-btn-5 w-sm-100' onClick={() => this.getAllSubCategoryList()}><TbSearch size={20} /></button>
                                            </div>
                                            <div className="col-3 ms-auto">
                                                <button onClick={() => this.showAddModal(null, false, "productSubCategory")} className='w-100 btn-custom action-btn-5'>Add</button>
                                            </div>
                                        </div>
                                        <div className="col-12 px-0">
                                            <div className="bg-light rounded h-100 p-2">
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th className="col">#</th>
                                                                <th className="col">Sub Category Name</th>
                                                                <th className="col">Category Name</th>
                                                                <th className="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.productSubCategoryList.map((data,index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{subCategoryx++}</td>
                                                                        <td>{data.sub_category_name}</td>
                                                                        <td>{this.getCategoryNameFromID(data.product_category_id)}</td>
                                                                        <td><button onClick={() => this.showAddModal(data, true, "productSubCategory")} className='btn-custom action-btn-1'>Edit</button></td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className='me-2' style={{ float: 'right', display: this.state.productSubCategoryList.length > 0 ? 'unset' : 'none' }}>
                                                <Pagination
                                                    activePage={parseInt(this.state.pagination.active_page)}
                                                    itemsCountPerPage={this.state.pagination.per_page}
                                                    totalItemsCount={this.state.pagination.total_items}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handlePageChangeProductSubCategory.bind(this)}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                />
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="addonCategory" title={<Link className='tab-link-color' to="/category/addonCategory">Addon</Link>}>
                                        <div className="row mt-md-5 mb-3" style={{marginTop:90}}>
                                            <div className="col-3 mt-md-auto mt-5 ms-auto">
                                                <button className='w-100 btn-custom action-btn-5' onClick={() => this.showAddModal(null, false, "addonCategory")}>Add</button>
                                            </div>
                                        </div>
                                        <div className="col-12 px-0">
                                            <div className="bg-light rounded h-100 p-2">
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th className="col">#</th>
                                                                <th className="col">Category Name</th>
                                                                <th className="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.addonCategoryList.map((data,index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{addonCategoryx++}</td>
                                                                        <td>{data.add_on_category_name}</td>
                                                                        <td><button className='btn-custom action-btn-1' onClick={() => this.showAddModal(data, true, "addonCategory")}>Edit</button></td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className='me-2' style={{ float: 'right', display: this.state.addonCategoryList.length > 0 ? 'unset' : 'none' }}>
                                                <Pagination
                                                    activePage={parseInt(this.state.pagination.active_page)}
                                                    itemsCountPerPage={this.state.pagination.per_page}
                                                    totalItemsCount={this.state.pagination.total_items}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handlePageChangeAddonCategory.bind(this)}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                />
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="decorCategory" title={<Link className='tab-link-color' to="/category/decorCategory">Decor</Link>}>
                                        <div className="row mt-md-5 mb-3" style={{marginTop:90}}>
                                            <div className="col-3 mt-md-auto mt-5 ms-auto">
                                                <button className='w-100 btn-custom action-btn-5' onClick={() => this.showAddModal(null, false, "decorCategory")}>Add</button>
                                            </div>
                                        </div>
                                        <div className="col-12 px-0">
                                            <div className="bg-light rounded h-100 p-2">
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th className="col">#</th>
                                                                <th className="col">Category Name</th>
                                                                <th className="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.decorCategoryList.map((data,index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{decorCategoryx++}</td>
                                                                        <td>{data.decor_category_name}</td>
                                                                        <td><button className='btn-custom action-btn-1' onClick={() => this.showAddModal(data, true, "decorCategory")}>Edit</button></td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className='me-2' style={{ float: 'right', display: this.state.decorCategoryList.length > 0 ? 'unset' : 'none' }}>
                                                <Pagination
                                                    activePage={parseInt(this.state.pagination.active_page)}
                                                    itemsCountPerPage={this.state.pagination.per_page}
                                                    totalItemsCount={this.state.pagination.total_items}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handlePageChangeDecorCategory.bind(this)}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                />
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="quantityUnit" title={<Link className='tab-link-color' to="/category/quantityUnit">Quantity Unit</Link>}>
                                        <div className="row mt-md-5 mb-3" style={{marginTop:90}}>
                                            <div className="col-3 mt-md-auto mt-5 ms-auto">
                                                <button className='w-100 btn-custom action-btn-5' onClick={() => this.showAddModal(null, false, "quantityUnit")}>Add</button>
                                            </div>
                                        </div>
                                        <div className="col-12 px-0">
                                            <div className="bg-light rounded h-100 p-2">
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th className="col">#</th>
                                                                <th className="col">Unit Name</th>
                                                                <th className="col">Unit Short Name</th>
                                                                <th className="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.quantityCategoryList.map((data,index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{quantityCategoryx++}</td>
                                                                        <td>{data.unit_name}</td>
                                                                        <td>{data.unit_short_name}</td>
                                                                        <td><button className='btn-custom action-btn-1' onClick={() => this.showAddModal(data, true, "quantityUnit")}>Edit</button></td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className='me-2' style={{ float: 'right', display: this.state.quantityCategoryList.length > 0 ? 'unset' : 'none' }}>
                                                <Pagination
                                                    activePage={parseInt(this.state.pagination.active_page)}
                                                    itemsCountPerPage={this.state.pagination.per_page}
                                                    totalItemsCount={this.state.pagination.total_items}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handlePageChangeQuantityCategory.bind(this)}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                />
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={this.state.addModalShow} onHide={() => this.hideAddModal()}>
                    <Modal.Header closeButton style={{ display: "none" }}>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: "10px 14px" }}>
                        <div className="text-center"><h3 style={{ borderBottom: "4px solid teal" }}>{this.editModal ? 'Edit Category' : 'Add Category'}</h3></div>
                        <form>
                            <input id='category-id' type="text" style={{ display: "none" }} />
                            <div className="form-group mb-2">
                                <label htmlFor="">Category Name</label>
                                <div className="input-group input-group-sm">
                                    <input id="category-name" type="text" className="form-control form-control-sm" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span><MdDriveFileRenameOutline /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2" style={{ display: this.page === "quantityUnit" ? 'unset' : 'none' }}>
                                <label htmlFor="">Unit Short Name</label>
                                <div className="input-group input-group-sm">
                                    <input id="unit-short-name" type="text" className="form-control form-control-sm" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span><MdDriveFileRenameOutline /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2" style={{ display: this.page === "productSubCategory" ? 'unset' : 'none' }}>
                                <label htmlFor="">Product Category</label>
                                <div className="input-group input-group-sm">
                                    <select id="product-category-dropdown" className='form-control'>
                                        {this.state.staticCategoryList.map((data,index) => {
                                            return (
                                                <option key={index} value={data.product_category_id}>{data.product_category_name}</option>
                                            )
                                        })}
                                    </select>
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span><TbCaretDown /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2" style={{ display: this.page === "productCategory" ? 'unset' : 'none' }}>
                                <label htmlFor="">Category Icon :&nbsp;&nbsp;&nbsp;</label>
                                <img id="pic-preview" style={{ maxHeight: 60 }} alt="" />
                            </div>
                            <div className="input-group mb-2" style={{ display: this.page === "productCategory" ? 'flex' : 'none' }}>
                                <input id="edit-photo" type="file" className="form-control form-control-sm" onChange={this.fileOnChange} />
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='btn-custom action-btn-2' onClick={() => this.hideAddModal()}>
                            Close
                        </Button>
                        <Button className='btn-custom action-btn-1' onClick={() => this.editModal && this.page === "productCategory" ? this.updateProductCategory() : this.page === "productCategory" ? this.addProductCategory() : this.page === "productSubCategory" && !this.editModal ? this.addProductSubCategory() : this.editModal && this.page === "productSubCategory" ? this.editProductSubCategory() : this.page === "addonCategory" && !this.editModal ? this.addAddonCategory() : this.page === "addonCategory" && this.editModal ? this.updateAddonCategory() : this.page === "decorCategory" && !this.editModal ? this.addDecorCategory() : this.page === "decorCategory" && this.editModal ? this.updateDecorCategory() : this.page === "quantityUnit" && !this.editModal ? this.addQuantityCategory() : this.page === "quantityUnit" && this.editModal ? this.updateQuantityCategory() : null}>
                            {this.editModal ? 'Update' : 'Add'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}
