import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import "../css/main.css"

export default function SidebarMenu(props) {
    const { pathname } = useLocation();
    return (
        <div>
            <div className={props.isOpen ? "sidebar open pb-3" : "sidebar pb-3"}>
                <nav className="navbar bg-light navbar-light">
                    <Link to="/dashboard" className="navbar-brand mx-4 mb-1">
                        <h3 className="main-color"><i className="fa fa-heart me-2"></i>BATTERLY</h3>
                    </Link>
                    <div className="navbar-nav w-100">
                        <NavLink exact to="/dashboard" className="nav-item nav-link" activeClassName='active'><i className="fa fa-tachometer-alt me-2"></i>Dashboard</NavLink>
                        <NavLink exact to="/all-customer" className="nav-item nav-link" activeClassName='active'><i className="fa fa-users me-2"></i>Customer</NavLink>
                        <NavLink exact to="/all-bakers" className="nav-item nav-link" activeClassName='active'><i className="fa fa-user-circle me-2"></i>Bakers</NavLink>
                        <NavLink exact to="/all-shops" className="nav-item nav-link" activeClassName='active'><i className="fa fa-building me-2"></i>Shops</NavLink>
                        <NavLink exact to="/all-product" className="nav-item nav-link" activeClassName='active'><i className="fa fa-clone me-2"></i>Products</NavLink>
                        <NavLink exact to="/all-cart" className="nav-item nav-link" activeClassName='active'><i className="fa fa-shopping-cart me-2"></i>Cart</NavLink>
                        <NavLink exact to="/all-order" className="nav-item nav-link" activeClassName='active'><i className="fa fa-shopping-basket me-2"></i>Orders</NavLink>
                        <NavLink exact to="/coupon" className="nav-item nav-link" activeClassName='active'><i className="fa fa-tags me-2"></i>Coupon</NavLink>
                        <NavLink exact to="/wishlist/wishListShop" isActive={() => ['/wishlist/wishListShop', '/wishlist/wishListProduct'].includes(pathname)} className="nav-item nav-link" activeClassName='active'><i className="fa fa-heart me-2"></i>Wishlists</NavLink>
                        <NavLink exact to="/category/productCategory" isActive={() => ['/category/productCategory', '/category/productSubCategory', "/category/addonCategory", "/category/decorCategory", "/category/quantityUnit"].includes(pathname)} className="nav-item nav-link" activeClassName='active'><i className="fa fa-list me-2"></i>Category</NavLink>
                        <NavLink exact to="/feedbacks" className="nav-item nav-link" activeClassName='active'><i className="fa fa-comments me-2"></i>Feedback</NavLink>
                        <NavLink exact to="/notification" className="nav-item nav-link" activeClassName='active'><i className="fa fa-bell me-2"></i>Notification</NavLink>
                        <NavLink exact to="/settings/baker" isActive={() => ['/settings/baker', '/settings/customer'].includes(pathname)} className="nav-item nav-link" activeClassName='active'><i className="fa fa-cog me-2"></i>Settings</NavLink>
                        <NavLink exact to="/" className="nav-item nav-link" activeClassName='active'><i className="fa fa-power-off me-2"></i>LogOut</NavLink>
                    </div>
                </nav>
            </div>
        </div>
    )
}
