import React from 'react';
import { BrowserRouter as Router, Switch, Route, withRouter } from "react-router-dom";
import Login from './views/Login';
import Dashboard from './views/Dashboard';
import AllShops from './views/AllShops';
import AllCustomerView from './views/AllCustomerView';
import AllCartView from './views/AllCartView';
import Feedbacks from './views/Feedbacks';
import Wishlists from './views/Wishlists';
import AllProducts from './views/AllProducts';
import AllOrder from './views/AllOrder';
import Notification from './views/Notification';
import SingleShopView from './views/SingleShopView';
import Category from './views/Category';
import SingleCustomerView from './views/SingleCustomerView';
import SettingsView from './views/SettingsView';
import AllBakers from './views/AllBakers';
import Coupon from './views/Coupon';
import UsedCoupon from './views/UsedCoupon';
import { Component } from 'react';
import socketService from './api/socketConnect';

class App extends Component {
  constructor(p){
    super(p);
    socketService.initializeSocket(); 
  }

  render(){
    return (
      <>
        <Router>
          <Switch>
            <Route exact path="/" component={withRouter(Login)} />
            <Route exact path="/dashboard" component={withRouter(Dashboard)} />
            <Route exact path="/all-shops" component={withRouter(AllShops)} />
            <Route exact path="/all-customer" component={withRouter(AllCustomerView)} />
            <Route exact path="/all-product" component={withRouter(AllProducts)} />
            <Route exact path="/all-cart" component={withRouter(AllCartView)} />
            <Route exact path="/all-order" component={withRouter(AllOrder)} />
            <Route exact path="/category/:defaultActiveKey?" component={withRouter(Category)} />
            <Route exact path="/feedbacks" component={withRouter(Feedbacks)} />
            <Route exact path="/wishlist/:defaultActiveKey?" component={withRouter(Wishlists)} />
            <Route exact path="/notification" component={withRouter(Notification)} />
            <Route exact path="/single-shop-view/:defaultActiveKey?/:bakerID" component={withRouter(SingleShopView)} />
            <Route exact path="/single-customer-view/:defaultActiveKey?/:customerID" component={withRouter(SingleCustomerView)} />
            <Route exact path="/settings/:defaultActiveKey?" component={withRouter(SettingsView)} />
            <Route exact path="/all-bakers" component={withRouter(AllBakers)}/> 
            <Route exact path="/coupon" component={withRouter(Coupon)}/> 
            <Route exact path="/used-coupon/:couponId/:couponCode" component={withRouter(UsedCoupon)}/> 
          </Switch>
        </Router>
      </>
    );
  }
}

export default App;
