import React, { Component } from 'react'
import { BsArrowLeftRight } from 'react-icons/bs';
import { GrPowerReset } from 'react-icons/gr';
import { TbSearch } from 'react-icons/tb';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { postApi } from '../api/call.api';
import dataApi from '../api/data.api';
import urlApi from '../api/url.api';
import Navbar from '../components/Navbar';
import SidebarMenu from '../components/SidebarMenu'
import { dateTimeConverter } from '../utils/dateTimeConverter';

export default class AllBakers extends Component {
    constructor(p) {
        super(p);
        this.state = {
            isOpen: false,
            tableData: [],
            pagination: ({
                active_page: 0,
                per_page: 10,
                total_items: 0
            }),
            page: 1,
            count: 10,
            totalCount: "0",
        }
    }

    menuBarClick() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    async resetTable() {
        await this.setState({ tableData: [] })
        await this.setState({
            pagination: ({
                active_page: 0,
                per_page: 10,
                total_items: 0
            })
        })
    }

    alert = (msg, responseCode) => {
        if (responseCode === "200") {
            toast.success(msg);
        }
        else if (responseCode === "500") {
            toast.error(msg);
        }
        else {
            toast.info(msg);
        }
    }

    async resetFields() {
        document.getElementById("from-date-filter").value = "";
        document.getElementById("to-date-filter").value = "";
        document.getElementById("search-field").value = "";
        document.getElementById("status-search").value = "0";
        this.fetchAllBakerList({dateFilter:""});
    }

    dateFilterVar ="";

    async fetchAllBakerList({ dateFilter = this.dateFilterVar } = {}) {
        this.dateFilterVar = dateFilter;
        this.setState({ page: 1 });
        var postData = {
            "search_data": document.getElementById("search-field").value,
            "status": document.getElementById("status-search").value,
            "date": dateFilter,
            "fromDate": document.getElementById("from-date-filter").value,
            "toDate": document.getElementById("to-date-filter").value
        }
        let resp = await postApi(postData, urlApi.allBaker + this.state.page);
        if (resp.responsecode === "200") {
            this.setState({ tableData: resp.data.data });
            this.setState({ pagination: resp.data.pagination });
            this.setState({ totalCount: resp.data.pagination.total_items });
        }
        else {
            this.resetTable();
            this.setState({ totalCount: "0" });
        }
    }

    async onSearch() {
        await this.setState({ page: 1 }, () => this.fetchAllBakerList());
    }

    componentDidMount() {
        this.fetchAllBakerList();
    }

    async handlePageChange(pageNumber) {
        await this.setState({ page: pageNumber });
        this.fetchAllBakerList();
    }

    render() {
        var x = (this.state.pagination.active_page - 1) * this.state.pagination.per_page + 1;
        return (
            <>
                <div className="container-fluid position-relative bg-white d-flex p-0">
                    <SidebarMenu isOpen={this.state.isOpen} />
                    <div className={this.state.isOpen ? "content open" : "content"}>
                        <Navbar menuBarClick={() => this.menuBarClick()} />
                        <div className="container-fluid">
                            <ToastContainer autoClose={1000} />
                            <div className="row g-4">
                                <div className="col-12 px-0">
                                    <div className="rounded h-100 p-2">
                                        <h6 className="mb-3">All Bakers || Total : {this.state.totalCount ?? "0"}</h6>
                                        <div className="row mb-2 gx-2">
                                        <div className="col-md-1 mb-1">
                                                <button className='btn-custom action-btn-5 w-100' onClick={() => this.fetchAllBakerList({ dateFilter: "" })}>All</button>
                                            </div>
                                            <div className="col-md-1 mb-1">
                                                <button className='btn-custom action-btn-1 w-100' onClick={() => this.fetchAllBakerList({ dateFilter: "DAILY" })}>Today</button>
                                            </div>
                                            <div className="col-md-1 mb-1">
                                                <button className='btn-custom action-btn-4 w-100' onClick={() => this.fetchAllBakerList({ dateFilter: "YESTERDAY" })}>Yesterday</button>
                                            </div>
                                            <div className="col-md-1 mb-1">
                                                <button className='btn-custom action-btn-3 w-100' onClick={() => this.fetchAllBakerList({ dateFilter: "WEEKLY" })}>Last Week</button>
                                            </div>
                                            <div className="col-md-1 mb-1">
                                                <button className='btn-custom action-btn-2 w-100' onClick={() => this.fetchAllBakerList({ dateFilter: "MONTHLY" })}>Last Month</button>
                                            </div>
                                        </div>
                                        <div className="row gx-2 mb-2">
                                            <div className="col-md-2">
                                                <input type="date" className='form-control form-control-sm w-100' id='from-date-filter' />
                                            </div>
                                            <div className="col-md-1 text-center"> <BsArrowLeftRight /> </div>
                                            <div className="col-md-2">
                                                <input type="date" className='form-control form-control-sm w-100' id='to-date-filter' />
                                            </div>
                                            <div className="col-md-1">
                                                <button className='btn-custom action-btn-1 w-100' onClick={() => this.fetchAllBakerList({ dateFilter: "CUSTOM" })}>Search</button>
                                            </div>
                                            <div className="col-md-2 col-2">
                                                <button className='btn-custom action-btn-5 w-sm-100' onClick={() => this.resetFields()}><GrPowerReset size={20} /></button>
                                            </div>
                                        </div>
                                        <form onSubmit={(e) => { e.preventDefault(); this.onSearch() }}>
                                            <div className="col-12 mb-2">
                                                <div className="row gx-1 search-section">
                                                    <div className="col-md-3 col-6">
                                                        <input className='form-control form-control-sm w-100' id='search-field' placeholder='Baker Name / Mobile' />
                                                    </div>
                                                    <div className="col-md-3 col-6">
                                                        <select id="status-search" defaultValue={"0"} className='form-control form-control-sm w-100'>
                                                            <option value="0">---All---</option>
                                                            <option value="1">With Shop</option>
                                                            <option value="2">Without Shop</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2 col-2">
                                                        <button className='btn-custom action-btn-5 w-sm-100' type='submit'><TbSearch size={20} /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        {this.state.tableData.length > 0 ? <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th className="col">#</th>
                                                        <th className="col">Name</th>
                                                        <th className="col">Shop Name</th>
                                                        <th className="col">Mobile</th>
                                                        <th className="col">Email</th>
                                                        <th className="col">Shop Status</th>
                                                        <th className="col">Created</th>
                                                        <th className="col">Modified</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.tableData.map((data,index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <th scope="row">{x++}</th>
                                                                <td className='text-left'>
                                                                    {data.shop_name ? <Link to={"single-shop-view/shopDetails/" + data.baker_id} target={"_blank"}>
                                                                        <span>{data.baker_name}</span>
                                                                    </Link> : <span>{data.baker_name}</span>}
                                                                </td>
                                                                <td className='text-left'>
                                                                    {data.shop_name ? <Link to={"single-shop-view/shopDetails/" + data.baker_id} target={"_blank"}>
                                                                        <span className='table-img'><img src={data.shop_image} alt="" /></span><br />
                                                                        <span>{data.shop_name}</span>
                                                                    </Link> : <span className='text-secondary'>[No Shop Created]</span>}
                                                                </td>
                                                                <td className='text-left'>
                                                                    {data.mobile}
                                                                </td>
                                                                <td>{data.email}</td>
                                                                <td> {data.shop_status ? <span className={`badge ${data.shop_status === 0 ? "bg-warning" : data.shop_status === 1 ? "bg-success" : "bg-danger"}`}>{data.shop_status === 0 ? "Pending" : data.shop_status === 1 ? "Approved" : "Rejected"}</span> : "--"}
                                                                </td>
                                                                <td>
                                                                    <span>{dateTimeConverter(data.create_date)}</span><br />
                                                                    <span>{data.modified_by === 0 ? "" : data.modified_by === data.baker_id ? "" : dataApi.USERNAME()}</span>
                                                                </td>
                                                                <td>{dateTimeConverter(data.modify_date)}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div> : <div className="no-data text-center my-5">
                                            <h4 className='text-danger'>Sorry! No Baker Found!</h4>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='me-2' style={{ float: 'right', display: this.state.tableData.length > 0 ? 'unset' : 'none' }}>
                            <Pagination
                                activePage={parseInt(this.state.pagination.active_page)}
                                itemsCountPerPage={this.state.pagination.per_page}
                                totalItemsCount={this.state.pagination.total_items}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange.bind(this)}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
